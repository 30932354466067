<template>
    <div class="dialog-off-canvas-main-canvas d-flex flex-column h-100"
         style="background: white;"
         data-off-canvas-main-canvas>
        <CenturionHeader></CenturionHeader>

        <main role="main">
            <a id="main-content"
               tabindex="-1"></a>


            <div class="region region-content">
                <div data-drupal-messages-fallback
                     class="hidden"></div>
                <div id="block-amexsubtheme-content"
                     class="block block-system block-system-main-block">



                    <article data-history-node-id="1079"
                             role="article"
                             about="/content/travel-offers"
                             class="node node--type-centurion-page node--view-mode-full">





                        <div class="node__content">

                            <div
                                 class="field field--name-field-centurion-page-sections field--type-entity-reference-revisions field--label-hidden field__items">
                                <div class="field__item">


                                    <div
                                         class="paragraph paragraph--type--block-reference paragraph--view-mode--default">


                                        <div
                                             class="field field--name-field-block-ref field--type-entity-reference field--label-hidden field__item">
                                            <div id="block-centurionexperiencesoffers"
                                                 class="block block-block-content block-block-content8194d79d-7a70-4a78-998a-545282d42555">



                                                <div
                                                     class="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item">
                                                    <div class="gray-white">
                                                        <div class="container">
                                                            <div class="row g-md-0 black-bg">
                                                                <div class="col-md-5">
                                                                    <div class="title">{{ $t('Centurion') }}<br />
                                                                        {{ $t('experiences & offers') }}</div>
                                                                </div>

                                                                <div class="col-md-7">
                                                                    <div class="image"><img
                                                                             alt="Centurion experiences & offers logo"
                                                                             class="img-fluid"
                                                                             :src="require(`@/assets/images/centurion/cent-exp-and-offers.jpg`)" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>



                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-experiences-offers paragraph--view-mode--default">
                                        <div class="cent-gray-bg">
                                            <div class="container">
                                                <div class="row g-md-0">
                                                    <div
                                                         class="col-12 mt-3 mt-md-0 p-md-0 d-flex align-items-md-end flex-column justify-content-md-center position-relative">
                                                        <div class="cent-exp-offers mw-100">

                                                            <div
                                                                 class="field field--name-field-cent-experiences-offer-img field--type-image field--label-hidden field__item">
                                                                <img :src="require(`@/assets/images/centurion/travel-banner.jpg`)"
                                                                     width="1300"
                                                                     height="543"
                                                                     alt=""
                                                                     loading="lazy"
                                                                     typeof="foaf:Image"
                                                                     class="img-fluid" />

                                                            </div>

                                                            <div class="cent-sub-menu">
                                                                <button class="cent-sub-menu-prev"
                                                                        type="button">
                                                                    <span class="cent-sub-menu-prev-icon"
                                                                          aria-hidden="true"></span>
                                                                </button>
                                                                <nav aria-labelledby="block-centurionmenu-2-menu"
                                                                     id="block-centurionmenu-2"
                                                                     class="block block-menu navigation menu--centurion-menu">

                                                                    <h5 class="visually-hidden"
                                                                        id="block-centurionmenu-2-menu">{{ $t(`Centurion
                                                                        Menu`) }}</h5>




                                                                    <ul class="menu">
                                                                        <li class="menu-item menu-item--active-trail">
                                                                            <a href="210-automotive-en.html"
                                                                               data-drupal-link-system-path="node/1074">{{
                                                                                $t(`Automotive`) }}</a>
                                                                        </li>
                                                                        <li class="menu-item">
                                                                            <a href="200-experience-en.html"
                                                                               data-drupal-link-system-path="node/1075"
                                                                               {{
                                                                               $t(`Centurion
                                                                               Experience`)
                                                                               }}></a>
                                                                        </li>
                                                                        <li class="menu-item">
                                                                            <a href="220-entertainment-en.html"
                                                                               data-drupal-link-system-path="node/1076">{{
                                                                                $t(`Entertainment`) }}</a>
                                                                        </li>
                                                                        <li class="menu-item">
                                                                            <a href="230-jewellery-en.html"
                                                                               data-drupal-link-system-path="node/1078"
                                                                               class="is-active">{{ $t(`Jewellery`)
                                                                                }}</a>
                                                                        </li>
                                                                        <li class="menu-item">
                                                                            <a href="240-shopping-en.html"
                                                                               data-drupal-link-system-path="node/1077">{{
                                                                                $t(`Shopping Experiences`) }}</a>
                                                                        </li>
                                                                        <li class="menu-item">
                                                                            <a href="250-travel-en.html"
                                                                               data-drupal-link-system-path="node/1079">{{
                                                                                $t(`Travel Offers`)}}</a>
                                                                        </li>
                                                                    </ul>


                                                                </nav>

                                                                <button class="cent-sub-menu-next"
                                                                        type="button">
                                                                    <span class="cent-sub-menu-next-icon"
                                                                          aria-hidden="true"></span>
                                                                </button>
                                                            </div>

                                                            <div
                                                                 class="field field--name-field-cent-offers field--type-entity-reference-revisions field--label-hidden field__items">
                                                                <div class="field__item">
                                                                    <div
                                                                         class="paragraph paragraph--type--cent-offers-container paragraph--view-mode--default">
                                                                        <div class="row margin justify-content-center">
                                                                            <div class="col-12 col-md-4">
                                                                                <div
                                                                                     class="paragraph paragraph--type--cent-offers-items paragraph--view-mode--default">

                                                                                    <div
                                                                                         class="field field--name-field-offers-image field--type-image field--label-hidden field__item">
                                                                                        <img :src="require(`@/assets/images/centurion/pure-escape.jpg`)"
                                                                                             width="321"
                                                                                             height="290"
                                                                                             alt=""
                                                                                             loading="lazy"
                                                                                             typeof="foaf:Image"
                                                                                             class="img-fluid" />

                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-title field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`Pure Escapes`) }}</p>
                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-text field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`Earn 2X Membership
                                                                                            Rewards Points when you
                                                                                            explore the stunning skies
                                                                                            and
                                                                                            clear waters of the Maldives
                                                                                            and Seychelles. Book a hotel
                                                                                            with the Pure
                                                                                            Escapes collection through
                                                                                            American Express Premium
                                                                                            Travel Services using
                                                                                            your Centurion Card and
                                                                                            double your points.Terms and
                                                                                            Conditions apply.`)}}</p>

                                                                                        <p>{{ $t(`Offer Valid until
                                                                                            February 28, 2025`)}}</p>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div class="col-12 col-md-4">
                                                                                <div
                                                                                     class="paragraph paragraph--type--cent-offers-items paragraph--view-mode--default">

                                                                                    <div
                                                                                         class="field field--name-field-offers-image field--type-image field--label-hidden field__item">
                                                                                        <img :src="require(`@/assets/images/centurion/le-lodge-park-megeve.jpg`)"
                                                                                             width="321"
                                                                                             height="290"
                                                                                             alt=""
                                                                                             loading="lazy"
                                                                                             typeof="foaf:Image"
                                                                                             class="img-fluid" />

                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-title field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`Le Lodge Park
                                                                                            Megeve`)}}</p>
                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-text field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`Make your winter
                                                                                            getaway unforgettable with
                                                                                            exclusive personalized
                                                                                            offers
                                                                                            at Europe’s finest
                                                                                            properties. Book at Le Lodge
                                                                                            Park Megeve through
                                                                                            Premium Travel Services
                                                                                            using your Centurion Card
                                                                                            and enjoy 15% savings on
                                                                                            Best Available Rates for
                                                                                            bookings of 7 nights or
                                                                                            longer, daily breakfast
                                                                                            for up to two guests per
                                                                                            room during the stay, and a
                                                                                            USD 100 Property
                                                                                            Credit to be enjoyed during
                                                                                            your stay. Terms and
                                                                                            Conditions apply. Valid
                                                                                            for bookings from December
                                                                                            8, 2024 until March 25,
                                                                                            2025. Valid for stays
                                                                                            from December 20,2024 until
                                                                                            March 31, 2025`)}}</p>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div class="col-12 col-md-4">
                                                                                <div
                                                                                     class="paragraph paragraph--type--cent-offers-items paragraph--view-mode--default">

                                                                                    <div
                                                                                         class="field field--name-field-offers-image field--type-image field--label-hidden field__item">
                                                                                        <img :src="require(`@/assets/images/centurion/grace-la-margna.jpg`)"
                                                                                             width="321"
                                                                                             height="290"
                                                                                             alt=""
                                                                                             loading="lazy"
                                                                                             typeof="foaf:Image"
                                                                                             class="img-fluid" />

                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-title field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`Grace La Margna St
                                                                                            Moritz`)}}</p>
                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-text field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`Make your winter escape
                                                                                            unforgettable with exclusive
                                                                                            offers at Grace La
                                                                                            Margna St. Moritz. Book
                                                                                            through Premium Travel
                                                                                            Services with your
                                                                                            Centurion Card and enjoy 20%
                                                                                            savings on Best Available
                                                                                            Rates for stays of
                                                                                            7 nights or more, daily
                                                                                            breakfast for two, late
                                                                                            check-out until 4 PM
                                                                                            (subject to availability),
                                                                                            and USD 100 food & beverage
                                                                                            credit. Valid
                                                                                            for bookings from December
                                                                                            8, 2024 until March 25,
                                                                                            2025. Valid for stays
                                                                                            from December 20,2024 until
                                                                                            March 31, 2025. Terms and
                                                                                            conditions apply.`)}}
                                                                                        </p>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div class="col-12 col-md-4">
                                                                                <div
                                                                                     class="paragraph paragraph--type--cent-offers-items paragraph--view-mode--default">

                                                                                    <div
                                                                                         class="field field--name-field-offers-image field--type-image field--label-hidden field__item">
                                                                                        <img :src="require(`@/assets/images/centurion/grandes-alpes-courchevel.jpg`)"
                                                                                             width="321"
                                                                                             height="290"
                                                                                             alt=""
                                                                                             loading="lazy"
                                                                                             typeof="foaf:Image"
                                                                                             class="img-fluid" />

                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-title field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`Grandes Alpes
                                                                                            Courchevel`)}}</p>
                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-text field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`Make your winter escape
                                                                                            unforgettable with exclusive
                                                                                            offers at Les
                                                                                            Grandes Alpes Courchevel.
                                                                                            Book through Premium Travel
                                                                                            Services with your
                                                                                            Centurion Card and enjoy EUR
                                                                                            1,000 hotel credit for stays
                                                                                            of 5 nights or
                                                                                            more, daily breakfast for
                                                                                            two, and dedicated butler
                                                                                            services for select
                                                                                            room categories. Valid for
                                                                                            bookings from December 8,
                                                                                            2024 until March 25,
                                                                                            2025. Valid for stays from
                                                                                            December 20,2024 until March
                                                                                            31, 2025 . Terms
                                                                                            and conditions apply.`)}}
                                                                                        </p>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div class="col-12 col-md-4">
                                                                                <div
                                                                                     class="paragraph paragraph--type--cent-offers-items paragraph--view-mode--default">

                                                                                    <div
                                                                                         class="field field--name-field-offers-image field--type-image field--label-hidden field__item">
                                                                                        <img :src="require(`@/assets/images/centurion/kempinski-palace-engelberg.jpg`)"
                                                                                             width="321"
                                                                                             height="290"
                                                                                             alt=""
                                                                                             loading="lazy"
                                                                                             typeof="foaf:Image"
                                                                                             class="img-fluid" />

                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-title field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`Kempinski Palace
                                                                                            Engelberg`)}}</p>
                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-text field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`Make your winter escape
                                                                                            unforgettable with exclusive
                                                                                            offers at Kempinski
                                                                                            Palace Engelberg. Book
                                                                                            through Premium Travel
                                                                                            Services with your Centurion
                                                                                            Card and enjoy a
                                                                                            complimentary fifth night,
                                                                                            daily breakfast for two, USD
                                                                                            100 property credit, and
                                                                                            room upgrades, early
                                                                                            check-in, and late check-out
                                                                                            (subject to availability).
                                                                                            Valid for bookings from
                                                                                            December 8, 2024 until
                                                                                            January 25, 2025. Valid for
                                                                                            stays from December 8, 2024
                                                                                            until February 28,
                                                                                            2025. Terms and conditions
                                                                                            apply.`)}}</p>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div class="col-12 col-md-4">
                                                                                <div
                                                                                     class="paragraph paragraph--type--cent-offers-items paragraph--view-mode--default">

                                                                                    <div
                                                                                         class="field field--name-field-offers-image field--type-image field--label-hidden field__item">
                                                                                        <img :src="require(`@/assets/images/centurion/hotel-barriere-les-neiges-courchevel.jpg`)"
                                                                                             width="321"
                                                                                             height="290"
                                                                                             alt=""
                                                                                             loading="lazy"
                                                                                             typeof="foaf:Image"
                                                                                             class="img-fluid" />

                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-title field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`Hotel Barriere Les
                                                                                            Neiges Courchevel`)}}</p>
                                                                                    </div>

                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-offers-text field--type-text-long field--label-hidden field__item">
                                                                                        <p>{{ $t(`Make your winter escape
                                                                                            unforgettable with exclusive
                                                                                            offers at Hotel
                                                                                            Barriere Les Neiges
                                                                                            Courchevel. Book through
                                                                                            Premium Travel Services with
                                                                                            your Centurion Card and
                                                                                            enjoy 20% savings on Best
                                                                                            Available Rates for
                                                                                            stays of 5 nights or more,
                                                                                            daily breakfast for two,
                                                                                            late check-out until 4
                                                                                            PM (subject to
                                                                                            availability), and USD 100
                                                                                            property credit. Valid for
                                                                                            bookings from December 8,
                                                                                            2024 until March 25, 2025.
                                                                                            Valid for stays from
                                                                                            December 20,2024 until March
                                                                                            31, 2025. Terms and
                                                                                            conditions apply.`)}}</p>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div
                                                                 class="clearfix text-formatted field field--name-field-cent-offer-text field--type-text-long field--label-hidden field__item">
                                                                <ul>
                                                                    <li>{{ $t(`All offers, benefits, and promotions are
                                                                        subject to availability and may change
                                                                        without prior notice. While we strive to provide
                                                                        accurate and up-to-date
                                                                        information, American Express Saudi Arabia does
                                                                        not guarantee the ongoing
                                                                        availability or validity of offers displayed on
                                                                        this website.`)}}</li>
                                                                    <li>{{ $t(`The website content is regularly reviewed
                                                                        and updated; however, due to the dynamic
                                                                        nature of promotions, some offers may expire or
                                                                        change before updates are reflected.
                                                                        For accurate and current information, please
                                                                        contact your Centurion Relationship
                                                                        Manager or call`)}} <a
                                                                           href="tel:800 122 5225">800 122 5225</a>.
                                                                    </li>
                                                                    <li>{{ $t(`American Express Saudi Arabia reserves the
                                                                        right to modify, replace, or terminate
                                                                        any offers, benefits, or promotions at any time
                                                                        without prior notice. Updates take
                                                                        effect immediately upon posting or on a
                                                                        specified date. By continuing to use the
                                                                        service, you agree to the terms of any
                                                                        changes.`)}}</li>
                                                                    <li>{{ $t(`The decision of American Express Saudi
                                                                        Arabia is final regarding disputes related
                                                                        to these offers and benefits. We are not liable
                                                                        for any discrepancies between the
                                                                        website information and the actual status of
                                                                        offers. For confirmation or specific
                                                                        inquiries, please contact your Centurion
                                                                        Relationship Manager or calls`)}} <a
                                                                           href="tel:800 122 5225">800 122 5225</a>.
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </article>

                </div>

            </div>


        </main>
        <CenturionFooter></CenturionFooter>
    </div>
</template>

<script>
import store from '@/store';

import CenturionHeader from './centurion-header.vue';
import CenturionFooter from './centurion-footer.vue';

export default {
    components: {
        CenturionHeader,
        CenturionFooter
    },
    data: () => ({
        offers: [],
        loaded: true,
        selectedLanguage: 'en',
        heading: 'Available Offers',
        loadedCount: 0,
        unsubscribe: null,
    }),
    created()
    {
        this.unsubscribe = store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
                if (store.get('requestKeys/accessToken') && this.loadedCount == 0)
                {
                    this.loadedCount++;
                }

            }
        })

    },

    destroyed()
    {
        this.unsubscribe();
    },
    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang)
        {
            this.selectedLanguage = localStorage.getItem('language');
        }
        // this.loggedOffers();
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
@import './centurion.scss';
@import './bootstrap.css';

.gray-white {
    background: linear-gradient(to bottom, #fff 80%, #f8f8f8 20%);
}

.black-bg {
    background-color: #000000;
}

.title {
    font-size: 1.5em;
    font-weight: bold;
    color: #ffffff;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    transform: translateY(1.5em);
    text-align: center;
}

.image img {}

@media only screen and (max-width: 576px) {
    .black-bg {
        margin-bottom: 20%;
    }

    .title {
        font-size: 2em;
        text-align: center;
        margin-top: 1em;
    }

    .image {
        transform: translateY(50%);
        text-align: center;
        margin-top: -25%;
    }
}
</style>