import axios from './axiosInit';
import store from '@/store';
const GetAnonymouoffers = async (data) =>
{
    let body = {
        AnonymousRequestInfo: anonymousRequestInfo,
        country: country,
        city: city,
        merchant: merchant,
        category: category,
        language: language

    }
    try
    {
        store.dispatch('loader/show');
        const result = await axios.post('smartphoneapi/offers_staging/offersAnonymousApi/listOffers', data)
        store.dispatch('loader/hide');
        return result
    } catch (e)
    {
        // console.log(e)
    }
}
const GetLoggedoffers = async (data) =>
{


    try
    {
        store.dispatch('loader/show');
        const auth = {
            headers: {
                Authorization: 'Bearer ' + store.get('requestKeys/accessToken')
            }
        }
        const result = await axios.post('smartphoneapi/offers_staging/offersApi/listOffers', data, auth)
        store.dispatch('loader/hide');
        return result
    } catch (e)
    {
        // console.log(e)
    }
}
const getOfferdetail = async (data) =>
{


    try
    {
        store.dispatch('loader/show');
        const auth = {
            headers: {
                Authorization: 'Bearer ' + store.get('requestKeys/accessToken')
            }
        }
        const result = await axios.post('smartphoneapi/offers_staging/offersApi/getOfferDetails', data, auth)
        store.dispatch('loader/hide');
        return result
    } catch (e)
    {
        // console.log(e)
    }
}

const getOfferDetails = async (data) =>
{

    try
    {
        store.dispatch('loader/show');
        const result = await axios.post('smartphoneapi/offers_staging/offersApi/getOfferDetails', data)
        store.dispatch('loader/hide');
        return result
    } catch (e)
    {
        // console.log(e)
    }
}


const GetSavedoffers = async (data) =>
{

    try
    {
        store.dispatch('loader/show');
        const auth = {
            headers: {
                Authorization: 'Bearer ' + store.get('requestKeys/accessToken')
            }
        }
        const result = await axios.post('smartphoneapi/offers_staging/offersApi/GetSavedOffers', data, auth)
        store.dispatch('loader/hide');
        return result
    } catch (e)
    {
        // console.log(e)
    }
}
const GetSaveoffer = async (data) =>
{
    let body = {
        offerSerno: data.offerSerno,
        clientCode: store.get('requestKeys/ClientCode'),
        language: 'E',
        reference: 'C',
        accountsToSave: data.accountsNo,
        requestInfo: data.requestInfo
    }
    try
    {
        const auth = {
            headers: {
                Authorization: 'Bearer ' + store.get('requestKeys/accessToken')
            }
        }
        store.dispatch('loader/show');
        const result = await axios.post('smartphoneapi/offers_staging/offersApi/saveToCard', body, auth)
        store.dispatch('loader/hide');
        return result
    } catch (e)
    {
        // console.log(e)
    }
}

export default {
    GetAnonymouoffers,
    GetLoggedoffers,
    GetSavedoffers,
    GetSaveoffer,
    getOfferdetail
}
