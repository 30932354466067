<template>
    <div class="text-center">

        <v-card class="security v-dialog-logout">
            <v-card-title>
                <span class="font-weight-semibold text-color-black mx-1"
                      style="font-size: 14px;">
                    {{ $t('Activate Card') }}
                </span>
                <span class="dialog-close en-close">
                    <v-icon @click="close">
                        {{ icons.mdiClose }}
                    </v-icon>
                </span>
            </v-card-title>
            <v-card-text class="pa-7 text-center"
                         style="position: relative;">
                <div>
                    <img src="@/assets/images/activatecard/call.png"
                         alt="" />
                </div>
                <div style="position: absolute; right: 0;top: 0;">
                    <img src="@/assets/images/activatecard/activatecall.png"
                         alt="" />
                </div>
                <div class="font-weight-semibold text-color-black mx-1 mt-2"
                     style="font-size: 18px;">
                    {{ $t('Authentication') }}
                </div>

                <p class="text-color-black mt-5 mx-11"
                   style="font-size: 12px">
                    {{ $t(`You will receive a call shortly on your phone`) }}
                    <span>*******407</span>
                    <!-- {{ cardDetail?.phoneNumber ? cardDetail.phoneNumber :
                                                        '*******407' }} -->
                </p>

                <div class="mx-auto"
                     style="width: 100%;text-align: center;">
                    <v-progress-circular :value="value"
                                         :size="50"
                                         :width="8"
                                         color="primary">
                        {{ value }}
                    </v-progress-circular>
                </div>

                <div class="d-flex flex-column mt-2">
                    <span v-if="value == 0"
                          style="font-size: 13px;">
                        {{ $t('Request Call Again') }}
                    </span>
                </div>

                <v-row class="mx-6 mt-4">
                    <v-col lg="12">
                        <v-btn block
                               color="primary"
                               :disabled="value > 79"
                               type="submit"
                               @click="switchIcon"
                               large>
                            {{ $t('Continue') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

    </div>
</template>

<script>
import
{
    mdiClose
} from '@mdi/js'
import store from '@/store';

import sharedServices from '@/services/shared-services';
import cardStatus from '@/services/card-status';

export default {
    props: {
        card: {
            default: '',
            type: Object
        },
        calldialog: {
            default: false,
            type: Boolean
        }
    },
    data: () => ({
        icons: {
            mdiClose
        },
        switchOn: [],
        isSusByUser: 'show',
        isNotSusByUser: 'hide',
        selectedLanguage: 'en',
        showTimer: false,
        time: 100,
        timerId: null,
        interval: {},
        value: 90,
    }),
    created()
    {
        // clearInterval(this.interval);
        console.log('ssssss');

        this.value = 90;
        store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
            }
        });
    },
    mounted()
    {
        // clearInterval(this.interval);
        this.value = 90;

        this.interval = setInterval(() =>
        {
            if (this.value === 0)
            {
                return;
            }
            this.value -= 1
        }, 1000)

        let lang = localStorage.getItem('language');
        if (lang == 'ar')
        {
            this.selectedLanguage = lang;
        }
        else
        {
            this.selectedLanguage = lang;
        }
    },
    methods: {
        switchIcon()
        {
            // clearInterval(this.interval);
            // this.value = 90;
            this.$emit('switchIcon');
        },
        close()
        {
            clearInterval(this.interval);
            this.value = 90;
            this.$emit('close');
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.card-action {
    margin-left: 9px;
    background: #E2F1FD;
    width: 29px;
    height: 27px;
    border-radius: 50%;


}

.v-dialog {
    box-shadow: none !important;
}

.switch-btn {
    margin-bottom: -21px;
}

.v-dialog-logout {
    min-width: 250px !important;
    max-width: 500px !important;
    margin: 0 auto;
}

.en-close {
    position: absolute;
    right: 10px;
    cursor: pointer;
}

.action-image {
    height: 35px;
    width: 35px;
    margin: 5px;
    // top: 6px;
}

.security {
    padding-left: 0px !important;
}
</style>