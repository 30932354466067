import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import VueRouter from 'vue-router'
import apps from './apps'
import store from '@/store';

import { loadLanguageAsync } from '@/plugins/i18n';

// import { getVuetify } from '@core/utils'
// import useAppConfig from '@core/@app-config/useAppConfig'
// import { createRouter, createWebHistory } from 'vue-router'
// import dashboard from './dashboard'

Vue.use(VueRouter)

const routes = [
    // ? We are redirecting to different pages based on role.
    // NOTE: Role is just for UI purposes. ACL is based on abilities.
    {
        path: '/',
        redirect: to =>
        {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const accessToken = store.get('requestKeys/accessToken');

            //   const useraccessToken = accessToken && accessToken.id ? accessToken.id : null

            if (accessToken) return { name: 'home' }

            let lang = localStorage.getItem('language');

            return { name: 'auth-login', query: to.query, params: {lang: lang} }
        },
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/Error404.vue'),
        meta: {
            layout: 'blank',
            resource: 'Public',
        },
    },
    {
        path: '/:lang/login',
        name: 'auth-login',
        component: () => import('@/views/Login.vue'),
        meta: {
            layout: 'blank',
            resource: 'Public',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/:lang/login-centurion',
        name: 'auth-login-centurion',
        component: () => import('@/views/LoginCenturion.vue'),
        meta: {
            layout: 'blank',
            resource: 'Public',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/:lang/forgot-password',
        name: 'auth-forgot-password',
        component: () => import('@/views/ForgotPassword.vue'),
        meta: {
            layout: 'blank',
            resource: 'Public',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/:lang/forgot-id',
        name: 'auth-forgot-id',
        component: () => import('@/views/ForgotUserID.vue'),
        meta: {
            layout: 'blank',
            resource: 'Public',
            redirectIfLoggedIn: true,
        },
    },
    // Enrollment
    {
        path: '/:lang/enrollment',
        name: 'enrollment',
        component: () => import('@/views/enrollment/Enrollment.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            name: 'Enrollment',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/offers',
        name: 'offers',
        component: () => import('@/views/membership/offers.vue'),
        meta: {
            layout: 'content',
            //   resource: 'Public',
            //   redirectIfLoggedIn: true,
        },
    },
    {
        path: '/trips',
        name: 'trips',
        component: () => import('@/views/trips/trips.vue'),
        meta: {
            layout: 'content',
            //   resource: 'Public',
            //   redirectIfLoggedIn: true,
        },
    },
    {
        path: '/trips/add',
        name: 'trips2',
        component: () => import('@/views/trips/tripAdd.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/view-transaction',
        name: 'transaction',
        component: () => import('@/views/view-transaction/ViewTransaction.vue'),
        meta: {
            layout: 'content',
            //   resource: 'Public',
            //   redirectIfLoggedIn: true,
        },
    },

    {
        path: '/customer-service',
        name: 'customer-service',
        component: () => import('@/views/customer-service/CustomerService.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            redirectIfLoggedIn: true,
        },
    },

    ...apps,
    {
        path: '*',
        redirect: 'error-404',
    },
]

const router = new VueRouter({
    //   mode: 'history',
    //   history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
    scrollBehavior()
    {
        return { x: 0, y: 0 }
    },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) =>
{
    let lang = to?.params?.lang;

    if (lang)
    {
        localStorage.setItem('language', lang);
        loadLanguageAsync(lang);
        store.commit('accounts/SET_LANGUAGE', lang);

        if (lang == 'en')
        {
            localStorage.setItem('rtl', 'no');
        }
        else
        {
            localStorage.setItem('rtl', 'yes');
        }
    }
    else
    {
        // localStorage.setItem('language', 'en');
        // localStorage.setItem('rtl', 'no');
    }
    const userData = localStorage.getItem('userData')

    const isLoggedIn = userData && store.get('requestKeys/accessToken');

    if (!canNavigate(to))
    {
        // Redirect to login if not logged in
        if (!isLoggedIn) return next({ name: 'auth-login', query: { marketplace: to.query.marketplace }, params: {lang: lang} })

        // If logged in => not authorized
        // return next({ name: 'misc-not-authorized' })

        // return next({ name: 'misc-not-authorized' })
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn)
    {
        next('/home')
    }

    return next()
})

export default router
