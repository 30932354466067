<template>
    <div class="auth-wrapper auth-v1"
         style="padding: 0">
        <div class="auth-inner main-container-width">

            <v-dialog v-model="calldialog">
                <div v-if="calldialog">
                    <activateCall @close="close"
                                  @switchIcon="switchIcon"
                                  :calldialog="calldialog"
                                  :card="cardDetail"></activateCall>
                    <div v-if="showAlertError">
                        <alert :message="alertMessage"
                               :type="alertType"></alert>
                    </div>
                </div>
            </v-dialog>

            <v-row>
                <v-col cols="12"
                       sm="12"
                       md="12"
                       lg="12"
                       class="mx-auto">
                    <v-card flat
                            class="full-min-height">
                        <app-header :details="cardDetail"></app-header>

                        <v-stepper v-model="step"
                                   class="custom">
                            <v-stepper-items>
                                <div v-if="showAlert">
                                    <alert :message="alertMessage"
                                           :type="alertType"></alert>
                                </div>

                                <v-stepper-content step="1">
                                    <v-row>
                                        <v-col cols="12"
                                               lg="7"
                                               md="12"
                                               sm="12"
                                               class="mx-auto">
                                            <v-row>
                                                <v-col lg="10">
                                                    <div @click="stepOne"
                                                         class="mx-4 d-flex justify-start mt-4">
                                                        <span>
                                                            <img class="arabic-arrow"
                                                                 src="../../assets/images/back.png"
                                                                 alt=""
                                                                 width="70px"
                                                                 style="cursor: pointer" />
                                                        </span>
                                                        <span class="mt-1 text-black font-weight-semibold"
                                                              style="font-size: 14px">
                                                            {{ $t('Back Manage Cards') }}</span>
                                                    </div>

                                                    <p class="font-weight-semibold text-color-black mt-5 mx-11"
                                                       style="font-size: 17px">
                                                        {{ $t('Activate card') }}
                                                    </p>

                                                    <v-row class="mx-11">
                                                        <v-col cols="12"
                                                               lg="7"
                                                               md="7"
                                                               sm="12"
                                                               dir="ltr">
                                                            <p class="arabic-font arabic-otp font-weight-semibold mb-4 mt-3 text-color-black"
                                                               style="font-size: 13px;">
                                                                {{ $t("Enter Card’s last 5 digits") }}
                                                                <!-- Enter Card's last 4 digits -->
                                                            </p>
                                                            <v-otp-input v-model="lastFourDigit"
                                                                         :rules="cards"
                                                                         @input="onFourDigitInput"
                                                                         @blur="onFourDigitBlur"
                                                                         ref="fourDigitInput"
                                                                         :hide-details="true"
                                                                         type="number"
                                                                         onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                                                         length="5">
                                                            </v-otp-input>
                                                            <div v-if="fourDigitError"
                                                                 style="font-size: 12px;"
                                                                 class="error-message">
                                                                {{ $t(fourDigitErrorMessage) }}
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="12"
                                                               lg="5"
                                                               md="5"
                                                               sm="12">
                                                            <p class="arabic-font font-weight-semibold pt-1 mb-5 mt-2 text-color-black"
                                                               style="font-size: 13px;">
                                                                {{ $t("Enter Card's expiry date") }}
                                                            </p>
                                                            <v-text-field v-model="maskedExpiryDate"
                                                                          outlined
                                                                          maxlength="5"
                                                                          :placeholder="$t('MM-YY')"
                                                                          v-mask="timeMask"
                                                                          :rules="expiry"
                                                                          hide-details="auto">
                                                                <template #message="{ message }">
                                                                    {{ $t(message) }}
                                                                </template>
                                                            </v-text-field>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row class="mx-8">
                                                        <v-col cols="12"
                                                               lg="12"
                                                               md="12"
                                                               sm="12">
                                                            <v-card max-width="419px"
                                                                    class="pa-2 balance-card-background">
                                                                <v-card-text class="pa-1">
                                                                    <v-row>
                                                                        <v-col lg="12">
                                                                            <div class="d-flex align-center">
                                                                                <div class="mx-3">
                                                                                    <v-img contain
                                                                                           class="union auth-3d-group overflow-hidden"
                                                                                           :src="require(`@/assets/images/iota.png`)">
                                                                                    </v-img>
                                                                                </div>
                                                                                <div class="ml-1">
                                                                                    <span class="text-black font-weight-semibold"
                                                                                          style="font-size: 12px">
                                                                                        {{ $t('Card activation') }}
                                                                                    </span>
                                                                                    <div style="font-size: 10px">
                                                                                        {{ $t(`Only activate your new
                                                                                        Card once you have received it
                                                                                        and have it in hand.`)
                                                                                        }}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-card-text>
                                                            </v-card>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row class="mx-9 mt-7">
                                                        <v-col lg="12">
                                                            <v-btn block
                                                                   color="primary"
                                                                   type="submit"
                                                                   :disabled="!maskedExpiryDate || lastFourDigit.length < 5"
                                                                   @click="OutBoundDialerCardActivation"
                                                                   large>
                                                                {{ $t('Next') }}
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col lg="1"
                                               class="line-section">
                                            <v-row class="mt-3">
                                                <v-col style="height: 279px">
                                                    <v-divider class="my-16"
                                                               :vertical="true"></v-divider>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col lg="4"
                                               md="12"
                                               sm="12"
                                               cols="12"
                                               class="text-center">
                                            <v-row class="mt-16 mx-8">
                                                <v-col lg="8">
                                                    <span class="text-color-black font-weight-semibold"
                                                          style="font-size: 14px">
                                                        {{ $t('Card last five digits') }}
                                                    </span>
                                                    <v-img contain
                                                           max-width="150px"
                                                           style="margin: 0 auto"
                                                           class="auth-3d-group overflow-hidden"
                                                           :src="require(`@/assets/images/activate.png`)">
                                                    </v-img>

                                                    <span class="text-center text-color-black font-weight-semibold"
                                                          style="font-size: 14px">
                                                        {{ $t('Expiry date') }}
                                                    </span>
                                                    <v-img contain
                                                           max-width="150px"
                                                           style="margin: 0 auto"
                                                           class="auth-3d-group overflow-hidden"
                                                           :src="require(`@/assets/images/activate.png`)">
                                                    </v-img>
                                                    <p class="mt-2"
                                                       style="font-size: 10px">
                                                        {{ $t(`The location of your Card details may differ depending on
                                                        your Card.`) }}
                                                    </p>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-stepper-content>

                                <!-- <v-stepper-content step="5">
                                    <v-row>
                                        <v-col cols="12"
                                               lg="7"
                                               md="12"
                                               sm="12"
                                               class="mx-auto">
                                            <v-row>
                                                <v-col lg="10">
                                                    <div @click="backToStep"
                                                         class="mx-4 d-flex justify-start mt-4">
                                                        <span>
                                                            <img class="arabic-arrow"
                                                                 src="../../assets/images/back.png"
                                                                 alt=""
                                                                 width="70px"
                                                                 style="cursor: pointer" />
                                                        </span>
                                                        <span class="mt-1 text-black font-weight-semibold"
                                                              style="font-size: 14px">
                                                            {{ $t('Back Manage Cards') }}
                                                        </span>
                                                    </div>
                                                    <p class="font-weight-semibold text-color-black mt-5 mx-11"
                                                       style="font-size: 17px">
                                                        {{ $t('Authentication') }}
                                                    </p>
                                                    <p class="text-color-black mt-10 mx-11"
                                                       style="font-size: 12px">
                                                        {{ $t(`You will receive a call shortly on your phone`) }}
                                                        
                                                    </p>


                                                    <div class="mt-7 mx-11">
                                                        <timer :formattedTime="formattedTime"
                                                               :selectedLanguage="selectedLanguage"
                                                               @retry="generateOtp()"></timer>
                                                    </div>

                                                    <v-row class="mx-6 mt-7">
                                                        <v-col lg="12">
                                                            <v-btn block
                                                                   color="primary"
                                                                   type="submit"
                                                                   @click="OutBoundDialerValidateCMResponse"
                                                                   large>
                                                                {{ $t('Continue') }}
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col lg="1">
                                            <v-row class="mt-3">
                                                <v-col style="height: 350px">
                                                    <v-divider class="my-16"
                                                               :vertical="true"></v-divider>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col lg="4">
                                            <v-row class="mt-16 mx-8">
                                                <v-col cols="12"
                                                       lg="8"
                                                       md="8"
                                                       sm="12">
                                                    <span class="text-color-black font-weight-semibold"
                                                          style="font-size: 14px">
                                                        {{ $t('Card last four digits') }}
                                                    </span>
                                                    <v-img contain
                                                           max-width="150px"
                                                           class="auth-3d-group overflow-hidden"
                                                           :src="require(`@/assets/images/activate.png`)">
                                                    </v-img>

                                                    <span class="text-center text-color-black font-weight-semibold"
                                                          style="font-size: 14px">
                                                        {{ $t('Expiry date') }}
                                                    </span>
                                                    <v-img contain
                                                           max-width="150px"
                                                           class="auth-3d-group overflow-hidden"
                                                           :src="require(`@/assets/images/activate.png`)">
                                                    </v-img>
                                                    <p class="mt-2"
                                                       style="font-size: 10px">
                                                        {{ $t(`The location of your Card details may differ depending on
                                                        your Card.`) }}
                                                    </p>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-stepper-content> -->

                                <v-stepper-content step="2">
                                    <v-row>
                                        <v-col cols="12"
                                               lg="7"
                                               md="12"
                                               sm="12"
                                               class="mx-auto">
                                            <v-row>
                                                <v-col lg="10">
                                                    <div @click="backToStep"
                                                         class="mx-4 d-flex justify-start mt-4">
                                                        <span>
                                                            <img class="arabic-arrow"
                                                                 src="../../assets/images/back.png"
                                                                 alt=""
                                                                 width="70px"
                                                                 style="cursor: pointer" />
                                                        </span>
                                                        <span class="mt-1 text-black font-weight-semibold"
                                                              style="font-size: 14px">
                                                            {{ $t('Back Manage Cards') }}
                                                        </span>
                                                    </div>
                                                    <p class="font-weight-semibold text-color-black mt-5 mx-11"
                                                       style="font-size: 17px">
                                                        {{ $t('Enter 4-digit code sent to you') }}
                                                    </p>
                                                    <p class="text-color-black mt-10 mx-11"
                                                       style="font-size: 12px">
                                                        {{ $t(`Enter code to activate your card`) }}
                                                    </p>
                                                    <v-row class="mx-8">
                                                        <v-col cols="12"
                                                               lg="8"
                                                               md="8"
                                                               sm="12"
                                                               dir="ltr">
                                                            <v-otp-input v-model="otpCode"
                                                                         :rules="cards"
                                                                         @input="onOtpInput"
                                                                         @blur="onInputBlur"
                                                                         ref="otpInput"
                                                                         :hide-details="true"
                                                                         type="number"
                                                                         onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                                                         length="4"></v-otp-input>
                                                            <div v-if="otpError"
                                                                 style="font-size: 12px"
                                                                 class="error-message">
                                                                {{ $t(otpErrorMessage) }}
                                                            </div>
                                                        </v-col>
                                                    </v-row>

                                                    <div class="mt-7 mx-11">
                                                        <timer :formattedTime="formattedTime"
                                                               :selectedLanguage="selectedLanguage"
                                                               @retry="generateOtp()"></timer>
                                                    </div>

                                                    <v-row class="mx-6 mt-7">
                                                        <v-col lg="12">
                                                            <v-btn block
                                                                   color="primary"
                                                                   type="submit"
                                                                   :disabled="otpCode.length < 4"
                                                                   @click="nextStep"
                                                                   large>
                                                                {{ $t('Next') }}
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col lg="1">
                                            <v-row class="mt-3">
                                                <v-col style="height: 350px">
                                                    <v-divider class="my-16"
                                                               :vertical="true"></v-divider>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col lg="4">

                                        </v-col>
                                    </v-row>
                                </v-stepper-content>

                                <v-stepper-content step="3">
                                    <v-row class="pa-16">
                                        <v-col lg="12"
                                               class="text-center">

                                            <div>
                                                <div class="secure-img">
                                                    <img src="@/assets/images/Success.png"
                                                         alt="" />
                                                </div>
                                                <p class="mt-3 font-weight-semibold text-color-black"
                                                   style="font-size: 22px">
                                                    {{ $t('Activate card') }}
                                                </p>
                                                <p style="font-size: 14px"
                                                   class="mt-4">
                                                    {{ $t(`Your Card is now active and ready to use. Enjoy all the
                                                    benefits!`) }}
                                                    <!-- {{ $t('The Card ending') }}
                                                    <span v-if="cardDetail">{{ cardDetail.Last4Digits }}</span>
                                                    {{ $t('is now Activated.') }} -->
                                                </p>
                                            </div>

                                            <!-- <div v-if="!callValidated">
                                                <div class="secure-img">
                                                    <img src="@/assets/images/Success.png"
                                                         alt="" />
                                                </div>
                                                <p class="mt-3 font-weight-semibold text-color-black"
                                                   style="font-size: 22px">
                                                    {{ $t('Activate card') }}
                                                </p>
                                                <p style="font-size: 14px"
                                                   class="mt-4">
                                                    {{ $t(`Sorry! Your authentication is not completed, please try again
                                                    to continue.`) }}
                                                </p>
                                            </div> -->

                                            <div>
                                                <v-btn color="primary"
                                                       class="submit return"
                                                       type="submit"
                                                       to="/"
                                                       large>
                                                    {{ $t('Return to Home') }}
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import timer from '@/@core/timer.vue'
import AppHeader from '../test/AppHeader.vue'
// import soapServices from '@/services/soap-services'
import store from '@/store'
// import moment from 'moment'
// import { DeviceUUID } from 'device-uuid'
// import sharedServices from '@/services/shared-services';
import cardStatus from '@/services/card-status';
// import router from '@/router'
import alert from '@/views/alert.vue'
import soapErrorMessages from '@/@core/utils/soap-error-messages'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js';

import { getAnalytics, logEvent } from "firebase/analytics";

import activateCall from '@/views/card-settings/ActivateCallDialog.vue';

export function timeMask(value)
{
    const month = [
        /[0-1]/, value.charAt(0) === '0' ? /[0-9]/ : /[0-2]/,
    ];
    const year = [/[0-9]/, /[0-9]/];
    return value.length > 2 ? [...month, '-', ...year] : month;
}

export default {
    components: {
        AppHeader,
        alert,
        timer,
        activateCall
        //     Cardmanagement,
    },

    data: () => ({
        analytics: getAnalytics(),
        timeMask,
        step: 1,
        password: '',
        showTimer: false,
        time: 60,
        timerId: null,
        maskedExpiryDate: '',
        cardDetail: null,
        selectedCard: null,
        isPasswordVisible: false,
        alertMessage: '',

        callValidated: false,

        otpCode: '',
        lastFourDigit: '',

        alertType: 'error',

        otpError: false,
        otpErrorMessage: '',

        fourDigitError: false,
        fourDigitErrorMessage: '',

        selectedLanguage: 'en',
        showAlert: false,
        showAlertError: false,
        isCPasswordVisible: false,
        cards: [value => !!value || 'Last 4 digits are required.'],
        selectedDate: null,
        passwordRules: [
            value => !!value || 'Password is required.',
            value => (value && value.length >= 6) || 'password_info',
        ],
        expiry: [
            value => !!value || 'Expiry date is required.',
            value => (value && value.length >= 5) || 'Expiry date is required.',
        ],
        confirmPassword: null,
        disableVerify: true,

        icons: {
            mdiEyeOutline,
            mdiEyeOffOutline,
        },
        calldialog: false,
    }),
    mounted()
    {
        let lang = localStorage.getItem('language')
        if (lang)
        {
            this.selectedLanguage = localStorage.getItem('language')
        }

        this.cardDetail = store.get('card/details');

        this.$route.params.cardType == 'primary'
            ? (this.cardDetail = store.get('card/details'))
            : (this.cardDetail = store.get('card/supplementory'))

        window.scrollTo(0, 0);

        this.selectedCard = store.get('cardStore/selectedAccount');
        // this.cardDetail = store.get('card/details');
        let currentDate = new Date(this.cardDetail?.expiry);
        let month = currentDate.getMonth() + 1;
        let year = currentDate.getFullYear();
        this.selectedDate = month >= 10 ? month + '-' + (year % 100) : 0 + '' + month + '-' + (year % 100);
        //  this.maskedExpiryDate = this.selectedDate;
    },
    computed: {
        formattedTime()
        {
            const minutes = Math.floor(this.time / 60);
            const seconds = this.time % 60;
            return `${seconds.toString().padStart(2, '0')}`;
        },
        mr3Class()
        {
            if (this.selectedLanguage === 'ar')
            {
                return 'ml-3';
            } else
            {
                return 'mr-3';
            }
        },
    },
    created()
    {
        store.subscribe(mutation =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
            }
        });
    },
    methods: {
        switchIcon()
        {
            this.OutBoundDialerValidateCMResponse();
        },
        close()
        {
            this.calldialog = false;
        },
        onFourDigitInput()
        {
            if (this.lastFourDigit.length !== 5)
            {
                this.fourDigitError = true;
                this.fourDigitErrorMessage = '5 digits code is required.';
                const inputElement = this.$refs.fourDigitInput.$el.querySelector('input');
                if (inputElement)
                {
                    inputElement.focus(); // Move focus back into the input
                }
            } else
            {
                this.fourDigitError = false
            }
        },
        onFourDigitBlur()
        {
            if (!this.lastFourDigit.length)
            {
                this.fourDigitError = true
                this.fourDigitErrorMessage = '5 digits code is required.'
            }
        },
        onOtpInput()
        {
            if (this.otpCode.length !== 4)
            {
                this.otpError = true
                this.otpErrorMessage = '4 digits code is required.';
                const inputElement = this.$refs.otpInput.$el.querySelector('input');
                if (inputElement)
                {
                    inputElement.focus(); // Move focus back into the input
                }
            } else
            {
                this.otpError = false
            }
        },
        onInputBlur()
        {
            if (!this.otpCode.length)
            {
                this.otpError = true
                this.otpErrorMessage = '4 digits code is required.'
            }
        },
        backToStep()
        {
            this.step = 1
            window.scrollTo(0, 0)
        },
        stepTwo()
        {
            this.step = 2
            window.scrollTo(0, 0)
        },
        stepOne()
        {
            window.history.back()
            window.scrollTo(0, 0)
        },
        // sendOtpAgain()
        // {
        //     if (this.maskedExpiryDate != this.selectedDate)
        //     {
        //         let msg = 'Expiry date is Incorrect'
        //         this.alertMessage = msg
        //         this.alertType = 'error'
        //         this.showAlert = true
        //         setTimeout(() =>
        //         {
        //             this.showAlert = false
        //         }, 5000);
        //     } else
        //     {
        //         let obj = {
        //             idSeed: sharedServices.getIdSeed(),
        //             sysId: new DeviceUUID().get(),
        //             messageStamp: moment(new Date()).format('DD/MM/yyyy HH:mm:ss'),
        //             sysAuth: store.get('requestKeys/accessToken'),
        //             correlationID: Math.floor(1000 + Math.random() * 9000).toString(),
        //             requestorID: localStorage.getItem('userName'),
        //             custSerNo: store.get('requestKeys/ClientCode'),
        //             cardSserno: this.cardDetail.primeCardSerno,
        //         }
        //         soapServices.otpCard(obj).then(soapResp =>
        //         {
        //             if (soapResp.RequestAuthCodeResponse.RequestAuthCodeResult.Result.ErrorCode == '000')
        //             {
        //                 this.step = 2
        //                 window.scrollTo(0, 0)
        //                 let alertMessage = soapResp.RequestAuthCodeResponse.RequestAuthCodeResult.Body.ResponseMsg
        //                 let errorCode = soapResp.RequestAuthCodeResponse.RequestAuthCodeResult.Result.ErrorCode
        //                 this.alertMessage = soapErrorMessages.get(errorCode, alertMessage)
        //                 this.alertType = 'success'
        //                 this.showAlert = true
        //                 setTimeout(() =>
        //                 {
        //                     this.showAlert = false
        //                 }, 5000)
        //                 this.showTimer = true
        //                 this.timerId = setInterval(() =>
        //                 {
        //                     this.time -= 1
        //                     if (this.time <= 0)
        //                     {
        //                         clearInterval(this.timerId)
        //                         this.showTimer = false
        //                         this.time = 60
        //                         this.timerId = null
        //                     }
        //                 }, 1000)
        //             } else
        //             {
        //                 let alertMessage = soapResp?.RequestAuthCodeResponse?.RequestAuthCodeResult?.Result?.ErrorDesc;
        //                 let errorCode = soapResp.RequestAuthCodeResponse.RequestAuthCodeResult.Result.ErrorCode;
        //                 this.alertMessage = soapErrorMessages.get(errorCode, alertMessage);
        //                 this.alertType = 'error';
        //                 this.showAlert = true;
        //                 setTimeout(() =>
        //                 {
        //                     this.showAlert = false;
        //                 }, 5000)
        //             }
        //         })
        //     }
        //     // this.step = 2;
        // },

        // nextStep()
        // {
        //     let obj = {
        //         correlationID: Math.floor(1000 + Math.random() * 9000).toString(),
        //         messageStamp: moment(new Date()).format('DD/MM/yyyy HH:mm:ss'),
        //         sysId: new DeviceUUID().get(),
        //         sysAuth: store.get('requestKeys/accessToken'),
        //         requestorID: localStorage.getItem('userName'),
        //         idSeed: sharedServices.getIdSeed(),
        //         accountProductCode: this.cardDetail.productCode,
        //         custSerNo: store.get('requestKeys/ClientCode'),
        //         cardSserno: this.cardDetail.primeCardSerno,
        //         authCode: this.otpCode,
        //         activateDeActivate: 'A',
        //     }
        //     soapServices.activateCard(obj).then(soapResp =>
        //     {
        //         if (soapResp?.ActDeActCardResponse?.ActDeActCardResult?.Resp?.Result?.ErrorCode == '000')
        //         {
        //             this.step = 3;
        //             window.scrollTo(0, 0);

        //             logEvent(this.analytics, 'AMEX_CI_ACTIVATE_CARD_SUCCESS', {
        //                 content_type: 'userName',
        //                 content_id: store.get('requestKeys/userName')
        //             })
        //         } else
        //         {
        //             logEvent(this.analytics, 'AMEX_CI_ACTIVATE_CARD_FAILURE', {
        //                 content_type: 'userName',
        //                 content_id: store.get('requestKeys/userName')
        //             })

        //             let alertMessage = soapResp?.ActDeActCardResponse?.ActDeActCardResult?.Resp?.Result?.ErrorDesc;
        //             let errorCode = soapResp?.ActDeActCardResponse?.ActDeActCardResult?.Resp?.Result.ErrorCode;
        //             this.alertMessage = soapErrorMessages.get(errorCode, alertMessage);
        //             this.alertType = 'error';
        //             this.showAlert = true;
        //             setTimeout(() =>
        //             {
        //                 this.showAlert = false;
        //             }, 5000)
        //         }
        //     })
        // },
        nextStep()
        {
            this.activateDeactivateCard(this.otpCode);
        },
        OutBoundDialerCardActivation()
        {
            try
            {
                let data = {
                    userLanguage: this.selectedLanguage
                }
                cardStatus.OutBoundDialerCardActivation(data).then(response =>
                {
                    console.log(response);
                    if (response)
                    {
                        this.outBoundDialerId = response.data.id;
                        // this.step = 2;
                        this.calldialog = true;
                    }
                    else
                    {
                        // let alertMessage = soapResp?.ActDeActCardResponse?.ActDeActCardResult?.Resp?.Result?.ErrorDesc;
                        // let errorCode = soapResp?.ActDeActCardResponse?.ActDeActCardResult?.Resp?.Result.ErrorCode;
                        this.alertMessage = soapErrorMessages.get('146', '');
                        this.alertType = 'error';
                        this.showAlert = true;
                        setTimeout(() =>
                        {
                            this.showAlert = false;
                        }, 5000)
                    }
                });
            }
            catch (e)
            {
                console.log('Error', e);
            }
        },
        OutBoundDialerValidateCMResponse()
        {
            try
            {
                let data = {
                    id: this.outBoundDialerId
                }
                cardStatus.OutBoundDialerValidateCMResponse(data).then(response =>
                {
                    console.log(response);
                    if (response)
                    {
                        if (response.data.customerFeedback == 'Confirmed')
                        {
                            this.step = 2;
                            this.callValidated = true;
                            this.calldialog = false;
                        }
                        else if (response.data.customerFeedback == 'Declined')
                        {
                            this.alertMessage = soapErrorMessages.get('987987', '');
                            this.alertType = 'error';
                            this.showAlertError = true;
                            setTimeout(() =>
                            {
                                this.showAlertError = false;
                            }, 5000)
                        }
                        else
                        {
                            this.alertMessage = soapErrorMessages.get('987987', '');
                            this.alertType = 'error';
                            this.showAlertError = true;
                            setTimeout(() =>
                            {
                                this.showAlertError = false;
                            }, 5000)
                        }
                    }
                    else
                    {
                        this.alertMessage = soapErrorMessages.get('987987', '');
                        this.alertType = 'error';
                        this.showAlertError = true;
                        setTimeout(() =>
                        {
                            this.showAlertError = false;
                        }, 5000)
                    }
                });
            }
            catch (e)
            {
                console.log('Error', e);
            }
        },
        async generateOtp()
        {
            try
            {
                let otpMessageType = this.cardDetail.isSusByUser ? "SuspendCard" : "UnSuspendCard";
                let otpPurpose = this.cardDetail.isSusByUser ? "SuspendCard" : "UnSuspendCard";

                await cardStatus.generateOtp(otpMessageType, otpPurpose).then(response =>
                {
                    if (response.data['isOtpGenerated'] == true)
                    {
                        // let alertMessage = soapResp.RequestAuthCodeResponse.RequestAuthCodeResult.Body.ResponseMsg
                        // let errorCode = soapResp.RequestAuthCodeResponse.RequestAuthCodeResult.Result.ErrorCode
                        // this.alertMessage = soapErrorMessages.get(errorCode, alertMessage)
                        // this.alertType = 'success'
                        // this.showAlert = true
                        // setTimeout(() =>
                        // {
                        //     this.showAlert = false
                        // }, 5000)
                        this.showTimer = true
                        this.timerId = setInterval(() =>
                        {
                            this.time -= 1
                            if (this.time <= 0)
                            {
                                clearInterval(this.timerId)
                                this.showTimer = false
                                this.time = 60
                                this.timerId = null
                            }
                        }, 1000)
                    }
                    else
                    {
                        // let alertMessage = soapResp.RequestAuthCodeResponse.RequestAuthCodeResult.Resp.Result.ErrorDesc
                        // let errorCode = soapResp.RequestAuthCodeResponse.RequestAuthCodeResult.Result.ErrorCode
                        // this.alertMessage = soapErrorMessages.get(errorCode, alertMessage)
                        // this.alertType = 'error'
                        // this.showAlert = true
                        // setTimeout(() =>
                        // {
                        //     this.showAlert = false
                        // }, 5000)
                    }
                });
            }
            catch (e)
            {
                console.log('error', e);
            }
        },
        activateDeactivateCard(otp)
        {
            try
            {
                var requestDate = store.get('requestKeys/requestDate');
                var pos = requestDate.lastIndexOf('.');

                let result = (pos != -1) ? requestDate.substring(0, pos) : requestDate;

                let requestInfo = {
                    requestUID: (Math.floor(1000 + Math.random() * 9000)).toString(),
                    requestDate: result,
                    channelKey: store.get('requestKeys/channelKey'),
                    identityKey: store.get('requestKeys/identityKey'),
                    channelId: store.get('requestKeys/channelId'),
                    identitySeed: store.get('requestKeys/identitySeed'),
                    // UserName: store.get('requestKeys/userName'),
                    userName: store.get('requestKeys/userName'),
                    requestReference: (Math.floor(1000 + Math.random() * 9000)).toString(),
                };

                let activateCardRequestModel;

                if (this.cardDetail.isEnableForCall) 
                {
                    activateCardRequestModel = {
                        requestInfo: requestInfo,
                        cardNumber: this.cardDetail.primeCardSerno,
                        oTP: otp,
                        isPhysical: 'Y',
                        lastDigits: this.lastFourDigit,
                        activate: true,
                    };
                }
                else if (this.cardDetail.isSusByUser)
                {
                    activateCardRequestModel = {
                        requestInfo: requestInfo,
                        cardNumber: this.cardDetail.primeCardSerno,
                        oTP: otp,
                        isPhysical: 'O',
                        lastDigits: '',
                        activate: true,
                    };
                }
                else
                {
                    activateCardRequestModel = {
                        requestInfo: requestInfo,
                        cardNumber: this.cardDetail.primeCardSerno,
                        oTP: otp,
                        isPhysical: 'O',
                        lastDigits: '',
                        activate: false,
                    };
                }


                cardStatus.ActivateCard(activateCardRequestModel).then(response =>
                {
                    console.log(response);
                    if (response['error'])
                    {
                        this.alertMessage = soapErrorMessages.get('108', '');
                        this.alertType = 'error'
                        this.showAlert = true
                        setTimeout(() =>
                        {
                            this.showAlert = false
                        }, 5000)
                    }
                    else
                    {
                        if (response.data['responseInfo'])
                        {
                            let res = response.data['responseInfo'];
                            if (res.errorCode == '00000')
                            {
                                this.step = 3;
                            }
                            else
                            {
                                this.alertMessage = soapErrorMessages.get('108', '');
                                this.alertType = 'error'
                                this.showAlert = true
                                setTimeout(() =>
                                {
                                    this.showAlert = false
                                }, 5000)
                            }
                        }
                        else
                        {
                            this.alertMessage = soapErrorMessages.get('108', '');
                            this.alertType = 'error'
                            this.showAlert = true
                            setTimeout(() =>
                            {
                                this.showAlert = false
                            }, 5000)
                        }
                    }
                });
            }

            catch (e)
            {
                this.alertMessage = soapErrorMessages.get('108', '');
                this.alertType = 'error'
                this.showAlert = true
                setTimeout(() =>
                {
                    this.showAlert = false
                }, 5000)
            }
        },

    },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.v-application.theme--light .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}

.refresh {
    color: blue !important;
}

.return {
    width: 35%;
}

.v-btn--round {
    border-radius: 0px !important;
}

.secure-img {
    background: #e2f1fd;
    width: 123px;
    height: 123px;
    padding-top: 33px;
    border-radius: 79px;
    margin: 0 auto;
}

@media screen and (max-width: 1000px) {
    .line-section {
        display: none;
    }
}
</style>