<template>
    <div class="text-center">
        <v-dialog v-model="dialog">
            <v-card class="security v-dialog-logout">
                <v-card-title>
                    <span v-if="!card.isSusByUser"
                          class="font-weight-semibold text-color-black mx-1"
                          style="font-size: 14px;">
                        {{ $t('Temporary suspend card') }}
                    </span>
                    <span v-if="card.isSusByUser"
                          class="font-weight-semibold text-color-black mx-1"
                          style="font-size: 14px;">
                        {{ $t('Unsuspend card') }}
                    </span>
                    <span class="dialog-close en-close">
                        <v-icon @click="close">
                            {{ icons.mdiClose }}
                        </v-icon>
                    </span>
                </v-card-title>



                <v-card-text class="pa-7">
                    <span class="font-weight-semibold text-color-black mx-1"
                          style="font-size: 14px;">
                        {{ $t('Primary Card') }}
                    </span>
                    <v-card class="pa-4 mt-3">
                        <v-row>
                            <v-col cols="12"
                                   lg="4">
                                <div>
                                    <v-img contain
                                           height="89px"
                                           class="auth-3d-group overflow-hidden"
                                           :src="card.image">
                                    </v-img>
                                </div>
                            </v-col>

                            <v-col lg="8">
                                <div class="mt-2"
                                     v-if="!card.isSusByUser"
                                     style="background: rgba(9, 180, 169, 0.15); color: #09B4A9; width: 50px; font-size: 12px;">
                                    <div class="d-flex justify-center">
                                        {{ $t('Active') }}
                                    </div>
                                </div>
                                <div class="mt-2"
                                     v-if="card.isSusByUser"
                                     style="background: rgba(237, 28, 36, 0.15); color: #D92D20; width: 50%; font-size: 12px;">
                                    <div class="d-flex justify-center">
                                        {{ $t('Temporary suspended') }}
                                    </div>
                                </div>
                                <div class="text-black font-weight-bold mt-1">
                                    <span style="font-size: 12px;"
                                          v-if="selectedLanguage == 'ar'">{{ card.cardNameAr }}</span>
                                    <span style="font-size: 12px;"
                                          v-if="selectedLanguage == 'en'">{{ card.cardName }}</span>
                                </div>
                                <div class="">
                                    <span style="font-size: 12px;">
                                        {{ card.cardMemberName }}
                                    </span>
                                </div>
                                <div class="">
                                    <span style="font-size: 12px;">
                                        {{ $t('Card ending -') }} {{ card.Last4Digits }}
                                    </span>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row class="mt-4">
                            <v-divider class=""></v-divider>
                        </v-row>
                        <div class="switch-btn d-flex justify-space-between font-weight-bold pb-4"
                             style="margin-top: 10px;">
                            <div class="d-flex flex-column mt-4">
                                <span v-if="!card.isSusByUser"
                                      style="font-size: 13px;">
                                    {{ $t('Temporary suspend card') }}
                                </span>
                                <span v-if="card.isSusByUser"
                                      style="font-size: 13px;">
                                    {{ $t('Unsuspend card') }}
                                </span>
                                <!-- <span style="font-size: 11px;">
                                    {{ $t('You can currently use your card as normal') }}
                                </span> -->
                            </div>
                            <div v-if="!card.isSusByUser">
                                <v-switch v-model="isSusByUser"
                                          @click="switchIcon()"
                                          color="info"
                                          value="hide"></v-switch>
                            </div>

                            <div v-if="card.isSusByUser">
                                <v-switch v-model="isNotSusByUser"
                                          @click="switchIcon()"
                                          color="info"
                                          value="hide"></v-switch>
                            </div>
                        </div>
                    </v-card>
                </v-card-text>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import
{
    mdiClose
} from '@mdi/js'
import store from '@/store';
export default {
    props: {
        card: {
            default: '',
            type: Object
        },
        dialog: {
            default: false,
            type: Boolean
        },
    },
    data: () => ({
        icons: {
            mdiClose
        },
        switchOn: [],
        isSusByUser: 'show',
        isNotSusByUser: 'hide',
        selectedLanguage: 'en'
    }),
    created()
    {
        store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
            }
        })
    },
    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang == 'ar')
        {
            this.selectedLanguage = lang;
        }
        else
        {
            this.selectedLanguage = lang;
        }
    },
    methods: {
        switchIcon()
        {
            this.$emit('switchIcon');
        },
        close()
        {
            this.$emit('close');
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.card-action {
    margin-left: 9px;
    background: #E2F1FD;
    width: 29px;
    height: 27px;
    border-radius: 50%;


}

.v-dialog {
    box-shadow: none !important;
}

.switch-btn {
    margin-bottom: -40px;
}

.v-dialog-logout {
    min-width: 250px !important;
    max-width: 500px !important;
    margin: 0 auto;
}

.en-close {
    position: absolute;
    right: 10px;
    cursor: pointer;
}

.action-image {
    height: 35px;
    width: 35px;
    margin: 5px;
    // top: 6px;
}

.security {
    padding-left: 0px !important;
}
</style>