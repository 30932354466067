<template>
    <div class="dialog-off-canvas-main-canvas d-flex flex-column h-100"
         style="background: white;"
         data-off-canvas-main-canvas>
        <CenturionHeader></CenturionHeader>
    <main role="main">
        <a id="main-content"
           tabindex="-1"></a>


        <div class="region region-content">
            <div data-drupal-messages-fallback
                 class="hidden"></div>
            <div id="block-amexsubtheme-content"
                 class="block block-system block-system-main-block">



                <article data-history-node-id="1084"
                         role="article"
                         about="/content/dining-program"
                         class="node node--type-centurion-page node--view-mode-full">





                    <div class="node__content">

                        <div
                             class="field field--name-field-centurion-page-sections field--type-entity-reference-revisions field--label-hidden field__items">
                            <div class="field__item">
                                <div
                                     class="paragraph paragraph--type--centurion-sub-page-banner paragraph--view-mode--default">
                                    <div class="container">
                                        <div class="row g-md-0 black-bg">
                                            <div class="col-md-4">
                                                <div class="sub-page-banner-title">

                                                    <div
                                                         class="clearfix text-formatted field field--name-field-sub-banner-title field--type-text field--label-hidden field__item">
                                                        {{ $t(`Dining Program`) }} </div>


                                                </div>
                                            </div>
                                            <div class="col-md-8">
                                                <div class="sub-page-banner-image">

                                                    <div
                                                         class="field field--name-field-sub-banner-image field--type-image field--label-hidden field__item">
                                                        <img :src="require(`@/assets/images/centurion/dining-program-banner.jpg`)"
                                                             width="650"
                                                             height="449"
                                                             alt=""
                                                             loading="lazy"
                                                             typeof="foaf:Image"
                                                             class="img-fluid" />

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="field__item">
                                <div
                                     class="paragraph paragraph--type--cent-z-shape-image-with-text paragraph--view-mode--default cent-bg-">
                                    <div class="container">
                                        <div class="row g-md-0">
                                            <div
                                                 class="col-12 col-md-7 mt-3 mt-md-0 p-md-0 d-flex align-items-md-end flex-column position-relative cent-imgwithtxt-zshape-image-cont">
                                                <div class="cent-imgwithtxt-zshape-image">

                                                    <div
                                                         class="field field--name-field-image-with-text-icons-img field--type-image field--label-hidden field__item">
                                                        <img :src="require(`@/assets/images/centurion/exclusive-benefits.jpg`)"
                                                             width="500"
                                                             height="500"
                                                             alt=""
                                                             loading="lazy"
                                                             typeof="foaf:Image"
                                                             class="img-fluid" />

                                                    </div>

                                                </div>
                                            </div>
                                            <div
                                                 class="col-12 col-md-5 mt-3 mt-md-0 p-md-0 d-flex align-self-md-center flex-column cent-imgwithtxt-zshape-text-cont">
                                                <div class="cent-imgwithtxt-zshape-text">

                                                    <div
                                                         class="clearfix text-formatted field field--name-field-image-with-text-icon-title field--type-text-long field--label-hidden field__item">
                                                        <p>{{ $t(`Discover Exclusive Benefits With The Centurion Card
                                                            Local Dining Program`) }}</p>
                                                    </div>


                                                    <div
                                                         class="clearfix text-formatted field field--name-field-image-with-text-icons-text field--type-text-long field--label-hidden field__items">
                                                        <div class="field__item">
                                                            <p>{{ $t(`As a Centurion card member, you have access to an
                                                                exceptional local dining program
                                                                that elevates your culinary experiences. This program
                                                                oﬀers a variety of exclusive
                                                                beneﬁts designed to enhance your dining adventures.`) }}
                                                            </p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="field__item">
                                <div
                                     class="paragraph paragraph--type--cent-z-shape-image-with-text paragraph--view-mode--default cent-bg-">
                                    <div class="container">
                                        <div class="row g-md-0">
                                            <div
                                                 class="col-12 col-md-7 mt-3 mt-md-0 p-md-0 d-flex align-items-md-end flex-column position-relative cent-imgwithtxt-zshape-image-cont">
                                                <div class="cent-imgwithtxt-zshape-image">

                                                    <div
                                                         class="field field--name-field-image-with-text-icons-img field--type-image field--label-hidden field__item">
                                                        <img :src="require(`@/assets/images/centurion/different-cuisines.jpg`)"
                                                             width="500"
                                                             height="500"
                                                             alt=""
                                                             loading="lazy"
                                                             typeof="foaf:Image"
                                                             class="img-fluid" />

                                                    </div>

                                                </div>
                                            </div>
                                            <div
                                                 class="col-12 col-md-5 mt-3 mt-md-0 p-md-0 d-flex align-self-md-center flex-column cent-imgwithtxt-zshape-text-cont">
                                                <div class="cent-imgwithtxt-zshape-text">

                                                    <div
                                                         class="clearfix text-formatted field field--name-field-image-with-text-icon-title field--type-text-long field--label-hidden field__item">
                                                        <p>{{ $t(`Explore Diﬀerent Cuisines With Your Centurion Card`)
                                                            }}</p>
                                                    </div>


                                                    <div
                                                         class="clearfix text-formatted field field--name-field-image-with-text-icons-text field--type-text-long field--label-hidden field__items">
                                                        <div class="field__item">
                                                            <p>{{ $t(`You can explore diﬀerent cuisines with Your
                                                                Centurion Card. Whether you are in the
                                                                mood for ﬁne dining, casual eateries, or unique
                                                                gastronomic experiences, the Centurion
                                                                dining program has something for every palate.`) }}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="field__item">
                                <div
                                     class="paragraph paragraph--type--cent-z-shape-image-with-text paragraph--view-mode--default cent-bg-">
                                    <div class="container">
                                        <div class="row g-md-0">
                                            <div
                                                 class="col-12 col-md-7 mt-3 mt-md-0 p-md-0 d-flex align-items-md-end flex-column position-relative cent-imgwithtxt-zshape-image-cont">
                                                <div class="cent-imgwithtxt-zshape-image">

                                                    <div
                                                         class="field field--name-field-image-with-text-icons-img field--type-image field--label-hidden field__item">
                                                        <img :src="require(`@/assets/images/centurion/cent-dining-benefits.jpg`)"
                                                             width="500"
                                                             height="500"
                                                             alt=""
                                                             loading="lazy"
                                                             typeof="foaf:Image"
                                                             class="img-fluid" />

                                                    </div>

                                                </div>
                                            </div>
                                            <div
                                                 class="col-12 col-md-5 mt-3 mt-md-0 p-md-0 d-flex align-self-md-center flex-column cent-imgwithtxt-zshape-text-cont">
                                                <div class="cent-imgwithtxt-zshape-text">

                                                    <div
                                                         class="clearfix text-formatted field field--name-field-image-with-text-icon-title field--type-text-long field--label-hidden field__item">
                                                        <p>{{ $t(`Enjoy Exclusive Centurion Beneﬁts`) }}</p>
                                                    </div>


                                                    <div
                                                         class="clearfix text-formatted field field--name-field-image-with-text-icons-text field--type-text-long field--label-hidden field__items">
                                                        <div class="field__item">
                                                            <div class="row image-txticons-margin">
                                                                <div class="col-2">
                                                                    <div class="image-txticons-icons"><img alt=""
                                                                             class="img-fluid"
                                                                             :src="require(`@/assets/images/centurion/welcome-drink.jpg`)" />
                                                                    </div>
                                                                </div>

                                                                <div class="col-10">
                                                                    <div class="image-txticons-txt"><span><strong>
                                                                                {{ $t(`Complimentary Welcome Drinks`)
                                                                                }}</strong></span></div>
                                                                </div>
                                                            </div>

                                                            <div class="row image-txticons-margin">
                                                                <div class="col-2">
                                                                    <div class="image-txticons-icons"><img alt=""
                                                                             class="img-fluid"
                                                                             :src="require(`@/assets/images/centurion/reservation.jpg`)" />
                                                                    </div>
                                                                </div>

                                                                <div class="col-10">
                                                                    <div class="image-txticons-txt"><span><strong>{{
                                                                                $t(`Same-Day Reservations`)
                                                                                }}</strong></span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="row image-txticons-margin">
                                                                <div class="col-2">
                                                                    <div class="image-txticons-icons"><img alt=""
                                                                             class="img-fluid"
                                                                             :src="require(`@/assets/images/centurion/exclusive.jpg`)" /></div>
                                                                </div>

                                                                <div class="col-10">
                                                                    <div class="image-txticons-txt"><span><strong>{{
                                                                                $t(`Exclusive Discounts`)
                                                                                }}</strong></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="field__item">
                                <div
                                     class="paragraph paragraph--type--centurion-page-title paragraph--view-mode--default cent-bg-2">
                                    <div class="container">
                                        <div class="row g-md-0">
                                            <div
                                                 class="col-12 mt-3 mt-md-0 p-md-0 d-flex align-items-md-center flex-column justify-content-md-center position-relative">
                                                <div class="cent-homepage-title">

                                                    <div
                                                         class="clearfix text-formatted field field--name-field-centurion-page-title-text field--type-text-long field--label-hidden field__item">
                                                        <p class="title">{{ $t(`Embark on a journey of exquisite
                                                            culinary adventures and unforgettable
                                                            dining experiences`) }}</p>

                                                    </div>


                                                    <div
                                                         class="clearfix text-formatted field field--name-field-centurion-title-sub-text field--type-text-long field--label-hidden field__item">
                                                        <p class="text">{{ $t(`With Your Centurion Card, savor exclusive
                                                            privileges such as complimentary
                                                            welcome drinks, discounts of up to 20%, and same-day
                                                            reservations. Indulge in the ﬁnest
                                                            dining, enjoying the unparalleled beneﬁts of Your Centurion
                                                            Card. Click on your
                                                            preferred cuisine to discover its exclusive advantages!`) }}
                                                        </p>

                                                        <p class="text">{{ $t(`For more information and booking, please
                                                            call your Centurion Relationship
                                                            Manager or`) }}<br />
                                                            {{ $t(`From inside Saudi Arabia:`) }} <a
                                                               href="tel:800 122 5225">800 122 5225</a><br />
                                                            {{ $t(`From outside Saudi Arabia:`) }} <a
                                                               href="tel:(+966) 11 407 1999">(+966) 11 407
                                                                1999</a><br />
                                                            {{ $t(`Or email:`) }} <a
                                                               href="mailto:centurion@americanexpress.com.sa">centurion@americanexpress.com.sa</a>
                                                        </p>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="field__item">
                                <div
                                     class="paragraph paragraph--type--centurion-restaurant-container paragraph--view-mode--default cent-bg-">
                                    <div class="restaurants-pad">
                                        <div class="container g-md-0">
                                            <div class="row g-md-0">
                                                <div
                                                     class="col-12 mt-3 mt-md-0 p-md-0 d-flex flex-column justify-content-md-center position-relative">
                                                    <div class="cent-restaurants-container">
                                                        <div class="row">
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/kayzoo_0.jpg`)"
                                                                                         width="229"
                                                                                         height="163"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/billionaire_0.jpg`)"
                                                                                         width="223"
                                                                                         height="62"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/crazy-pizza_0.jpg`)"
                                                                                         width="227"
                                                                                         height="160"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/avens_0.jpg`)"
                                                                                         width="227"
                                                                                         height="97"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/shang-palace_0.jpg`)"
                                                                                         width="148"
                                                                                         height="166"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/urth-caffe_0.jpg`)"
                                                                                         width="187"
                                                                                         height="184"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/myazu_0.jpg`)"
                                                                                         width="197"
                                                                                         height="44"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/beefbarr_0.jpg`)"
                                                                                         width="192"
                                                                                         height="166"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/madeo-tm_0.jpg`)"
                                                                                         width="202"
                                                                                         height="118"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/robata_0.jpg`)"
                                                                                         width="197"
                                                                                         height="28"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/cicchetti-gold_0.jpg`)"
                                                                                         width="174"
                                                                                         height="141"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/sassi_0.jpg`)"
                                                                                         width="192"
                                                                                         height="80"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/chez-bruno.jpg`)"
                                                                                         width="194"
                                                                                         height="137"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/hakkasan.jpg`)"
                                                                                         width="178"
                                                                                         height="36"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/longchim.jpg`)"
                                                                                         width="195"
                                                                                         height="65"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/coya.jpg`)"
                                                                                         width="179"
                                                                                         height="186"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/sarabeths.jpg`)"
                                                                                         width="190"
                                                                                         height="117"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/AOK.jpg`)"
                                                                                         width="171"
                                                                                         height="110"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/asmak-wadi-el-nile.jpg`)"
                                                                                         width="152"
                                                                                         height="150"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/black-tap.jpg`)"
                                                                                         width="181"
                                                                                         height="64"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/chi-spacca.jpg`)"
                                                                                         width="178"
                                                                                         height="126"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/gymkhana.jpg`)"
                                                                                         width="178"
                                                                                         height="178"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/IL-baretto.jpg`)"
                                                                                         width="178"
                                                                                         height="95"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/les-deux-magot.jpg`)"
                                                                                         width="177"
                                                                                         height="48"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/le-vesuvio.jpg`)"
                                                                                         width="178"
                                                                                         height="69"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/Crustacean.jpg`)"
                                                                                         width="180"
                                                                                         height="110"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/mr-chow.jpg`)"
                                                                                         width="179"
                                                                                         height="126"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/raouls.jpg`)"
                                                                                         width="177"
                                                                                         height="77"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/roka.jpg`)"
                                                                                         width="183"
                                                                                         height="69"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/ruya.jpg`)"
                                                                                         width="178"
                                                                                         height="63"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/scotts.jpg`)"
                                                                                         width="178"
                                                                                         height="53"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/spago.jpg`)"
                                                                                         width="171"
                                                                                         height="67"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/sobhy-kaber.jpg`)"
                                                                                         width="173"
                                                                                         height="147"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-3 resmar">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-restaurant-items paragraph--view-mode--default">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-restaurant-items">

                                                                                <div
                                                                                     class="field field--name-field-restaurant-logo field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/ferdi.jpg`)"
                                                                                         width="140"
                                                                                         height="91"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </article>

            </div>

        </div>


    </main>
    <CenturionFooter></CenturionFooter>
    </div>
</template>

<script>
import store from '@/store';

import CenturionHeader from './centurion-header.vue';
import CenturionFooter from './centurion-footer.vue';

export default {
    components: {
        CenturionHeader,
        CenturionFooter
    },
    data: () => ({
        offers: [],
        loaded: true,
        selectedLanguage: 'en',
        heading: 'Available Offers',
        loadedCount: 0,
        unsubscribe: null,
    }),
    created()
    {
        this.unsubscribe = store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
                if (store.get('requestKeys/accessToken') && this.loadedCount == 0)
                {
                    this.loadedCount++;
                }

            }
        })

    },

    destroyed()
    {
        this.unsubscribe();
    },
    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang)
        {
            this.selectedLanguage = localStorage.getItem('language');
        }
        // this.loggedOffers();
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
@import './centurion.scss';
@import './bootstrap.css';

.gray-white {
    background: linear-gradient(to bottom, #fff 80%, #f8f8f8 20%);
}

.black-bg {
    background-color: #000000;
}

.title {
    font-size: 1.5em;
    font-weight: bold;
    color: #ffffff;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    transform: translateY(1.5em);
    text-align: center;
}

.image img {}

@media only screen and (max-width: 576px) {
    .black-bg {
        margin-bottom: 20%;
    }

    .title {
        font-size: 2em;
        text-align: center;
        margin-top: 1em;
    }

    .image {
        transform: translateY(50%);
        text-align: center;
        margin-top: -25%;
    }
}

.text {
    max-width: 70%;
    margin: 0 auto;
    color: #ffffff;
    margin-bottom: 1em;
}

@media only screen and (max-width: 576px) {
    .text {
        max-width: 100%;
    }
}
</style>