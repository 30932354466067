import { render, staticRenderFns } from "./centurion-dining.vue?vue&type=template&id=5aead3cc&scoped=true&"
import script from "./centurion-dining.vue?vue&type=script&lang=js&"
export * from "./centurion-dining.vue?vue&type=script&lang=js&"
import style0 from "./centurion-dining.vue?vue&type=style&index=0&id=5aead3cc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aead3cc",
  null
  
)

export default component.exports