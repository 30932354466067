import axios from './axiosInit';
import store from '@/store';
const login = async (data) =>
{
  try
  {
    store.dispatch('loader/show');
    const result = await axios.post('hpsbridge/hpsbridge_staging/se/user/login', data)
    store.dispatch('loader/hide');
    return result
  } catch (e)
  {
    // console.log(e)
  }
}

const generateOtp = async (data) =>
{
  try
  {
    store.dispatch('loader/show');
    const result = await axios.post('hpsbridge/hpsbridge_staging/se/Otp/generateOtp', data)
    store.dispatch('loader/hide');
    return result
  } catch (e)
  {
    // console.log(e)
  }
}

const verifyOTP = async (data) =>
{
  try
  {
    store.dispatch('loader/show');
    const result = await axios.post('hpsbridge/hpsbridge_staging/se/Otp/validateOtp', data)
    store.dispatch('loader/hide');
    return result
  } catch (e)
  {
    // console.log(e)
  }
}

export default {
  login,
  generateOtp,
  verifyOTP
}
