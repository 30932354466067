import { make } from 'vuex-pathify';

export const name = 'cardStore';

const namespaced = true;
const state = {
    allCards: null,
    primaryCards: null,
    activeCards: null,
    inActiveCards:null,
    notActiveCards:null,
    accountCards: null,
    selectedAccount: null,
    tripPlan: null
 
};
const getters = { ...make.getters(state) };
const actions = { ...make.actions(state) };
const mutations = { ...make.mutations(state) };

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};