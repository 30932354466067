import axios from './axiosInit'
import store from '@/store';

// import { xml2json } from 'xml-js';
const xmlToJson = require('xml-to-json-stream');
const parser = xmlToJson({ attributeMode: false });

// const {convertXML, createAST} = require("simple-xml-to-json")

const soapAPI = async (obj) =>
{
    try
    {
        // store.dispatch('loader/show');
        let message = `<soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
                            <soap:Body>
                            <GetSummaryMR xmlns='http://tempuri.org/'>
                                <MessageID>6.2.0.1</MessageID>
                                <CorrelationID>${obj.correlationID}</CorrelationID>
                                <MessageStamp>${obj.messageStamp}</MessageStamp>
                                <Channel>CONS</Channel>
                                <SystemID>${obj.sysId}</SystemID>
                                <SystemAuth>${obj.sysAuth}</SystemAuth>
                                <RequestorID>${obj.requestorID}</RequestorID>
                                <IdentifierSeed>${obj.idSeed}</IdentifierSeed>
                                <loyaltyAccountNumber>${obj.loyaltyAccountNumber}</loyaltyAccountNumber>
                            </GetSummaryMR>
                            </soap:Body>
                        </soap:Envelope>`
        const result = await axios.post('mobilehps/AppFEStarAPI.asmx', message, { headers: { 'Content-Type': 'text/xml' } })
        // store.dispatch('loader/hide');
        return parseXmlToJson(result.data)
    } catch (e)
    {
        // console.log(e)
    }
}

const addNewTrip = async (data) =>
{
    let cardPlan = data.plan[1].cardPlan;
    try
    {
        store.dispatch('loader/show');
        let message = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tem="http://tempuri.org/">
            <soapenv:Header/>
                <soapenv:Body>
                    <AddPlan xmlns='http://tempuri.org/'>
                    <Lang>E</Lang>
                    <MessageID>6.2.0.1</MessageID>
                    <CorrelationID>${data.correlationID}</CorrelationID>
                    <Channel>CONS</Channel>
                    <MessageStamp>${data.messageStamp}</MessageStamp>
                    <SystemID>${data.sysId}</SystemID>
                    <SystemAuth>${data.sysAuth}</SystemAuth>
                    <RequestorID>${data.requestorID}</RequestorID>
                    <IdentifierSeed>${data.idSeed}</IdentifierSeed>
                    <entityref>U</entityref>
                    <entityrefserno>${data.clientCode}</entityrefserno>
                    <tripPlan>
                        <Serno>${data.tripSerNo}</Serno>
                        <Description></Description>
                        <RequesterId>${data.requestorID}</RequesterId>
                        <custserno>0</custserno>
                        <TripPlanCards>`;

        for (let i = 0; i < cardPlan.length; i++)
        {
            message += `<TripPlanCard>
                    <Serno>0</Serno>
                    <PrimeCardSerno>${cardPlan[i].primeCardSerno}</PrimeCardSerno>
                    <CardMemberName>${cardPlan[i].CardMemberName}</CardMemberName>
                    <Product>${cardPlan[i].Product}</Product>
                    <Last4Digits>${cardPlan[i].Last4Digits}</Last4Digits>
                    <ProductCode>${cardPlan[i].AccountProductCode}</ProductCode>
                </TripPlanCard>`;
        }
        message += `</TripPlanCards>
                          <TripPlanDestinations>
                          <TripPlanDestination>
                          <Serno>${data.plan[0].Serno}</Serno>
                          <tripPlanSerno>0</tripPlanSerno>
                          <Country>${data.plan[0].Country}</Country>
                          <StartDate>${data.plan[0].StartDate}</StartDate>
                          <EndDate>${data.plan[0].EndDate}</EndDate>
                          </TripPlanDestination>
                          </TripPlanDestinations>
                          </tripPlan>
                          </AddPlan>
                          </soapenv:Body>
                          </soapenv:Envelope>`;

        const result = await axios.post('mobilehps/AppFEWSTripPlan.asmx', message, { headers: { 'Content-Type': 'text/xml', 'SOAPAction': 'http://tempuri.org/AddPlan' } })
        store.dispatch('loader/hide');
        return parseXmlToJson(result.data)
    } catch (e)
    {
        // console.log(e)
    }
}



const updateTrip = async (data) =>
{
    try
    {
        store.dispatch('loader/show');
        let cardPlan = data.plan[1].cardPlan;
        let message = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tem="http://tempuri.org/">
            <soapenv:Header/>
                <soapenv:Body>
                    <UpdatePlan xmlns='http://tempuri.org/'>
                        <MessageID>6.2.0.1</MessageID>
                        <CorrelationID>${data.correlationID}</CorrelationID>
                        <Channel>CONS</Channel>
                        <MessageStamp>${data.messageStamp}</MessageStamp>
                        <SystemID>${data.sysId}</SystemID>
                        <SystemAuth>${data.sysAuth}</SystemAuth>
                        <RequestorID>${data.requestorID}</RequestorID>
                        <IdentifierSeed>${data.idSeed}</IdentifierSeed>
                        <entityref>U</entityref>
                        <entityrefserno>${data.clientCode}</entityrefserno>
                        <tripPlan>
                            <Serno>${data.tripSerNo}</Serno>
                            <Description></Description>
                            <RequesterId>${data.requestorID}</RequesterId>
                            <custserno>0</custserno>
                            <TripPlanCards>`;

        for (let i = 0; i < cardPlan.length; i++)
        {
            message += `<TripPlanCard>
                            <Serno>${cardPlan[i].Serno}</Serno>
                            <PrimeCardSerno>${cardPlan[i].primeCardSerno}</PrimeCardSerno>
                            <CardMemberName>${cardPlan[i].CardMemberName}</CardMemberName>
                            <Product>${cardPlan[i].Product}</Product>
                            <Last4Digits>${cardPlan[i].Last4Digits}</Last4Digits>
                            <ProductCode>${cardPlan[i].AccountProductCode}</ProductCode>
                        </TripPlanCard>`;
        }
        message += `</TripPlanCards>
                            <TripPlanDestinations>
                                <TripPlanDestination>
                                    <Serno>0</Serno>
                                    <tripPlanSerno>0</tripPlanSerno>
                                    <Country>${data.plan[0]?.Country}</Country>
                                    <StartDate>${data.plan[0]?.StartDate}</StartDate>
                                    <EndDate>${data.plan[0]?.EndDate}</EndDate>
                                </TripPlanDestination>
                            </TripPlanDestinations>
                        </tripPlan>
                    </UpdatePlan>
                </soapenv:Body>
          </soapenv:Envelope>`;

        const result = await axios.post('mobilehps/AppFEWSTripPlan.asmx', message, { headers: { 'Content-Type': 'text/xml', 'SOAPAction': 'http://tempuri.org/UpdatePlan' } })
        store.dispatch('loader/hide');
        return parseXmlToJson(result.data)
    } catch (e)
    {
        // console.log(e)
    }
}


const removeTrip = async (data) =>
{
    try
    {
        let removeTripXml = `<soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
        <soap:Body>
        <RemovePlan xmlns='http://tempuri.org/'>
        <MessageID>6.2.0.1</MessageID>
        <CorrelationID>${data.correlationID}</CorrelationID>
        <Channel>CONS</Channel>
        <MessageStamp>messageStamp</MessageStamp>
        <SystemID>${data.sysId}</SystemID>
        <SystemAuth>${data.sysAuth}</SystemAuth>
        <RequestorID>${data.requestorID}</RequestorID>
        <IdentifierSeed>${data.idSeed}</IdentifierSeed>
        <entityref>U</entityref>
        <entityrefserno>${data.clientCode}</entityrefserno>
        <serno>${data.serno}</serno>
        <Lang>E</Lang>
        </RemovePlan>
        </soap:Body>
        </soap:Envelope>`;

        const result = await axios.post('mobilehps/AppFEWSTripPlan.asmx', removeTripXml, { headers: { 'Content-Type': 'text/xml', 'SOAPAction': 'http://tempuri.org/RemovePlan' } })
        return parseXmlToJson(result.data)
    } catch (e)
    {
        // console.log(e)
    }
}



const getTrip = async (obj) =>
{
    try
    {
        store.dispatch('loader/show');
        let message = `<soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
        <soap:Body>
        <GetPlans xmlns='http://tempuri.org/'>
        <MessageID>6.2.0.1</MessageID>
        <CorrelationID>${obj.correlationID}</CorrelationID>
        <Channel>CONS</Channel>
        <MessageStamp>${obj.messageStamp}</MessageStamp>
        <SystemID>${obj.sysId}</SystemID>
        <SystemAuth>${obj.sysAuth}</SystemAuth>
        <RequestorID>${obj.requestorID}</RequestorID>
        <IdentifierSeed>${obj.idSeed}</IdentifierSeed>
        <entityref>U</entityref>
        <entityrefserno>${obj.clientCode}</entityrefserno>
        <Lang>E</Lang>
        </GetPlans>
        </soap:Body>
        </soap:Envelope>`;

        const result = await axios.post('mobilehps/AppFEWSTripPlan.asmx', message, { headers: { 'Content-Type': 'text/xml' } })
        store.dispatch('loader/hide');
        // let json = xmlToJson(result.data);

        return parser.xmlToJson(result.data, (err, jsn) =>
        {
            if (err)
            {
                //error handling
            }

            return jsn['soap:Envelope']['soap:Body']['GetPlansResponse']['GetPlansResult']['Root']
        });

        // parser.xmlToJson(result.data)
        // return json
    } catch (e)
    {
        // console.log(e)
    }
}
let trips = [];
function tripXmlToJson(xml, isRecord)
{

    if (isRecord == true)
    {
        trips = [];
        isRecord = false;
    }
    // // console.log(xml);
    const json = {};
    for (const res of xml.matchAll(/(?:<(\w*)(?:\s[^>]*)*>)((?:(?!<\1).)*)(?:<\/\1>)|<(\w*)(?:\s*)*\/>/gm))
    {

        // console.log(res);
        const key = res[1] || res[3];
        // console.log(key);
        const value = res[2] && tripXmlToJson(res[2]).json;
        if (res[1] == "Plan")
        {
            trips.push(value)
        }
        json[key] = ((value && Object.keys(value).length) ? value : res[2]) || null;

    }
    // console.log('after', json, trips)
    return {
        json: json,
        trips: trips
    };

}



const updateContactInfo = async (data) =>
{
    try
    {
        let updateInfoXml = `<soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
        <soap:Body>
          <SetContact xmlns='http://www.americanexpress.com.sa/'>
            <MessageID>${messageID}</MessageID>
            <CorrelationID>${correlationID}</CorrelationID>
            <MessageStamp>${messageStamp}</MessageStamp>
            <Channel>CONS</Channel>
            <SystemID>${sysId}</SystemID>
            <SystemAuth>${data.sysAuth}</SystemAuth>
            <RequestorID>${data.requestorID}</RequestorID>
            <IdentifierSeed>${data.idSeed}</IdentifierSeed>
            <PeopleSerno>${data.peopleSerno}</PeopleSerno>
            <Email>${data.email}</Email>
            <Mobile>${data.mobile}</Mobile>
            <AccSerno>${data.accSerno}</AccSerno>
            <eStmtFlag>${data.eStntFlag}</eStmtFlag>
            <Lang>${data.lang}</Lang>
          </SetContact>
        </soap:Body>
      </soap:Envelope>`;

        const result = await axios.post('mobilehps/AppFEWSUpdate.asmx', message, { headers: { 'Content-Type': 'text/xml' } })
        return parseXmlToJson(result.data)
    } catch (e)
    {
        // console.log(e)
    }
}

const editIdDetail = async (data) =>
{
    try
    {
        let editIdXml = `<soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
        <soap:Body>
        <SaveDocument  xmlns='http://www.americanexpress.com.sa/'>
        <MessageID>${messageID}</MessageID>
        <CorrelationID>${correlationID}</CorrelationID>
        <MessageStamp>${messageStamp}</MessageStamp>
        <Rsv></Rsv>
        <SystemID>${sysId}</SystemID>
        <SystemAuth>${data.sysAuth}</SystemAuth>
        <RequestorID>${data.requestorID}</RequestorID>
        <IdentifierSeed>${data.idSeed}</IdentifierSeed>
        <DocumentData>${data.base64Binary}</DocumentData>
        <DocumentName>${data.name}</DocumentName>
        <DocumentLength>${data.length}</DocumentLength>
        <Lang>${data.lang}</Lang>
        </SaveDocument>
        </soap:Body>
        </soap:Envelope>`;

        const result = await axios.post('mobilehps/AppFEWSUploadFile.asm', message, { headers: { 'Content-Type': 'text/xml' } })
        return parseXmlToJson(result.data)
    } catch (e)
    {
        // console.log(e)
    }
}


const updateIdDetail = async (data) =>
{
    try
    {
        store.dispatch('loader/show');
        let message = `<soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
        <soap:Body>
        <SRPersonalInformationUpdate  xmlns='http://www.americanexpress.com.sa/'>
        <MessageID>6.2.0.1</MessageID>
        <CorrelationID>${data.correlationID}</CorrelationID>
        <MessageStamp>${data.messageStamp}</MessageStamp>
        <Channel>CONS</Channel>
        <SystemID>${data.sysId}</SystemID>
        <SystemAuth>${data.sysAuth}</SystemAuth>
        <RequestorID>${data.requestorID}</RequestorID>
        <IdentifierSeed>${data.idSeed}</IdentifierSeed>
        <CustNo>${data.custNo}</CustNo>
        <AccNo>${data.accNo}</AccNo>
        <CardNo>${data.cardNo}</CardNo>
        <updateRequest>${data.updateRequest}</updateRequest>
        <Lang>E</Lang>
        <Nationality>${data.nationality}</Nationality>
        <IDNumber>${data.idNumber}</IDNumber>
        <IDExpiry>${data.idExpiry}</IDExpiry>
        <eStmtEmail>${data.eStmtEmail}</eStmtEmail>
        <MailMobile>${data.mailMobile}</MailMobile>
        <HomeEmail>${data.homeEmail}</HomeEmail>
        <WorkEmail>${data.workEmail}</WorkEmail>
        <HomeMobile>${data.homeMobile}</HomeMobile>
        <WorkMobile>${data.workMobile}</WorkMobile>
        <MailAddress1>${data.mailAddress1}</MailAddress1>
        <MailAddress2>${data.mailAddress2}</MailAddress2>
        <MailCity>${data.mailCity}</MailCity>
        <MailZip>${data.mailZip}</MailZip>
        <MailCountry>${data.mailCountry}</MailCountry>
        <HomeTel1>${data.homeTel1}</HomeTel1>
        <HomeTel2>${data.HomeTel2}</HomeTel2>
        <WorkTel1>${data.workTel1}</WorkTel1>
        <WorkTel2>${data.workTel2}</WorkTel2>
        <SuppMobile1>${data.suppMobile1}</SuppMobile1>
        <SuppEmail1>${data.suppEmail1}</SuppEmail1>
        <SuppMobile2>${data.suppMobile2}</SuppMobile2>
        <SuppEmail2>${data.suppEmail2}</SuppEmail2>
        <SuppMobile3>${data.psuppMobile3}</SuppMobile3>
        <SuppEmail3>${data.suppEmail3}</SuppEmail3>
        <SuppMobile4>${data.suppMobile4}</SuppMobile4>
        <SuppEmail4>${data.suppEmail4}</SuppEmail4>
        <SuppMobile5>${data.suppMobile5}</SuppMobile5>
        <SuppEmail5>${data.suppEmail5}</SuppEmail5>
        <SuppMobile6>${data.suppMobile6}</SuppMobile6>
        <SuppEmail6>${data.suppEmail6}</SuppEmail6>
        <SuppMobile7>${data.suppMobile7}</SuppMobile7>
        <SuppEmail7>${data.suppEmail7}</SuppEmail7>
        <SuppMobile8>${data.suppMobile8}</SuppMobile8>
        <SuppEmail8>${data.suppEmail8}</SuppEmail8>
        <SuppMobile9>${data.suppMobile9}</SuppMobile9>
        <SuppEmail9>${data.suppEmail9}</SuppEmail9>
        <ClientMessage>${data.clientMessage}</ClientMessage>
        <SessionID>1</SessionID>
        <fileName>${data.fileName}</fileName>
        </SRPersonalInformationUpdate>
        </soap:Body>
        </soap:Envelope>`;

        const result = await axios.post('mobilehps/AppFEWSAltitude.asmx', message, { headers: { 'SOAPAction': 'http://www.americanexpress.com.sa/SRPersonalInformationUpdate', 'Content-Type': 'text/xml;charset=UTF-8' } })
        store.dispatch('loader/hide');
        return parseXmlToJson(result.data)
    } catch (e)
    {
        // console.log(e)
    }
}





const updateProfileDetail = async (data) =>
{
    try
    {
        store.dispatch('loader/show');
        let message = `<soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
        <soap:Body>
        <SRPersonalInformationUpdateWithAuthCode  xmlns='http://www.americanexpress.com.sa/'>
        <MessageID>6.2.0.1</MessageID>
        <CorrelationID>${data.correlationID}</CorrelationID>
        <MessageStamp>${data.messageStamp}</MessageStamp>
        <Channel>CONS</Channel>
        <SystemID>${data.sysId}</SystemID>
        <SystemAuth>${data.sysAuth}</SystemAuth>
        <RequestorID>${data.requestorID}</RequestorID>
        <IdentifierSeed>${data.idSeed}</IdentifierSeed>
        <CustNo>${data.custNo}</CustNo>
        <AccNo>${data.accNo}</AccNo>
        <CardNo>${data.cardNo}</CardNo>
        <updateRequest>${data.updateRequest}</updateRequest>
        <Lang>E</Lang>
        <Nationality>${data.nationality}</Nationality>
        <IDNumber>${data.idNumber}</IDNumber>
        <IDExpiry>${data.idExpiry}</IDExpiry>
        <eStmtEmail>${data.eStmtEmail}</eStmtEmail>
        <MailMobile>${data.mailMobile}</MailMobile>
        <HomeEmail>${data.homeEmail}</HomeEmail>
        <WorkEmail>${data.workEmail}</WorkEmail>
        <HomeMobile>${data.homeMobile}</HomeMobile>
        <WorkMobile>${data.workMobile}</WorkMobile>
        <MailAddress1>${data.mailAddress1}</MailAddress1>
        <MailAddress2>${data.mailAddress2}</MailAddress2>
        <MailCity>${data.mailCity}</MailCity>
        <MailZip>${data.mailZip}</MailZip>
        <MailCountry>${data.mailCountry}</MailCountry>
        <HomeTel1>${data.homeTel1}</HomeTel1>
        <HomeTel2>${data.homeTel2}</HomeTel2>
        <WorkTel1>${data.workTel1}</WorkTel1>
        <WorkTel2>${data.workTel2}</WorkTel2>
        <SuppMobile1>${data.suppMobile1}</SuppMobile1>
        <SuppEmail1>${data.suppEmail1}</SuppEmail1>
        <SuppMobile2>${data.suppMobile2}</SuppMobile2>
        <SuppEmail2>${data.suppEmail2}</SuppEmail2>
        <SuppMobile3>${data.suppMobile3}</SuppMobile3>
        <SuppEmail3>${data.suppEmail3}</SuppEmail3>
        <SuppMobile4>${data.suppMobile4}</SuppMobile4>
        <SuppEmail4>${data.suppEmail4}</SuppEmail4>
        <SuppMobile5>${data.suppMobile5}</SuppMobile5>
        <SuppEmail5>${data.suppEmail5}</SuppEmail5>
        <SuppMobile6>${data.suppMobile6}</SuppMobile6>
        <SuppEmail6>${data.suppEmail6}</SuppEmail6>
        <SuppMobile7>${data.suppMobile7}</SuppMobile7>
        <SuppEmail7>${data.suppEmail7}</SuppEmail7>
        <SuppMobile8>${data.suppMobile8}</SuppMobile8>
        <SuppEmail8>${data.suppEmail8}</SuppEmail8>
        <SuppMobile9>${data.suppMobile9}</SuppMobile9>
        <SuppEmail9>${data.suppEmail9}</SuppEmail9>
        <ClientMessage>${data.clientMessage}</ClientMessage>
        <SessionID>1</SessionID>
        <fileName>${data.fileName}</fileName>
        <AuthCode>${data.authCode}</AuthCode>
        </SRPersonalInformationUpdateWithAuthCode>
        </soap:Body>
        </soap:Envelope>`;

        const result = await axios.post('mobilehps/AppFEWSAltitude.asmx', message, { headers: { 'SOAPAction': 'http://www.americanexpress.com.sa/SRPersonalInformationUpdateWithAuthCode', 'Content-Type': 'text/xml;charset=UTF-8' } })
        store.dispatch('loader/hide');
        return parseXmlToJson(result.data)
    } catch (e)
    {
        // console.log(e)
    }
}

// Request for OTP
const otpCard = async (data) =>
{
    try
    {
        store.dispatch('loader/show');
        let message = `<soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
        <soap:Body>
        <RequestAuthCode  xmlns='http://www.americanexpress.com.sa/'>
          <MessageID>6.2.0.1</MessageID>
          <CorrelationID>${data.correlationID}</CorrelationID>
          <MsgStp>${data.messageStamp}</MsgStp>
          <channel>CONS</channel>
          <SystemID>${data.sysId}</SystemID>
          <SystemAuth>${data.sysAuth}</SystemAuth>
          <RequestorID>${data.requestorID}</RequestorID>
          <IdentifierSeed>${data.idSeed}</IdentifierSeed>
         <Rsv>1</Rsv>
         <CustSerNo>${data.custSerNo}</CustSerNo>
         <CardSserno>${data.cardSserno}</CardSserno>
         <ServiceId>001</ServiceId>
          <Lang>E</Lang>
         </RequestAuthCode>
         </soap:Body>
         </soap:Envelope>`;

        const result = await axios.post('mobilehps/AppFEAuthCode.asmx', message, { headers: { 'Content-Type': 'text/xml' } })
        store.dispatch('loader/hide');
        return parseXmlToJson(result.data)
    } catch (e)
    {
        // console.log(e)
    }
}


// Request for ActivateCard
const activateCard = async (data) =>
{
    try
    {
        store.dispatch('loader/show');
        let cardXml = `<soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
        <soap:Body>
        <ActDeActCard xmlns='http://www.americanexpress.com.sa/'>
         <MessageID>6.2.0.1</MessageID>
         <CorrelationID>${data.correlationID}</CorrelationID>
         <MsgStp>${data.messageStamp}</MsgStp>
         <channel>CONS</channel>
         <SystemID>${data.sysId}</SystemID>
         <SystemAuth>${data.sysAuth}</SystemAuth>
         <RequestorID>${data.requestorID}</RequestorID>
         <IdentifierSeed>${data.idSeed}</IdentifierSeed>
         <Rsv>${data.accountProductCode}</Rsv>
         <CustSerNo>${data.custSerNo}</CustSerNo>
         <CardSserno>${data.cardSserno}</CardSserno>
         <AuthCode>${data.authCode}</AuthCode>
         <ActivateDeActivate>${data.activateDeActivate}</ActivateDeActivate>
          <Lang>E</Lang>
         </ActDeActCard>
         </soap:Body>
         </soap:Envelope>`;

        const result = await axios.post('mobilehps/AppFEWSActDeActCard.asmx', cardXml, { headers: { 'Content-Type': 'text/xml' } })
        store.dispatch('loader/hide');
        return parseXmlToJson(result.data)
    } catch (e)
    {
        // console.log(e)
    }
}

const suspendCard = async (data) =>
{
    try
    {
        let suspendCardXml = `<soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
        <soap:Body>
        <RequestAuthCode  xmlns='http://www.americanexpress.com.sa/'>
          <MessageID>${messageID}</MessageID>
          <CorrelationID>${correlationID}</CorrelationID>
          <MsgStp>${data.msgStp}</MsgStp>
          <channel>CONS</channel>
          <SystemID>${sysId}</SystemID>
          <SystemAuth>${data.sysAuth}</SystemAuth>
          <RequestorID>${data.requestorID}</RequestorID>
          <IdentifierSeed>${data.idSeed}</IdentifierSeed>
         <Rsv>1</Rsv>
         <CustSerNo>${data.custSerNo}</CustSerNo>
         <CardSserno>${data.cardSserno}</CardSserno>
         <ServiceId>${data.sid}</ServiceId>
          <Lang>${data.lang}</Lang>
         </RequestAuthCode>
         </soap:Body>
         </soap:Envelope>`;

        const result = await axios.post('mobilehps/AppFEAuthCode.asmx', message, { headers: { 'Content-Type': 'text/xml' } })
        return parseXmlToJson(result.data)
    } catch (e)
    {
        // console.log(e)
    }
}


const changeCardLimit = async (data) =>
{
    try
    {
        // store.dispatch('loader/show');
        let cardLimitXml = `<soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
        <soap:Body>
          <SaveDocument xmlns='http://www.americanexpress.com.sa/'>
            <MessageID>6.2.0.1</MessageID>
            <CorrelationID>${data.correlationID}</CorrelationID>
            <MessageStamp>${data.messageStamp}</MessageStamp>
            <Rsv></Rsv>
            <SystemID>${data.sysId}</SystemID>
            <SystemAuth>${data.sysAuth}</SystemAuth>
            <RequestorID>${data.requestorID}</RequestorID>
            <IdentifierSeed>${data.idSeed}</IdentifierSeed>
            <DocumentData>${data.base64Binary}</DocumentData>
            <DocumentName>${data.filename}</DocumentName>
            <DocumentLength>${data.length}</DocumentLength>
            <Lang>E</Lang>
          </SaveDocument>
        </soap:Body>
      </soap:Envelope>`;

        const result = await axios.post('mobilehps/AppFEWSUploadFile.asmx', cardLimitXml, { headers: { 'Content-Type': 'text/xml', 'SOAPAction': 'http://www.americanexpress.com.sa/SaveDocument' } })
        // store.dispatch('loader/hide');
        return parseXmlToJson(result.data)
    } catch (e)
    {
        // console.log(e)
    }
}


const submitForChangeLimit = async (data) =>
{
    try
    {
        store.dispatch('loader/show');
        let cardLimitXml = `<soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
        <soap:Body>
          <SRLOCIncrease xmlns='http://www.americanexpress.com.sa/'>
            <MessageID>6.2.0.1</MessageID>
            <CorrelationID>${data.correlationID}</CorrelationID>
            <MessageStamp>${data.messageStamp}</MessageStamp>
            <Channel>CONS</Channel>
            <SystemID>${data.sysId}</SystemID>
            <SystemAuth>${data.sysAuth}</SystemAuth>
            <RequestorID>${data.requestorID}</RequestorID>
            <IdentifierSeed>${data.idSeed}</IdentifierSeed>
            <CustNo>${data.custNo}</CustNo>
            <AccNo>${data.accNo}</AccNo>
            <CardNo>${data.cardNo}</CardNo>
            <Lang>E</Lang>
            <ContactMethod>${data.contactMethod}</ContactMethod>
            <NewLOC>${data.newLOC}</NewLOC>
            <ClientMessage>${data.clientMessage}</ClientMessage>
            <fileName>${data.filename}</fileName>
            <SessionID>${data.sessionId}</SessionID>
          </SRLOCIncrease>
        </soap:Body>
      </soap:Envelope>`;
        const result = await axios.post('mobilehps/AppFEWSAltitude.asmx', cardLimitXml, { headers: { 'Content-Type': 'text/xml' } })
        store.dispatch('loader/hide');
        return parseXmlToJson(result.data)
    } catch (e)
    {
        // console.log(e)
    }
}

const temporarySuspendCard = async (data) =>
{
    try
    {
        store.dispatch('loader/show');
        let viewCardXml = `
        <soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
             <soap:Body>
             <ActDeActCard xmlns='http://www.americanexpress.com.sa/'>
              <MessageID>6.2.0.1</MessageID>
              <CorrelationID>${data.correlationID}</CorrelationID>
              <MsgStp>${data.messageStamp}</MsgStp>
              <channel>CONS</channel>
              <SystemID>${data.sysId}</SystemID>
              <SystemAuth>${data.sysAuth}</SystemAuth>
              <RequestorID>${data.requestorID}</RequestorID>
              <IdentifierSeed>${data.idSeed}</IdentifierSeed>
              <Rsv>1</Rsv>
              <CustSerNo>${data.custSerNo}</CustSerNo>
              <CardSserno>${data.cardSserno}</CardSserno>
              <AuthCode>${data.authCode}</AuthCode>
              <ActivateDeActivate>${data.activateDeActivate}</ActivateDeActivate>
               <Lang>E</Lang>
              </ActDeActCard>
              </soap:Body>
              </soap:Envelope>
`;

        const result = await axios.post('mobilehps/AppFEWSActDeActCard.asmx', viewCardXml, { headers: { 'Content-Type': 'text/xml' } })
        store.dispatch('loader/hide');
        return parseXmlToJson(result.data)
    } catch (e)
    {
        // console.log(e)
    }
}

const viewCardPin = async (data) =>
{
    try
    {
        // store.dispatch('loader/show');
        let viewCardXml = `<soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
        <soap:Body>
        <RequestAuthCode  xmlns='http://www.americanexpress.com.sa/'>
          <MessageID>6.2.0.1</MessageID>
          <CorrelationID>${data.correlationID}</CorrelationID>
          <MsgStp>${data.messageStamp}</MsgStp>
          <channel>CONS</channel>
          <SystemID>${data.sysId}</SystemID>
          <SystemAuth>${data.sysAuth}</SystemAuth>
          <RequestorID>${data.requestorID}</RequestorID>
          <IdentifierSeed>${data.idSeed}</IdentifierSeed>
         <Rsv>1</Rsv>
         <CustSerNo>${data.custSerNo}</CustSerNo>
         <CardSserno>${data.cardSserno}</CardSserno>
         <ServiceId>001</ServiceId>
          <Lang>E</Lang>
         </RequestAuthCode>
         </soap:Body>
         </soap:Envelope>`;

        const result = await axios.post('mobilehps/AppFEAuthCode.asmx', viewCardXml, { headers: { 'Content-Type': 'text/xml' } })
        // store.dispatch('loader/hide');
        return parseXmlToJson(result.data)
    } catch (e)
    {
        // console.log(e)
    }
}
const getOtp = async (data) =>
{
    try
    {
        store.dispatch('loader/show');
        let viewCardXml = `<soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
        <soap:Body>
        <RequestAuthCode  xmlns='http://www.americanexpress.com.sa/'>
          <MessageID>6.2.0.1</MessageID>
          <CorrelationID>${data.correlationID}</CorrelationID>
          <MsgStp>${data.messageStamp}</MsgStp>
          <channel>CONS</channel>
          <SystemID>${data.sysId}</SystemID>
          <SystemAuth>${data.sysAuth}</SystemAuth>
          <RequestorID>${data.requestorID}</RequestorID>
          <IdentifierSeed>${data.idSeed}</IdentifierSeed>
         <Rsv>1</Rsv>
         <CustSerNo>${data.custSerNo}</CustSerNo>
         <CardSserno>${data.cardSserno}</CardSserno>
         <ServiceId>001</ServiceId>
          <Lang>E</Lang>
         </RequestAuthCode>
         </soap:Body>
         </soap:Envelope>`;

        const result = await axios.post('mobilehps/AppFEAuthCode.asmx', viewCardXml, { headers: { 'Content-Type': 'text/xml' } })
        store.dispatch('loader/hide');
        return parseXmlToJson(result.data)
    } catch (e)
    {
        // console.log(e)
    }
}
const viewCardPinCode = async (data) =>
{
    try
    {
        
        let viewCardXml = `<soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
        <soap:Body>
        <ViewCashPin xmlns='http://www.americanexpress.com.sa/'>
          <MessageID>6.2.0.1</MessageID>
          <CorrelationID>${data.correlationID}</CorrelationID>
          <MsgStp>${data.messageStamp}</MsgStp>
          <channel>CONS</channel>
          <SystemID>${data.sysId}</SystemID>
          <SystemAuth>${data.sysAuth}</SystemAuth>
          <RequestorID>${data.requestorID}</RequestorID>
          <IdentifierSeed>${data.idSeed}</IdentifierSeed>
         <Rsv>1</Rsv>
         <CustSerNo>${data.custSerNo}</CustSerNo>
         <CardSserno>${data.cardSserno}</CardSserno>
         <AuthCode>${data.authCode}</AuthCode>
         <seqNo>${data.seqNo}</seqNo>
          <Lang>E</Lang>
         </ViewCashPin>
         </soap:Body>
         </soap:Envelope>
`;

        const result = await axios.post('mobilehps/AppFEWSViewCashPin.asmx', viewCardXml, { headers: { 'Content-Type': 'text/xml' } })
       
        return parseXmlToJson(result.data)
    } catch (e)
    {
        // console.log(e)
    }
}


const cardIssue = async (data) =>
{
    try
    {
        store.dispatch('loader/show');
        let message = `<soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
        <soap:Body>
        <SRCardNotReceived xmlns='http://www.americanexpress.com.sa/'>
          <MessageID>6.2.0.1</MessageID>
          <CorrelationID>${data.correlationID}</CorrelationID>
          <MessageStamp>${data.messageStamp}</MessageStamp>
          <Channel>CONS</Channel>
          <SystemID>${data.sysId}</SystemID>
          <SystemAuth>${data.sysAuth}</SystemAuth>
          <RequestorID>${data.requestorID}</RequestorID>
          <IdentifierSeed>${data.idSeed}</IdentifierSeed>
         <CustNo>${data.clientCode}</CustNo>
         <AccNo>${data.accountNo}</AccNo>
         <CardNo>${data.cardSrno}</CardNo>
         <Lang>E</Lang>
         <ContactMethod>${data.contactMethod}</ContactMethod>
         <ClientMessage>${data.clientMessage}</ClientMessage>
         <SessionID>1</SessionID>
         </SRCardNotReceived>
         </soap:Body>
         </soap:Envelope>`;

        const result = await axios.post('mobilehps/AppFEWSAltitude.asmx', message, { headers: { 'Content-Type': 'text/xml' } })
        store.dispatch('loader/hide');
        return parseXmlToJson(result.data)
    } catch (e)
    {
        // console.log(e)
    }
}


const updateSupplementaryCard = async (data) =>
{
    try
    {
        let updateSupplementaryCardXml = `<soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
        <soap:Body>
        <RequestAuthCode  xmlns='http://www.americanexpress.com.sa/'>
          <MessageID>${messageID}</MessageID>
          <CorrelationID>${correlationID}</CorrelationID>
          <MsgStp>${data.msgStp}</MsgStp>
          <channel>CONS</channel>
          <SystemID>${sysId}</SystemID>
          <SystemAuth>${data.sysAuth}</SystemAuth>
          <RequestorID>${data.requestorID}</RequestorID>
          <IdentifierSeed>${data.idSeed}</IdentifierSeed>
         <Rsv>1</Rsv>
         <CustSerNo>${data.custSerNo}</CustSerNo>
         <CardSserno>${data.cardSserno}</CardSserno>
         <ServiceId>${data.sid}</ServiceId>
          <Lang>${data.lang}</Lang>
         </RequestAuthCode>
         </soap:Body>
         </soap:Envelope>`;

        const result = await axios.post('mobilehps/AppFEAuthCode.asmx', message, { headers: { 'Content-Type': 'text/xml' } })
        return parseXmlToJson(result.data)
    } catch (e)
    {
        // console.log(e)
    }
}


const getNearbyAtms = async (data) =>
{

    try
    {
        let nearbyAtmsXml = `<soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
        <soap:Body>
          <findLocationOffers xmlns='http://www.americanexpress.com.sa/'>
            <MessageID>6.2.0.1</MessageID>
            <CorrelationID>1</CorrelationID>
            <MessageStamp>${data.messageStamp}</MessageStamp>
            <Channel>CONS</Channel>
            <SystemID>${data.sysId}</SystemID>
            <SystemAuth>${data.authToken}</SystemAuth>
            <RequestorID>I_anonymous_user</RequestorID>
            <IdentifierSeed>${data.idSeed}</IdentifierSeed>
            <offer>N</offer>
            <latitude_direction>${data.latitudeDirection}</latitude_direction>
            <latitude_degree>${data.latitudeDegree}</latitude_degree>
            <latitude_minute>${data.latitudeMinute}</latitude_minute>
            <latitude_second>${data.latitudeSecond}</latitude_second>
            <longitude_direction>${data.longitudeDirection}</longitude_direction>
            <longitude_degree>${data.longitudeDegree}</longitude_degree>
            <longitude_minute>${data.longitudeMinute}</longitude_minute>
            <longitude_second>${data.longitudeSecond}</longitude_second>
            <Lang>E</Lang>
            <category>atm</category>
            <p_type></p_type>
            <range>500</range>
            <country>682</country>
            <city></city>
          </findLocationOffers>
        </soap:Body>
      </soap:Envelope>`;

        const result = await axios.post('mobilehps/AppFEWSGeoLocationOffers.asmx', nearbyAtmsXml, { headers: { 'SOAPAction': 'http://www.americanexpress.com.sa/findLocationOffers', 'Content-Type': 'text/xml;charset=UTF-8' } })
        return alertXmlToJson(result.data, true)
    } catch (e)
    {
        // console.log(e)
    }
}

const downloadStatement = async (data) =>
{
    try
    {
        let downloadStatementXml = `<soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
        <soap:Body>
          <GetMailFile xmlns='http://www.americanexpress.com.sa/'>
            <MessageID>${messageID}</MessageID>
            <CorrelationID>${correlationID}</CorrelationID>
            <MsgStp>${data.msgStp}</MsgStp>
            <Rsv></Rsv>
            <SystemID>${sysId}</SystemID>
            <SystemAuth>${data.sysAuth}</SystemAuth>
            <RequestorID>${data.requestorID}</RequestorID>
            <IdentifierSeed>${data.idSeed}</IdentifierSeed>
            <AccSerNo>${data.accSerno}</AccSerNo>
            <CustSerNo>${data.custSerNo}</CustSerNo>
            <MsgID>${data.msgID}</MsgID>
            <Lang>${data.lang}</Lang>
            <FileName></FileName>
            <FileDate></FileDate>
            <binFile></binFile>
          </GetMailFile>
        </soap:Body>
      </soap:Envelope>`;

        const result = await axios.post('mobilehps/AppFEWSMailFile.asmx', message, { headers: { 'Content-Type': 'text/xml' } })
        return parseXmlToJson(result.data)
    } catch (e)
    {
        // console.log(e)
    }
}

const redAlerts = async (data) =>
{
    try
    {
        let redAlertsXml = `
        <soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
          <soap:Body>
            <ReadAlerts xmlns='http://www.americanexpress.com.sa/'>
              <MessageID>${messageID}</MessageID>
              <CorrelationID>${correlationID}</CorrelationID>
              <MessageStamp>${messageStamp}</MessageStamp>
              <Channel>CONS</Channel>
              <SystemID>${sysId}</SystemID>
              <SystemAuth>${data.sysAuth}</SystemAuth>
              <RequestorID>${data.requestorID}</RequestorID>
              <IdentifierSeed>${data.idSeed}</IdentifierSeed>
              <Lang>${data.lang}</Lang>
            </ReadAlerts>
          </soap:Body>
        </soap:Envelope>`;

        const result = await axios.post('mobilehps/AppFEWSNotifications.asmx', message, { headers: { 'Content-Type': 'text/xml' } })
        return parseXmlToJson(result.data)
    } catch (e)
    {
        // console.log(e)
    }
}

const updateStatmentStatus = async (data) =>
{
    try
    {
        let updateStatmentStatusXml = `<soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
        <soap:Body>
          <SetContact xmlns='http://www.americanexpress.com.sa/'>
            <MessageID>${messageID}</MessageID>
            <CorrelationID>${correlationID}</CorrelationID>
            <MessageStamp>${messageStamp}</MessageStamp>
            <Channel>CONS</Channel>
            <SystemID>${sysId}</SystemID>
            <SystemAuth>${data.sysAuth}</SystemAuth>
            <RequestorID>${data.requestorID}</RequestorID>
            <IdentifierSeed>${data.idSeed}</IdentifierSeed>
            <PeopleSerno>${data.peopleSerno}</PeopleSerno>
            <Email>${data.email}</Email>
            <Mobile>${data.mobile}</Mobile>
            <AccSerno>${data.accSerno}</AccSerno>
            <eStmtFlag>${data.eStntFlag}</eStmtFlag>
            <Lang>${data.lang}</Lang>
          </SetContact>
        </soap:Body>
      </soap:Envelope>`;

        const result = await axios.post('mobilehps/AppFEWSUpdate.asmx', message, { headers: { 'Content-Type': 'text/xml' } })
        return parseXmlToJson(result.data)
    } catch (e)
    {
        // console.log(e)
    }
}


const updateNotificationStatus = async (data) =>
{
    try
    {
        let updateNotificationStatusXml = `<soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
        <soap:Body>
          <SetAlert  xmlns='http://www.americanexpress.com.sa/'>
            <MessageID>${data.messageID}</MessageID>
            <CorrelationID>${data.correlationID}</CorrelationID>
            <MessageStamp>${data.messageStamp}</MessageStamp>
            <Channel>CONS</Channel>
            <SystemID>${sysId}</SystemID>
            <SystemAuth>${data.sysAuth}</SystemAuth>
            <RequestorID>${data.requestorID}</RequestorID>
            <IdentifierSeed>${data.idSeed}</IdentifierSeed>
            <AccSerno>${data.accSerno}</AccSerno>
            <CardSerno>${data.cardSerno}</CardSerno>
            <NotificationId>${data.notificationId}</NotificationId>
            <NotificationStatus>${data.notificationStatus}</NotificationStatus>
            <EmailActv>${data.email}Actv</EmailActv>
            <MobileActv>${data.mobile}Actv</MobileActv>
            <Editable>${data.editable}</Editable>
            <Criteria>${data.criteria}</Criteria>
            <UserId>${data.userId}</UserId>
            <Lang>${data.lang}</Lang>
          </SetAlert>
        </soap:Body>
      </soap:Envelope>`;

        const result = await axios.post('mobilehps/AppFEWSNotifications.asmx', message, { headers: { 'Content-Type': 'text/xml' } })
        return parseXmlToJson(result.data)
    } catch (e)
    {
        // console.log(e)
    }
}
const bilingDispute = async (data) =>
{
    try
    {
        store.dispatch('loader/show');
        let message =
            `<soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
                  <soap:Body>
                  <SRBillingDispute xmlns='http://www.americanexpress.com.sa/'>
                   <MessageID>6.2.0.1</MessageID>
                  <CorrelationID>${data.correlationID}</CorrelationID>
                    <MessageStamp>${data.messageStamp}</MessageStamp>
                    <Channel>CONS</Channel>
                    <SystemID>${data.sysId}</SystemID>
                    <SystemAuth>${data.sysAuth}</SystemAuth>
                    <RequestorID>${data.requestorID}</RequestorID>
                    <IdentifierSeed>${data.idSeed}</IdentifierSeed>
                      <CustNo>${data.custNo}</CustNo>
                      <AccNo>${data.accNo}</AccNo>
                      <CardNo>${data.cardNo}</CardNo>
                      <Lang>E</Lang>
                      <AccStmtDate>${data.accStmtDate}</AccStmtDate>
                      <postedDate>${data.posDate}</postedDate>
                      <trxnDate>${data.trxnDate}</trxnDate>
                      <description>${data.description ?? ''}</description>
                      <embossingName>${data.subtitle}</embossingName>
                      <category></category>
                    <last4Digits>${data.last4digits}</last4Digits>
                      <trxnCur>${data.transCurCode}</trxnCur>
                      <PosEntry>${data.cachBackAmt}</PosEntry>
                      <BillingAmount>${data.BillingAmount}</BillingAmount>
                      <TrxnAmount>${data.transAmt}</TrxnAmount>
                      <TrxnSerno>${data.transId}</TrxnSerno>
                      <ContactMethod>PHA</ContactMethod>
                      <ClientMessage>${data.clientMessage}</ClientMessage>
                      <fileName>${data.fileName}</fileName>
                      <SessionID>1</SessionID>
                   </SRBillingDispute>
                   </soap:Body>
                   </soap:Envelope>`
        const result = await axios.post('mobilehps/AppFEWSAltitude.asmx', message, { headers: { 'SOAPAction': 'http://www.americanexpress.com.sa/SRBillingDispute', 'Content-Type': 'text/xml' } })
        store.dispatch('loader/hide');
        return parseXmlToJson(result.data)
    } catch (e)
    {
        // console.log(e)
    }
}
const downloadDocument = async (data) =>
{
    try
    {
        store.dispatch('loader/show');
        let message = `<soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
        <soap:Body>
          <GetMailFile xmlns='http://www.americanexpress.com.sa/'>
            <MessageID>6.2.0.1</MessageID>
            <CorrelationID>${data.correlationID}</CorrelationID>
            <MsgStp>${data.msgStp}</MsgStp>
            <Rsv></Rsv>
            <SystemID>CONS</SystemID>
            <SystemAuth>${data.sysAuth}</SystemAuth>
            <RequestorID>${data.requestorID}</RequestorID>
            <IdentifierSeed>${data.idSeed}</IdentifierSeed>
            <AccSerNo>${data.accSerno}</AccSerNo>
            <CustSerNo>${data.custSerNo}</CustSerNo>
            <MsgID>${data.msgID}</MsgID>
            <Lang>E</Lang>
            <FileName></FileName>
            <FileDate></FileDate>
            <binFile></binFile>
          </GetMailFile>
        </soap:Body>
      </soap:Envelope>`;

        const result = await axios.post('mobilehps/AppFEWSMailFile.asmx', message, { headers: { 'SOAPAction': 'http://www.americanexpress.com.sa/GetMailFile', 'Content-Type': 'text/xml;charset=UTF-8' } })
        store.dispatch('loader/hide');
        return parseXmlToJson(result.data)
    } catch (e)
    {
        // console.log(e)
    }
}

const addSupplementary = async (data) =>
{
    try
    {
        store.dispatch('loader/show');
        let message =
            `<soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
                  <soap:Body>
                  <SRNewSuppCard xmlns='http://www.americanexpress.com.sa/'>
                    <messageId>6.2.0.1</messageId>
                    <correlationId>${data.correlationID}</correlationId>
                    <messageStamp>${data.messageStamp}</messageStamp>
                    <channel>CONS</channel>
                    <systemId>${data.sysId}</systemId>
                    <systemAuth>${data.sysAuth}</systemAuth>
                    <requestorId>${data.requestorID}</requestorId>
                    <identifierSeed>${data.idSeed}</identifierSeed>
                    <customerNo>${data.custNo}</customerNo>
                    <accountNo>${data.accNo}</accountNo>
                    <cardNo>${data.cardNo}</cardNo>
                    <language>E</language>
                    <title>${data.title}</title>
                    <firstName>${data.firstName}</firstName>
                    <midName>${data.midName}</midName>
                    <lastName>${data.lastName}</lastName>
                    <nationality>${data.nationality}</nationality>
                    <IdNumber>${data.idNumber}</IdNumber>
                    <Dob>${data.dob}</Dob>
                    <gender>${data.gender}</gender>
                    <mobile>${data.mobile}</mobile>
                    <email>${data.email}</email>
                    <sDocument>${data.sDocument}</sDocument>
                    <relation>${data.relation}</relation>
                    <companyName>${data.companyName}</companyName>
                    <section>${data.Section}</section>
                    <billingType>${data.BillingType}</billingType>
                    <maritalStatus>${data.MaritalStatus}</maritalStatus>
                    <passportNumber>${data.passportNumber}</passportNumber>
                    <foreignId>${data.ForeignID}</foreignId>
                    <mailAddress1>${data.MailAddress1}</mailAddress1>
                    <mailAddress2>${data.MailAddress2}</mailAddress2>
                    <mailPoBox>${data.MailPOBox}</mailPoBox>
                    <mailCity>${data.MailCity}</mailCity>
                    <mailProvince>${data.MailProvince}</mailProvince>
                    <mailZip>${data.MailZip}</mailZip>
                    <mailCountry>${data.MailCountry}</mailCountry>
                    <mailTelephone1>${data.MailTel1}</mailTelephone1>
                    <mailTelephone2>${data.MailTel2}</mailTelephone2>
                    <mailFax>${data.MailFax}</mailFax>
                    <mailMobile>${data.MailMobile}</mailMobile>
                    <mailEmail>${data.MailEmail}</mailEmail>
                    <homeAddress1>${data.HomeAddress1}</homeAddress1>
                    <homeAddress2>${data.HomeAddress2}</homeAddress2>
                    <homePoBox>${data.HomePOBox}</homePoBox>
                    <homeCity>${data.HomeCity}</homeCity>
                    <homeProvince>${data.HomeProvince}</homeProvince>
                    <homeZip>${data.HomeZip}</homeZip>
                    <homeCountry>${data.HomeCountry}</homeCountry>
                    <homeTelephone1>${data.HomeTel1}</homeTelephone1>
                    <HomeTelephone2>${data.HomeTel2}</HomeTelephone2>
                    <homeFax>${data.HomeFax}</homeFax>
                    <homeMobile>${data.HomeMobile}</homeMobile>
                    <homeEmail>${data.HomeEmail}</homeEmail>
                    <employeeId>${data.EmpID}</employeeId>
                    <jobTitle>${data.JobTitle}</jobTitle>
                    <yearsOfService>${data.YearsofService}</yearsOfService>
                    <monthlySalary>${data.MonthlySalry}</monthlySalary>
                    <costCenterName>${data.CostCenterName}</costCenterName>
                    <maxLimit>${data.MaxLimit}</maxLimit>
                    <detailDescription>${data.DetailDesc}</detailDescription>
                    <sessionId>1</sessionId>
                    <fileData>${data.fileData}</fileData>
                    <fileType>${data.fileType}</fileType>
                    <fileData2>${data.fileData2}</fileData2>
                    <fileType2>${data.fileType2}</fileType2>
                    <languagepreferencecode>${data.languageCheck}</languagepreferencecode>
                    <boolparam001>${data.smsCheck}</boolparam001>
                    <boolparam002>${data.emailCheck}</boolparam002>
                    <boolparam003>${data.phoneCheck}</boolparam003>
                   </SRNewSuppCard>
                   </soap:Body>
                   </soap:Envelope>`

        let headers = {
            'SOAPAction': 'http://www.americanexpress.com.sa/SRNewSuppCard',
            'Content-Type': 'text/xml;charset=UTF-8',
        }


        const result = await axios.post('mobilehps/AppFEWSAltitude.asmx', message, { headers })
        store.dispatch('loader/hide');
        return parseXmlToJson(result.data)
    } catch (e)
    {
        // console.log(e)
    }
}
let records = [];
function alertXmlToJson(xml, isRecord)
{

    if (isRecord == true)
    {
        records = [];
        isRecord = false;
    }
    // // console.log(xml);
    const json = {};
    for (const res of xml.matchAll(/(?:<(\w*)(?:\s[^>]*)*>)((?:(?!<\1).)*)(?:<\/\1>)|<(\w*)(?:\s*)*\/>/gm))
    {

        const key = res[1] || res[3];
        const value = res[2] && alertXmlToJson(res[2]).json;
        if (res[1] == "Record")
        {
            records.push(value)
        }
        json[key] = ((value && Object.keys(value).length) ? value : res[2]) || null;

    }
    return {
        json: json,
        records: records
    };

}

function parseXmlToJson(xml)
{
    const json = {};
    for (const res of xml.matchAll(/(?:<(\w*)(?:\s[^>]*)*>)((?:(?!<\1).)*)(?:<\/\1>)|<(\w*)(?:\s*)*\/>/gm))
    {
        const key = res[1] || res[3];
        const value = res[2] && parseXmlToJson(res[2]);
        json[key] = ((value && Object.keys(value).length) ? value : res[2]) || null;

    }
    return json;

}

const AlertsNotificationsRepo = async (data) =>
{
    try
    {
        store.dispatch('loader/show');
        let message = `<soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
        <soap:Body>
          <ReadAlerts xmlns='http://www.americanexpress.com.sa/'>
            <MessageID>6.2.0.1</MessageID>
            <CorrelationID>${data.correlationID}</CorrelationID>
            <MessageStamp>${data.messageStamp}</MessageStamp>
            <Channel>CONS</Channel>
            <SystemID>${data.sysId}</SystemID>
            <SystemAuth>${data.sysAuth}</SystemAuth>
            <RequestorID>${data.requestorID}</RequestorID>
            <IdentifierSeed>${data.idSeed}</IdentifierSeed>
            <Lang>E</Lang>
          </ReadAlerts>
        </soap:Body>
      </soap:Envelope>`
        const result = await axios.post('mobilehps/AppFEWSNotifications.asmx', message, { headers: { 'Content-Type': 'text/xml' } })
        store.dispatch('loader/hide');
        // return alertXmlToJson(result.data, true)
        return parser.xmlToJson(result.data, (err, jsn) =>
        {
            if (err)
            {
                //error handling
            }

            return jsn['soap:Envelope']['soap:Body']['ReadAlertsResponse']['ReadAlertsResult']['ReadAlerts']
        });
    } catch (e)
    {
        // console.log(e)
    }
}

export default {
    soapAPI,
    otpCard,
    activateCard,
    getTrip,
    addNewTrip,
    updateTrip,
    removeTrip,
    changeCardLimit,
    cardIssue,
    getOtp,
    viewCardPinCode,
    viewCardPin,
    submitForChangeLimit,
    temporarySuspendCard,
    bilingDispute,
    addSupplementary,
    AlertsNotificationsRepo,
    updateProfileDetail,
    updateIdDetail,
    downloadDocument,
    getNearbyAtms

}