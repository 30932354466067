import axios from './axiosInit';
import store from '@/store';
import sharedServices from './shared-services';

let username = store.get('requestKeys/userName')

const generateOtp = async (otpMessageType, otpPurpose) =>
{
    let username = store.get('requestKeys/userName');

    store.dispatch('loader/show');

    return sharedServices.getToken('login', username).then(async entityRes =>
    {
        store.set('requestKeys/channelKey', entityRes.data.results.channelKey);
        store.set('requestKeys/identityKey', entityRes.data.results.identityKey);
        store.set('requestKeys/requestDate', entityRes.data.results.requestDate);

        let payload = {
            requestInfo: {
                channelKey: entityRes.data.results.channelKey,
                identityKey: entityRes.data.results.identityKey,
                requestDate: entityRes.data.results.requestDate,
                requestUID: (Math.floor(1000 + Math.random() * 9000)).toString(),
                channelId: store.get('requestKeys/channelId'),
                userName: store.get('requestKeys/userName'),
                identitySeed: sharedServices.getIdSeed()
            },
            // otpMessageType: "BIOKey",
            otpMessageType: otpMessageType,
            otpPurpose: otpPurpose,

        };

        const auth = {
            headers: {
                Authorization: 'Bearer ' + store.get('requestKeys/accessToken')
            }
        }

        const result = await axios.post('hpsbridge/hpsbridge_staging/se/Otp/generateOtp', payload, auth)
        store.dispatch('loader/hide');
        return result
    })
}

const OutBoundDialerCardActivation = async (data) =>
{
    try
    {
        let username = store.get('requestKeys/userName');

        store.dispatch('loader/show');

        return sharedServices.getToken('entity', username).then(async entityRes =>
        {
            let payload = {
                requestInfo: {
                    channelKey: entityRes.data.results.channelKey,
                    identityKey: entityRes.data.results.identityKey,
                    requestDate: entityRes.data.results.requestDate,
                    requestUID: (Math.floor(1000 + Math.random() * 9000)).toString(),
                    channelId: store.get('requestKeys/channelId'),
                    userName: store.get('requestKeys/userName'),
                    identitySeed: sharedServices.getIdSeed()
                },
                language: data.userLanguage == 'en' ? 'EN' : 'AR',
                fileNumber: "6001114138",
                serviceCode: "SCCA"
            }
            try
            {
                const auth = {
                    headers: {
                        Authorization: 'Bearer ' + store.get('requestKeys/accessToken')
                    }
                }
                // store.dispatch('loader/show');
                const result = await axios.post('hpsbridge/digitaltps_staging/OutBoundDialer/CardActivation', payload, auth)
                store.dispatch('loader/hide');
                return result
            } catch (e)
            {
                console.log(e)
            }
        })

    } catch (e)
    {
        console.log(e)
    }
}

const OutBoundDialerValidateCMResponse = async (data) =>
{
    try
    {
        let username = store.get('requestKeys/userName');

        store.dispatch('loader/show');

        return sharedServices.getToken('entity', username).then(async entityRes =>
        {
            let payload = {
                requestInfo: {
                    channelKey: entityRes.data.results.channelKey,
                    identityKey: entityRes.data.results.identityKey,
                    requestDate: entityRes.data.results.requestDate,
                    requestUID: (Math.floor(1000 + Math.random() * 9000)).toString(),
                    channelId: store.get('requestKeys/channelId'),
                    userName: store.get('requestKeys/userName'),
                    identitySeed: sharedServices.getIdSeed()
                },
                Id: data.id
            }
            try
            {
                const auth = {
                    headers: {
                        Authorization: 'Bearer ' + store.get('requestKeys/accessToken')
                    }
                }
                store.dispatch('loader/show');
                const result = await axios.post('hpsbridge/digitaltps_staging/OutBoundDialer/ValidateCMResponse', payload, auth)
                store.dispatch('loader/hide');
                return result
            }
            catch (e)
            {
                console.log(e)
            }
        })
    }
    catch (e)
    {
        console.log(e)
    }
}

const ActivateCard = async (data) =>
{
    let payload = {
        requestInfo: data.requestInfo,
        cardNumber: data.cardNumber,
        oTP: data.oTP,
        isPhysical: data.isPhysical,
        lastDigits: data.lastDigits,
        activate: data.activate
    }

    try
    {
        const auth = {
            headers: {
                Authorization: 'Bearer ' + store.get('requestKeys/accessToken')
            }
        }
        store.dispatch('loader/show');
        const result = await axios.post('smartphoneapi/SE_InteractiveServices_Staging/se/card/ActivateCard', payload, auth)
        store.dispatch('loader/hide');
        return result
    } catch (e)
    {
        // console.log(e)
    }
}


export default {
    generateOtp,
    OutBoundDialerCardActivation,
    OutBoundDialerValidateCMResponse,
    ActivateCard
}
