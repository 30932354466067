<template>
    <v-card class="mt-3 balance-card-background">
        <v-row class="mx-5 pa-2">
            <v-col cols="12"
                   lg="3"
                   md="12"
                   sm="12">
                <v-card-text class="mt-6 pa-0">
                    <p style="font-size: 22px;"
                       class="text-black font-weight-semibold">
                        {{ $t('Manage Cards') }}
                    </p>
                </v-card-text>
            </v-col>
            <v-col cols="12"
                   lg="3"
                   md="12"
                   sm="12"
                   v-for="(card, idx) in cardsCount"
                   :key="idx">
                <v-card>
                    <v-card-text class="pa-3 mx-3">
                        <div class="text-3xl text-black font-weight-semibold">
                            {{ getCardValue(card.value) }}
                        </div>
                        <div style="font-size: 12px;"
                             class="text-blue font-weight-semibold">
                            {{ $t(card.label) }}
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store';

export default {
    components: {
    },
    data: () => ({
        cardsCount: [
            { value: store.get('cardStore/activeCards').length, label: 'Active Cards' },
            { value: store.get('cardStore/inActiveCards').length, label: 'Suspended Cards' },
            { value: store.get('cardStore/notActiveCards').length, label: 'InActive Cards' },
        ]
    }),
    mounted()
    {

    },
    methods: {
        getCardValue(value){
            
            if(value < 10){
                return '0'+value
            }
            else{
                return value
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.v-application.theme--light .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}

.cut-text {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 146px;
    height: 1.2em;
    white-space: nowrap;
}

.area {
    background-color: white;
}
</style>
;