const msgs = {
    soapErrorCode100: 'The PIN you have entered is incorrect',
    soapErrorCode110: 'NO Date Found.',
    soapErrorCode101: 'Validate-invalid Start/End date, should be greater or equal to current date',
    soapErrorCode103: 'The characters you have entered do not match the CAPTCHA Image. Please try again',
    soapErrorCode109: 'The supporting document you are trying to upload was not accepted',
    soapErrorCode107: 'The file you are trying to download is not available',
    soapErrorCode904: 'Some of the information you have provided is not valid. Please try again',
    soapErrorCode905: 'You have entered an invalid Activation Code. Please try again',
    soapErrorCode901: 'Your mobile device is not registered with American Express Saudi Arabia.  Please tap “Register” and complete the enrollment process to start using your application',
    soapErrorCode920: 'The Card you have entered is not valid for this channel. Please use our Corporate Interactive site accessable from www.americanexpress.com.sa',
    soapErrorCode998: 'You have exceeded the maximum number of request per day. Please try again after 24 hours',
    soapErrorCode102: 'You have an old version of the American Express mobile application. We advise you to update your application to the latest version',
    soapErrorCode200: 'Make sure to complete the enrollment process before attempting to login',
    soapErrorCode111: 'You have reached the maximum number of login attempts. Your PIN is now blocked. To reset your PIN, you may click on (Register) to enroll mobile again',
    soapErrorCode911: 'Something went wrong with your request. Please try to login again.',
    soapErrorCode921: 'Your session has timed out. Please try again',
    invalidCodeTryAgain: "You have entered an invalid activation code. Please try again",
    authMessage: 'The code has been sent through SMS.',
    soapErrorCode146: 'We have encountered a problem while processing your request.We apologize for this inconvenience.Please try again later.',
    soapErrorCode1005: 'Something went wrong with your request. Please try again.',
    soapErrorCode1010: 'Authorization Code is disabled.',
    soapErrorCode55555: 'Supplementary card added successfully.',
    soapErrorCode987987: 'Validation failed, please try again.',
}


const msgsAr = {
    soapErrorCode100: 'رقم التعريف الشخصي الذي أدخلته غير صحيح',
    soapErrorCode110: 'NO Date Found.',
    soapErrorCode101: 'التحقق من صحة تاريخ البدء / الانتهاء ، يجب أن يكون أكبر من التاريخ الحالي أو مساويًا له',
    soapErrorCode103: 'الأحرف التي أدخلتها لا تتطابق مع صورة CAPTCHA. حاول مرة اخرى',
    soapErrorCode109: 'لم يتم قبول المستند الداعم الذي تحاول تحميله',
    soapErrorCode107: 'الملف الذي تحاول تنزيله غير متوفر',
    soapErrorCode904: 'بعض المعلومات التي قدمتها غير صالحة. حاول مرة اخرى',
    soapErrorCode905: 'لقد ادخلت الرمز خطأً، حاول مجدداً',
    soapErrorCode901: 'جهازك المحمول غير مسجل لدى أمريكان إكسبريس السعودية. يرجى النقر على "تسجيل" وإكمال عملية التسجيل لبدء استخدام التطبيق الخاص بك',
    soapErrorCode920: 'البطاقة التي أدخلتها غير صالحة لهذه القناة. يرجى استخدام موقعنا التفاعلي للشركات الذي يمكن الوصول إليه من www.americanexpress.com.sa',
    soapErrorCode998: 'لقد تجاوزت الحد الأقصى لعدد الطلبات في اليوم الواحد. يرجى المحاولة مرة أخرى بعد 24 ساعة',
    soapErrorCode102: 'لديك نسخة قديمة من تطبيق أمريكان إكسبريس للهاتف المحمول. ننصحك بتحديث تطبيقك إلى أحدث إصدار',
    soapErrorCode200: 'تأكد من إكمال عملية التسجيل قبل محاولة تسجيل الدخول',
    soapErrorCode111: 'لقد وصلت إلى الحد الأقصى لعدد محاولات تسجيل الدخول. تم الآن حظر رقم التعريف الشخصي الخاص بك. لإعادة تعيين رقم التعريف الشخصي الخاص بك ، يمكنك النقر فوق (تسجيل) لتسجيل الهاتف المحمول مرة أخرى',
    soapErrorCode911: 'حدث خطأ ما في طلبك. يرجى محاولة تسجيل الدخول مرة أخرى.',
    soapErrorCode921: 'انقضت مهلة جلستك. حاول مرة اخرى',
    invalidCodeTryAgain: 'لقد ادخلت الرمز خطأً، حاول مجدداً',
    // authMessage: 'رمز التفويض يتم تسليمه عبر الرسائل القصيرة.',
    authMessage: 'تم إرسال الرمز عبر الرسائل القصيرة',
    soapErrorCode146: 'لقد واجهتنا مشكلة أثناء معالجة طلبك ، نأسف لهذا الإزعاج ، يرجى المحاولة مرة أخرى لاحقًا.',
    soapErrorCode1005: 'حدث خطأ ما في طلبك. ارجوك تعال.',
    soapErrorCode1010: 'رمز الميزات معطل.',
    soapErrorCode55555: 'تمت إضافة البطاقة الإضافية بنجاح.',
    soapErrorCode987987: 'فشل التحقق من الصحة، يرجى المحاولة مرة أخرى.',
}


const get = (errorCode, apiErrorMessage) =>
{
    let lang = localStorage.getItem('language');
    let errorMessages = msgs;
    if (lang == 'ar')
    {
        errorMessages = msgsAr;
    }
    switch (errorCode)
    {
        case '000':
            return errorMessages['authMessage'];
        case '110':
            return errorMessages['soapErrorCode110'];
        case '1005':
            return errorMessages['soapErrorCode1005'];
        case '1010':
            return errorMessages['soapErrorCode1010'];
        case '101':
            return errorMessages['soapErrorCode101'];
        case '146':
            return errorMessages['soapErrorCode146'];
        case '100':
            return errorMessages['soapErrorCode100'];
        case '102':
            return errorMessages['soapErrorCode102'];
        case '103':
            return errorMessages['soapErrorCode103'];
        case '107':
            return errorMessages['soapErrorCode107'];
        case '108':
            return errorMessages['invalidCodeTryAgain'];
        case '109':
            return errorMessages['soapErrorCode109'];
        case '111':
            return errorMessages['soapErrorCode111'];
        case '200':
            return errorMessages['soapErrorCode200'];
        case '901':
            return errorMessages['soapErrorCode901'];
        case '904':
            return errorMessages['soapErrorCode904'];
        case '905':
            return errorMessages['soapErrorCode905'];
        case '911':
            return errorMessages['soapErrorCode911'];
        case '921':
            return errorMessages['soapErrorCode921'];
        case '920':
            return errorMessages['soapErrorCode920'];
        case '998':
            return errorMessages['soapErrorCode998'];
        case '55555':
            return errorMessages['soapErrorCode55555'];
        case '987987':
            return errorMessages['soapErrorCode987987'];
        default:
            return apiErrorMessage ?? 'something_went_wrong';
    }
}
export default {
    get
}
