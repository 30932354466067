<template>
    <div class="dialog-off-canvas-main-canvas d-flex flex-column h-100"
         style="background: white;"
         data-off-canvas-main-canvas>
        <CenturionHeader></CenturionHeader>
        <main role="main">
            <a id="main-content"
               tabindex="-1"></a>


            <div class="region region-content">
                <div data-drupal-messages-fallback
                     class="hidden"></div>
                <div id="block-amexsubtheme-content"
                     class="block block-system block-system-main-block">



                    <article data-history-node-id="1092"
                             role="article"
                             about="/content/travel-insurance"
                             class="node node--type-centurion-page node--view-mode-full">





                        <div class="node__content">

                            <div
                                 class="field field--name-field-centurion-page-sections field--type-entity-reference-revisions field--label-hidden field__items">
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-sub-page-banner paragraph--view-mode--default">
                                        <div class="container">
                                            <div class="row g-md-0 black-bg">
                                                <div class="col-md-4">
                                                    <div class="sub-page-banner-title">

                                                        <div
                                                             class="clearfix text-formatted field field--name-field-sub-banner-title field--type-text field--label-hidden field__item">
                                                             {{$t(`Travel Insurance`)}}</div>


                                                    </div>
                                                </div>
                                                <div class="col-md-8">
                                                    <div class="sub-page-banner-image">

                                                        <div
                                                             class="field field--name-field-sub-banner-image field--type-image field--label-hidden field__item">
                                                            <img :src="require(`@/assets/images/centurion/travel-insurance-banner.jpg`)"
                                                                 width="643"
                                                                 height="455"
                                                                 alt=""
                                                                 loading="lazy"
                                                                 typeof="foaf:Image"
                                                                 class="img-fluid" />

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">


                                    <div
                                         class="paragraph paragraph--type--block-reference paragraph--view-mode--default">




                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-image-with-text-icons paragraph--view-mode--default cent-bg-">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div
                                                     class="col-12 col-md-6 mt-3 mt-md-0 p-md-0 d-flex align-items-md-center flex-column position-relative">
                                                    <div class="cent-imgwithtxt-icons-image">

                                                        <div
                                                             class="field field--name-field-image-with-text-icons-img field--type-image field--label-hidden field__item">
                                                            <img :src="require(`@/assets/images/centurion/travel-assistance.jpg`)"
                                                                 width="482"
                                                                 height="402"
                                                                 alt=""
                                                                 loading="lazy"
                                                                 typeof="foaf:Image"
                                                                 class="img-fluid" />

                                                        </div>

                                                    </div>
                                                </div>
                                                <div
                                                     class="col-12 col-md-6 mt-3 mt-md-0 p-md-0 d-flex align-self-md-center flex-column">
                                                    <div class="cent-imgwithtxt-icons-text">

                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-icon-title field--type-text-long field--label-hidden field__item">
                                                            <p>{{$t(`TRAVEL ASSISTANCE & INSURANCE`)}}</p>
                                                        </div>


                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-icons-text field--type-text-long field--label-hidden field__items">
                                                            <div class="field__item">
                                                                <p>{{$t(`Travel plans can change unexpectedly. However, you
                                                                    can keep your peace of mind with
                                                                    the support of American Express Centurion services.
                                                                    American Express® Travel Insurance
                                                                    can cover important trip costs when you are far from
                                                                    home.`)}}</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-image-with-text-icons paragraph--view-mode--default cent-bg-">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div
                                                     class="col-12 col-md-6 mt-3 mt-md-0 p-md-0 d-flex align-items-md-center flex-column position-relative">
                                                    <div class="cent-imgwithtxt-icons-image">

                                                        <div
                                                             class="field field--name-field-image-with-text-icons-img field--type-image field--label-hidden field__item">
                                                            <img :src="require(`@/assets/images/centurion/trip-cancelation.jpg`)"
                                                                 width="482"
                                                                 height="402"
                                                                 alt=""
                                                                 loading="lazy"
                                                                 typeof="foaf:Image"
                                                                 class="img-fluid" />

                                                        </div>

                                                    </div>
                                                </div>
                                                <div
                                                     class="col-12 col-md-6 mt-3 mt-md-0 p-md-0 d-flex align-self-md-center flex-column">
                                                    <div class="cent-imgwithtxt-icons-text">

                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-icon-title field--type-text-long field--label-hidden field__item">
                                                            <p>{{$t(`TRIP CANCELLATION & DELAY COVERAGE`)}}</p>
                                                        </div>


                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-icons-text field--type-text-long field--label-hidden field__items">
                                                            <div class="field__item">
                                                                <p>{{$t(`Sometimes the world gets in the way of even the
                                                                    best-planned travel. If you purchase
                                                                    a round trip entirely with your Eligible centurion
                                                                    Card and a covered reason cancels
                                                                    or interrupts your trip. Trip Cancellation and
                                                                    Interruption Insurance can help
                                                                    reimburse your non-refundable expenses purchased
                                                                    with the same Eligible Card. Terms
                                                                    conditions and limitations apply.`)}}</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-free-design paragraph--view-mode--default">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                    <div class="cent-freedesign">
                                                        <div class="row">

                                                            <div
                                                                 class="clearfix text-formatted field field--name-field-free-design-text field--type-text-long field--label-hidden field__item">
                                                                <p class="travelinsurance-title">{{$t(`SUMMARY OF INSURANCE
                                                                    COVERAGE`)}}</p>

                                                                <p class="travelinsurance-text">{{$t(`The annual coverage for
                                                                    you, your children, and your
                                                                    Centurion supplementary cardmembers is exceptional,
                                                                    applying whenever and wherever you
                                                                    book your trips—including your air tickets using
                                                                    your Centurion Card, as long as your
                                                                    journey starts and ends in Saudi Arabia.`)}}</p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-free-design paragraph--view-mode--default">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                    <div class="cent-freedesign">
                                                        <div class="row">

                                                            <div
                                                                 class="clearfix text-formatted field field--name-field-free-design-text field--type-text-long field--label-hidden field__item">
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>{{$t(`Type of Coverage`)}}</th>
                                                                            <th>{{$t(`Coverage Amount`)}}</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Trip Duration Limit`)}}</td>
                                                                            <td>{{$t(`91 days`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Maximum Days Per Policy Period`)}}</td>
                                                                            <td>{{$t(`183 days`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Winter Sports Coverage*`)}}</td>
                                                                            <td>{{$t(`Up to US$ 1,000 (max 17 days per year;
                                                                                for persons under 65)`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Maximum Age Limit`)}}</td>
                                                                            <td>{{$t(`80 years`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Family Cover`)}}</td>
                                                                            <td>{{$t(`Yes, if traveling with the Cardmember`)}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Emergency Medical Expenses`)}}</td>
                                                                            <td>{{$t(`Up to $200,000`)}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <div class="discovermore-btn discover-margin"><a
                                                                       href="#"
                                                                       id="discovermore-link">{{$t(`Discover More`)}}</a></div>
                                                                <table class="dismoretable"
                                                                       style="display: none;">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>{{$t(`Emergency Dental Treatment`)}}</td>
                                                                            <td>{{$t(`Up to $1,000`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Repatriation of Remains`)}}</td>
                                                                            <td>{{$t(`Up to $5,000`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Additional Accommodation Costs`)}}</td>
                                                                            <td>{{$t(`$250 per day (up to 5 days)`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <p>{{$t(`Flight Delay for delays exceeding 6
                                                                                    hours.`)}}</p>

                                                                                <p>{{$t(`Denied Boarding caused by overbooking
                                                                                    by the Common Carrier and not
                                                                                    voluntarily accepted.`)}}</p>

                                                                                <p>{{$t(`Flight Cancellation caused due to bad
                                                                                    weather.`)}}</p>
                                                                            </td>
                                                                            <td>{{$t(`Up to $1,200`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Missed Connection/Missed Departure`)}}</td>
                                                                            <td>{{$t(`Up to $1,000`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Luggage Delay for delays exceeding 6
                                                                                hours`)}}</td>
                                                                            <td>{{$t(`Up to $1,200`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Luggage Loss`)}}</td>
                                                                            <td>{{$t(`Up to $2,200`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Valuables Limit`)}}</td>
                                                                            <td>{{$t(`Up to $300`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Trip Cancellation or Curtailment`)}}</td>
                                                                            <td>{{$t(`Up to $1,000 (excess: $50)`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Loss of Travel Documents or Money`)}}</td>
                                                                            <td>{{$t(`Up to $3,500`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Death (Public Conveyance Only)`)}}</td>
                                                                            <td>{{$t(`Up to $600,000`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Dismemberment (Public Conveyance Only)`)}}
                                                                            </td>
                                                                            <td>{{$t(`Up to $600,000`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Legal Expenses`)}}</td>
                                                                            <td>{{$t(`Up to $1,000,000`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Advance Payment of Bail Bond`)}}</td>
                                                                            <td>{{$t(`Up to $50,000`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Personal Liability Per Incident`)}}</td>
                                                                            <td>{{$t(`Up to $500,000`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Retail Protection (Per Incident)`)}}</td>
                                                                            <td>{{$t(`Up to $3,500`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Retail Protection (Per Year)`)}}</td>
                                                                            <td>{{$t(`Up to $25,000`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Extended Warranty (Per Incident)`)}}</td>
                                                                            <td>{{$t(`Up to $1,000`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Extended Warranty (Per Year)`)}}</td>
                                                                            <td>{{$t(`Up to $2,000`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Stolen or Damaged Vehicle Coverage Up to
                                                                                30 days of car rental**`)}}</td>
                                                                            <td>{{$t(`Up to $50,000`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Medical Emergency Assistance`)}}</td>
                                                                            <td>{{$t(`Covered`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Referrals to Medical Services`)}}</td>
                                                                            <td>{{$t(`Covered`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Hospital Admission Assistance`)}}</td>
                                                                            <td>{{$t(`Covered`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Lost Luggage Assistance`)}}</td>
                                                                            <td>{{$t(`Covered`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Cash Advances`)}}</td>
                                                                            <td>{{$t(`Up to $5,000`)}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{$t(`Online Fraud Guarantee`)}}</td>
                                                                            <td>{{$t(`100%`)}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <p><strong>{{$t(`* Included Activities:`)}}</strong> {{$t(`On-piste
                                                                    skiing or snowboarding, off-piste
                                                                    skiing or snowboarding with a qualified guide,
                                                                    cross-country skiing on recognized
                                                                    routes with a qualified guide, sledging, and outdoor
                                                                    ice-skating on public rinks.`)}}</p>

                                                                <p><strong>{{$t(`Excluded Activities:`)}}</strong> {{$t(`Bobsledding,
                                                                    heli-skiing, para skiing, ice
                                                                    hockey, lugging, skeleton use, ski acrobatics, ski
                                                                    racing, and ski jumping`)}}</p>

                                                                <p>{{$t(`** To be eligible this coverage, the basic insurance
                                                                    must be purchased at the time of
                                                                    renting the car, and the Centurion Card must be used
                                                                    to pay for the entire car rental
                                                                    period, including the basic insurance cost.`)}}</p>

                                                                <p></p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-image-with-text-icons paragraph--view-mode--default cent-bg-">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div
                                                     class="col-12 col-md-6 mt-3 mt-md-0 p-md-0 d-flex align-items-md-center flex-column position-relative">
                                                    <div class="cent-imgwithtxt-icons-image">

                                                        <div
                                                             class="field field--name-field-image-with-text-icons-img field--type-image field--label-hidden field__item">
                                                            <img :src="require(`@/assets/images/centurion/special-sports.jpg`)"
                                                                 width="482"
                                                                 height="402"
                                                                 alt=""
                                                                 loading="lazy"
                                                                 typeof="foaf:Image"
                                                                 class="img-fluid" />

                                                        </div>

                                                    </div>
                                                </div>
                                                <div
                                                     class="col-12 col-md-6 mt-3 mt-md-0 p-md-0 d-flex align-self-md-center flex-column">
                                                    <div class="cent-imgwithtxt-icons-text">

                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-icon-title field--type-text-long field--label-hidden field__item">
                                                            <p>{{$t(`Special Sports`)}}</p>
                                                        </div>


                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-icons-text field--type-text-long field--label-hidden field__items">
                                                            <div class="field__item">
                                                                <p>{{$t(`You and your family are also covered for medical
                                                                    expenses and personal accidents when
                                                                    scuba diving (above 30m), horse riding and
                                                                    windsurfing. However, some extreme sports
                                                                    are not covered.`)}}</p>

                                                                <p>{{$t(`You can find the full Terms and Conditions of this
                                                                    policy, along with the complete
                                                                    Insurance Benefits and Emergency Assistance Coverage
                                                                    by clicking`)}}<a href="#">{{$t(`here`)}}</a>.
                                                                </p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-free-design paragraph--view-mode--default">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                    <div class="cent-freedesign">
                                                        <div class="row">

                                                            <div
                                                                 class="clearfix text-formatted field field--name-field-free-design-text field--type-text-long field--label-hidden field__item">
                                                                <div class="travelinsurance-footer-cont">
                                                                    <p class="travelinsurance-footer">{{$t(`For more
                                                                        information and to file a claim, please
                                                                        contact your Centurion Relationship Manager,
                                                                        or:`)}}<br />
                                                                        {{$t(`From inside Saudi Arabia: `)}}<a
                                                                           href="tel:800 122 5225">800 122
                                                                            5225</a><br />
                                                                            {{$t(`From outside Saudi Arabia: `)}}<a
                                                                           href="tel:(+966) 11 407 1999">(+966) 11 407
                                                                            1999</a><br />
                                                                            {{$t(`Email:`)}}<a
                                                                           href="mailto:centurion@americanexpress.com.sa">{{$t(`centurion@americanexpress.com.sa`)}}</a>
                                                                    </p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </article>

                </div>

            </div>


        </main>
        <CenturionFooter></CenturionFooter>
    </div>
</template>

<script>
import store from '@/store';

import CenturionHeader from './centurion-header.vue';
import CenturionFooter from './centurion-footer.vue';

export default {
    components: {
        CenturionHeader,
        CenturionFooter
    },
    data: () => ({
        offers: [],
        loaded: true,
        selectedLanguage: 'en',
        heading: 'Available Offers',
        loadedCount: 0,
        unsubscribe: null,
    }),
    created()
    {
        this.unsubscribe = store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
                if (store.get('requestKeys/accessToken') && this.loadedCount == 0)
                {
                    this.loadedCount++;
                }

            }
        })

    },

    destroyed()
    {
        this.unsubscribe();
    },
    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang)
        {
            this.selectedLanguage = localStorage.getItem('language');
        }
        // this.loggedOffers();
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
@import './centurion.scss';
@import './bootstrap.css';

.travelinsurance-title,
.travelinsurance-text {
    max-width: 75%;
    margin: 0 auto;
    text-align: center;
}

.travelinsurance-title {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 1rem;
}

.travelinsurance-text {
    margin-bottom: 1rem;
}

@media only screen and (max-width: 576px) {

    .travelinsurance-title,
    .travelinsurance-text {
        max-width: 100%;
        margin: 0 auto;
    }
}

table {
    width: 100%;
    border: solid 1px;
    margin: 3em 0;
}

th {
    font-size: 1.5em;
    font-weight: bold;
    background-color: #000000;
    color: #ffffff;
    text-align: center;
    padding: 1em;
}

td {
    border: solid 1px;
    padding: 1em;
    width: 50%;
}

.discovermore-btn {
    margin: 1em 0;
}

.discovermore-btn a {
    background-color: #000000;
    color: #ffffff;
    text-decoration: none;
    display: block;
    width: fit-content;
    padding: 1em 2em;
    margin: 0 auto;
    border-radius: 10px;
}

.discover-margin {
    margin-bottom: 2em;
}

.travelinsurance-footer-cont {
    text-align: center;
    margin-bottom: 3em;
}

.travelinsurance-footer {
    width: 50%;
    margin: 0 auto;
    font-size: 1.2em;
    font-weight: bold;
    background-color: #000;
    color: #fff;
    padding: 1em 2em;
    border-radius: 10px;
    line-height: 1.5em;
}

/* Mobile View */

@media only screen and (max-width: 576px) {
    .travelinsurance-footer-cont {
        margin-bottom: 1em;
    }

    .travelinsurance-footer {
        width: 100%;
        padding: 2em;
        font-size: 1.1em;
    }
}
</style>

<!-- <script>
                                      document.addEventListener("DOMContentLoaded", function (event) {
                                        (function ($) {
  
                                          $("#discovermore-link").click(function (e) {
                                            e.preventDefault();
                                            $(".dismoretable").toggle();
                                            $(".discovermore-btn").toggleClass("discover-margin");
                                          });
  
                                        })(jQuery);
                                      });
                                  </script> -->