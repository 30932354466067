<template>
    <div class="dialog-off-canvas-main-canvas d-flex flex-column h-100"
         style="background: white;"
         data-off-canvas-main-canvas>
        <CenturionHeader></CenturionHeader>
        <main role="main"><a id="main-content"
               tabindex="-1"></a>
            <div class="region region-content">
                <div data-drupal-messages-fallback
                     class="hidden"></div>
                <div id="block-amexsubtheme-content"
                     class="block block-system block-system-main-block">
                    <article data-history-node-id="1083"
                             role="article"
                             about="/content/membership-rewards"
                             class="node node--type-centurion-page node--view-mode-full">
                        <div class="node__content">
                            <div
                                 class="field field--name-field-centurion-page-sections field--type-entity-reference-revisions field--label-hidden field__items">
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-sub-page-banner paragraph--view-mode--default">
                                        <div class="container">
                                            <div class="row g-md-0 black-bg">
                                                <div class="col-md-4">
                                                    <div class="sub-page-banner-title">
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-sub-banner-title field--type-text field--label-hidden field__item">
                                                            {{ $t('Discover,') }} <br />{{ $t(`Earn, Redeem`) }}</div>
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-sub-banner-text field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t(`and Enjoy a New Era of Membership Rewards!`) }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-8">
                                                    <div class="sub-page-banner-image">
                                                        <div
                                                             class="field field--name-field-sub-banner-image field--type-image field--label-hidden field__item">
                                                            <img :src="require(`@/assets/images/centurion/membership-banner.jpg`)"
                                                                 width="643"
                                                                 height="455"
                                                                 alt=""
                                                                 loading="lazy"
                                                                 typeof="foaf:Image"
                                                                 class="img-fluid" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-image-with-text-icons paragraph--view-mode--default cent-bg-">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div
                                                     class="col-12 col-md-6 mt-3 mt-md-0 p-md-0 d-flex align-items-md-center flex-column position-relative">
                                                    <div class="cent-imgwithtxt-icons-image">
                                                        <div
                                                             class="field field--name-field-image-with-text-icons-img field--type-image field--label-hidden field__item">
                                                            <img :src="require(`@/assets/images/centurion/membership-rewards-points.jpg`)"
                                                                 width="492"
                                                                 height="481"
                                                                 alt=""
                                                                 loading="lazy"
                                                                 typeof="foaf:Image"
                                                                 class="img-fluid" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                     class="col-12 col-md-6 mt-3 mt-md-0 p-md-0 d-flex align-self-md-center flex-column">
                                                    <div class="cent-imgwithtxt-icons-text">
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-icon-title field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t(`Membership Rewards`) }}</p>
                                                        </div>
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-icons-text field--type-text-long field--label-hidden field__items">
                                                            <div class="field__item">
                                                                <p>{{ $t(`Membership Rewards® are our way of thanking
                                                                    you
                                                                    forchoosing and using your centurionCard. With this
                                                                    program, you will receive one MembershipRewards®
                                                                    point
                                                                    for everydollar you charge virtually to an enrolled
                                                                    Card,excluding cash withdrawal, Annualfees, Credit
                                                                    Refund. There are no limits to how manypoints you
                                                                    can
                                                                    earn and the bestpart is, your points never expire!
                                                                    Terms and conditionsapply.`) }}</p>
                                                            </div>
                                                            <div class="field__item">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="title"><strong>{{ $t(`Use Membership
                                                                                RewardsPoints`) }}</strong></div>
                                                                    </div>
                                                                </div>
                                                                <div class="row image-txticons-margin">
                                                                    <div class="col-2">
                                                                        <div class="image-txticons-icons"><img alt=""
                                                                                 class="img-fluid"
                                                                                 :src="require(`@/assets/images/centurion/annual-fees.png`)" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-10">
                                                                        <div class="image-txticons-txt">
                                                                            <span><strong>{{ $t(`Easilycover the annual
                                                                                    fees`) }}
                                                                                </strong>{{ $t(`for both basic
                                                                                and
                                                                                supplementaryCards.`) }}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row image-txticons-margin">
                                                                    <div class="col-2">
                                                                        <div class="image-txticons-icons"><img alt=""
                                                                                 class="img-fluid"
                                                                                 :src="require(`@/assets/images/centurion/effortlessly.png`)" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-10">
                                                                        <div class="image-txticons-txt">
                                                                            <span><strong>{{ $t(`Effortlessly transfer
                                                                                    yourpoints`) }}</strong>
                                                                                {{ $t(`to top airline and hotelloyalty
                                                                                programs.`) }}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row image-txticons-margin">
                                                                    <div class="col-2">
                                                                        <div class="image-txticons-icons"><img alt=""
                                                                                 class="img-fluid"
                                                                                 :src="require(`@/assets/images/centurion/redeem-pts.png`)" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-10">
                                                                        <div class="image-txticons-txt">
                                                                            <span><strong>{{ $t(`Redeempoints`)
                                                                                    }}</strong>
                                                                                {{ $t(`for
                                                                                exclusivevouchers at luxury retailers
                                                                                like
                                                                                Harrods,Amazon and more.`) }}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row image-txticons-margin">
                                                                    <div class="col-2">
                                                                        <div class="image-txticons-icons"><img alt=""
                                                                                 class="img-fluid"
                                                                                 :src="require(`@/assets/images/centurion/donate.png`)" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-10">
                                                                        <div class="image-txticons-txt"><span><strong>{{
                                                                            $t(`Make
                                                                                    adifference by
                                                                                    donating`)}}</strong>{{
                                                                                        $t(`to
                                                                                charities such as DSCA.`) }}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row image-txticons-margin">
                                                                    <div class="col-2">
                                                                        <div class="image-txticons-icons"><img alt=""
                                                                                 class="img-fluid"
                                                                                 :src="require(`@/assets/images/centurion/pointstopay.png`)" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-10">
                                                                        <div class="image-txticons-txt">
                                                                            <span><strong>{{ $t(`Useyour points to
                                                                                    pay`) }}</strong>
                                                                                {{ $t(`forair tickets and travel in
                                                                                style!
                                                                                (1 USD
                                                                                =200 American Express Points)`)
                                                                                }}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="exp"><strong>
                                                                                {{ $t(`Unlock a World
                                                                                ofExtraordinary Rewards`) }} <a
                                                                                   href="https://online.americanexpress.com.sa/rewards/index.aspx"
                                                                                   target="_blank"
                                                                                   style="color: #000000">{{
                                                                                    $t(`Here`) }}</a></strong>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </main>
        <CenturionFooter></CenturionFooter>
    </div>
</template>

<script>
import store from '@/store';

import CenturionHeader from './centurion-header.vue';
import CenturionFooter from './centurion-footer.vue';

export default {
    components: {
        CenturionHeader,
        CenturionFooter
    },
    data: () => ({
        offers: [],
        loaded: true,
        selectedLanguage: 'en',
        heading: 'Available Offers',
        loadedCount: 0,
        unsubscribe: null,
    }),
    created()
    {
        this.unsubscribe = store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
                if (store.get('requestKeys/accessToken') && this.loadedCount == 0)
                {
                    this.loadedCount++;
                }

            }
        })

    },

    destroyed()
    {
        this.unsubscribe();
    },
    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang)
        {
            this.selectedLanguage = localStorage.getItem('language');
        }
        // this.loggedOffers();
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
@import './centurion.scss';
@import './bootstrap.css';

.title {
    font-size: 1.2em;
    margin-bottom: 1em;
}

.exp {
    font-size: 1.2em;
    border-top: solid 1px #B4B4B4;
    border-bottom: solid 1px #B4B4B4;
    padding: 1em 0;
    margin-top: 1em;
    margin-bottom: 2em;
}
</style>