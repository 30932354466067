import axios from './axiosInit'

import sharedServices from './shared-services';
import store from '@/store';
import moment from 'moment';

const buildPendingTransactionRequestModel = async () =>
{
    return sharedServices.getToken('entity', store.get('requestKeys/userName')).then(tokenRes =>
    {
        store.set('requestKeys/channelKey', tokenRes.data.results.channelKey);
        store.set('requestKeys/identityKey', tokenRes.data.results.identityKey);
        store.set('requestKeys/requestDate', tokenRes.data.results.requestDate);

        let requestInfo = {
            requestUID: (Math.floor(1000 + Math.random() * 9000)).toString(),
            channelKey: tokenRes.data.results.channelKey,
            identityKey: tokenRes.data.results.identityKey,
            requestDate: tokenRes.data.results.requestDate,
            channelId: store.get('requestKeys/channelId'),
            identitySeed: store.get('requestKeys/identitySeed'),
            userName: store.get('requestKeys/userName'),
        }

        let date = moment().utc();
        var endDt = moment(date).format('yyyy-MM-DD hh:mm:ss');
        var before20days = date.subtract(20, "days");
        var startDt = moment(before20days).format('yyyy-MM-DD hh:mm:ss');

        let card = store.get('card/details');

        // console.log(card);

        var requestModel = {
            cardNumber: card.primeCardSerno ?? '',
            rangeDate: {
                startDt: startDt,
                endDt: endDt,
            },
            requestInfo: requestInfo,
        };

        return requestModel;
    });
}

const getPendingTransactions = async () =>
{
    try
    {
        // store.dispatch('loader/show');
        let requestModel = await buildPendingTransactionRequestModel();

        const auth = {
            headers: {
                Authorization: 'Bearer ' + store.get('requestKeys/accessToken')
            }
        }
        const result = await axios.post('hpsbridge/hpsbridge_staging/se/transaction/searchAuthorization', requestModel, auth)
        // store.dispatch('loader/hide');
        return result
    }
    catch (e)
    {
        // console.log(e)
        store.dispatch('loader/hide');
    }
}

const getStatementHistory = async (statementHistoryRequestModel) =>
{
    try
    {
        // store.dispatch('loader/show');
        const auth = {
            headers: {
                Authorization: 'Bearer ' + store.get('requestKeys/accessToken')
            }
        }

        const result = await axios.post('hpsbridge/hpsbridge_staging/se/Statement/GetHistory', statementHistoryRequestModel, auth)
        // store.dispatch('loader/hide');
        console.log(result);
        return result
    }
    catch (e)
    {
        // console.log(e)
    }

    // var errorCode = response.body.responseInfo.errorCode
    // var errorDescription = response.body.responseInfo.errorDescription

    // if (response.statusCode == 200 && errorCode == RestErrors.SUCCESS)
    // {
    //     return response;
    // }
    // else if (response.statusCode == 403 ||
    //     response.statusCode == 401 ||
    //     (errorCode == '81046' &&
    //         errorDescription?.toString() == 'Entity validation error') ||
    //     errorCode == '403' ||
    //     errorCode == '401')
    // {
    //     // throw LogoutException('systemTimeout'.tr);
    // } else
    // {
    //     throw RestApiException(
    //         RestErrors.getErrorMessageFromErrorCode({
    //             errorCode: errorCode,
    //             errorDescription: errorDescription,
    //         }
    //         ),
    //     );
    // }
}

const getRecentActivities = async (recentActivityRequestModel, hide) =>
{
    try
    {
        if(!hide)
        {
            store.dispatch('loader/show');
        }
        let invalidOnce = false;

        const auth = {
            headers: {
                Authorization: 'Bearer ' + store.get('requestKeys/accessToken')
            }
        }
        const result = await axios.post('hpsbridge/hpsbridge_staging/se/transaction/search', recentActivityRequestModel, auth)
        if(!hide)
        {
            store.dispatch('loader/hide');
        }
        return result
    }
    catch (e)
    {
        // console.log(e)
    }
    // var response = apiClient.postData(
    //     '${Constants.basehpsUrl}${Constants.recentActivity}',
    //     recentActivityRequestModel.toJson(),
    // );

    // var errorCode = response.body.responseInfo.errorCode
    // var errorDescription = response.body.responseInfo.errorDescription
    // if (response.statusCode == 200 && errorCode == RestErrors.SUCCESS)
    // {
    //     return response;
    // }
    // else if (response.statusCode == 403 || response.statusCode == 401 || errorCode == '403' || errorCode == '401')
    // {
    //     if (invalidOnce)
    //     {
    //         throw LogoutException('systemTimeout'.tr);
    //     } else
    //     {
    //         Constants.getEncryptedChannelIdentityKeyForEnrollment();
    //         var token = Get.find().buildTokenModel();
    //         recentActivityRequestModel.requestInfo =
    //             recent_activity_request_model.RequestInfo.fromJson(
    //                 token.toJson(),
    //             );
    //         return getRecentActivities(
    //             recentActivityRequestModel,
    //             invalidOnce
    //         );
    //     }
    // } else
    // {
    //     if (invalidOnce)
    //     {
    //         throw RestApiException(
    //             RestErrors.getErrorMessageFromErrorCode(
    //                 errorCode,
    //                 errorDescription,
    //             ),
    //         );
    //     } else if (errorCode == '80030' || errorCode == '81046')
    //     {
    //         Constants.getEncryptedChannelIdentityKeyForEnrollment();
    //         var token = Get.find().buildTokenModel();
    //         recentActivityRequestModel.requestInfo =
    //             recent_activity_request_model.RequestInfo.fromJson(
    //                 token.toJson(),
    //             );
    //         return getRecentActivities(
    //             recentActivityRequestModel,
    //             invalidOnce,
    //         );
    //     } else
    //     {
    //         throw RestApiException(
    //             RestErrors.getErrorMessageFromErrorCode(
    //                 errorCode,
    //                 errorDescription
    //             ),
    //         );
    //     }
    // }
}

export default {
    // getEntities,
    getStatementHistory,
    getRecentActivities,
    getPendingTransactions
}
