<template>
    <div class="dialog-off-canvas-main-canvas d-flex flex-column h-100"
         style="background: white;"
         data-off-canvas-main-canvas>
        <CenturionHeader></CenturionHeader>
        <main role="main">
            <a id="main-content"
               tabindex="-1"></a>


            <div class="region region-content">
                <div data-drupal-messages-fallback
                     class="hidden"></div>
                <div id="block-amexsubtheme-content"
                     class="block block-system block-system-main-block">



                    <article data-history-node-id="1082"
                             role="article"
                             about="/content/lifestyle"
                             class="node node--type-centurion-page node--view-mode-full">





                        <div class="node__content">

                            <div
                                 class="field field--name-field-centurion-page-sections field--type-entity-reference-revisions field--label-hidden field__items">
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-sub-page-banner paragraph--view-mode--default">
                                        <div class="container">
                                            <div class="row g-md-0 black-bg">
                                                <div class="col-md-4">
                                                    <div class="sub-page-banner-title">

                                                        <div
                                                             class="clearfix text-formatted field field--name-field-sub-banner-title field--type-text field--label-hidden field__item">
                                                            {{ $t(`Lifestyle`) }}</div>


                                                    </div>
                                                </div>
                                                <div class="col-md-8">
                                                    <div class="sub-page-banner-image">

                                                        <div
                                                             class="field field--name-field-sub-banner-image field--type-image field--label-hidden field__item">
                                                            <img :src="require(`@/assets/images/centurion/lifestyle-banner.jpg`)"
                                                                 width="644"
                                                                 height="455"
                                                                 alt=""
                                                                 loading="lazy"
                                                                 typeof="foaf:Image"
                                                                 class="img-fluid" />

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--cent-z-shape-image-with-text paragraph--view-mode--default cent-bg-">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div
                                                     class="col-12 col-md-7 mt-3 mt-md-0 p-md-0 d-flex align-items-md-end flex-column position-relative cent-imgwithtxt-zshape-image-cont">
                                                    <div class="cent-imgwithtxt-zshape-image">

                                                        <div
                                                             class="field field--name-field-image-with-text-icons-img field--type-image field--label-hidden field__item">
                                                            <img :src="require(`@/assets/images/centurion/fashion-spec_0.jpg`)"
                                                                 width="499"
                                                                 height="500"
                                                                 alt=""
                                                                 loading="lazy"
                                                                 typeof="foaf:Image"
                                                                 class="img-fluid" />

                                                        </div>

                                                    </div>
                                                </div>
                                                <div
                                                     class="col-12 col-md-5 mt-3 mt-md-0 p-md-0 d-flex align-self-md-center flex-column cent-imgwithtxt-zshape-text-cont">
                                                    <div class="cent-imgwithtxt-zshape-text">

                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-icon-title field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t(`FASHION SPECTACULAR`) }}</p>
                                                        </div>


                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-icons-text field--type-text-long field--label-hidden field__items">
                                                            <div class="field__item">
                                                                <p>{{ $t(`Stay on top of fashion and luxury with
                                                                    complimentary access to FARFETCH's elite
                                                                    Private Client tier, the highest level of their
                                                                    loyalty program. Experience
                                                                    unparalleled privilege and personalized services for
                                                                    Centurion Cardmembers!`)}}<br />
                                                                    {{ $t(`Contact your Centurion Relationship Manager
                                                                    to receive your unique link and access
                                                                    code.`) }}
                                                                </p>

                                                                <p class="inclined"><i>{{ $t(`he link is accessible via
                                                                        the desktop version and not through
                                                                        the FARFETCH APP.`)}}T</i></p>

                                                            </div>
                                                            <div class="field__item">
                                                                <div class="row image-txticons-margin">
                                                                    <div class="col-2">
                                                                        <div class="image-txticons-icons"><img alt=""
                                                                                 class="img-fluid"
                                                                                 :src="require(`@/assets/images/centurion/bonus.jpg`)" />
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-10">
                                                                        <div class="image-txticons-txt">{{ $t(`US$ 400
                                                                            bonus when you shop for US$ 2,000 or
                                                                            more`)}}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="row image-txticons-margin">
                                                                    <div class="col-2">
                                                                        <div class="image-txticons-icons"><img alt=""
                                                                                 class="img-fluid"
                                                                                 :src="require(`@/assets/images/centurion/gift.jpg`)" />
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-10">
                                                                        <div class="image-txticons-txt">{{ $t(`A 10%
                                                                            promo code to celebrate your anniversary
                                                                            with
                                                                            FARFETCH`)}}</div>
                                                                    </div>
                                                                </div>

                                                                <div class="row image-txticons-margin">
                                                                    <div class="col-2">
                                                                        <div class="image-txticons-icons"><img alt=""
                                                                                 class="img-fluid"
                                                                                 :src="require(`@/assets/images/centurion/notification.jpg`)" />
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-10">
                                                                        <div class="image-txticons-txt">{{ $t(`Early
                                                                            notifications of discounts via email`)}}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="row image-txticons-margin">
                                                                    <div class="col-2">
                                                                        <div class="image-txticons-icons"><img alt=""
                                                                                 class="img-fluid"
                                                                                 :src="require(`@/assets/images/centurion/exclusive.jpg`)" />
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-10">
                                                                        <div class="image-txticons-txt">{{ $t(`Exclusive
                                                                            offers`)}}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--cent-z-shape-image-with-text paragraph--view-mode--default cent-bg-">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div
                                                     class="col-12 col-md-7 mt-3 mt-md-0 p-md-0 d-flex align-items-md-end flex-column position-relative cent-imgwithtxt-zshape-image-cont">
                                                    <div class="cent-imgwithtxt-zshape-image">

                                                        <div
                                                             class="field field--name-field-image-with-text-icons-img field--type-image field--label-hidden field__item">
                                                            <img :src="require(`@/assets/images/centurion/net-a-porter_0.jpg`)"
                                                                 width="499"
                                                                 height="500"
                                                                 alt=""
                                                                 loading="lazy"
                                                                 typeof="foaf:Image"
                                                                 class="img-fluid" />

                                                        </div>

                                                    </div>
                                                </div>
                                                <div
                                                     class="col-12 col-md-5 mt-3 mt-md-0 p-md-0 d-flex align-self-md-center flex-column cent-imgwithtxt-zshape-text-cont">
                                                    <div class="cent-imgwithtxt-zshape-text">

                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-icon-title field--type-text-long field--label-hidden field__item">
                                                            <p dir="">{{ $t(`MAKE THE MOST OF YOUR MEMBERSHIP WITH
                                                                NET-A-PORTER AND MR PORTER`)}}</p>
                                                        </div>


                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-icons-text field--type-text-long field--label-hidden field__items">
                                                            <div class="field__item">
                                                                <p dir="">{{ $t(`As a valued Centurion Cardmember,
                                                                    indulge in exclusive privileges crafted
                                                                    uniquely for you.`)}}</p>
                                                            </div>
                                                            <div class="field__item">
                                                                <div dir="">
                                                                    <div class="row image-txticons-margin">
                                                                        <div class="col-2">
                                                                            <div class="image-txticons-icons"><img
                                                                                     alt=""
                                                                                     class="img-fluid"
                                                                                     :src="require(`@/assets/images/centurion/bonus.jpg`)" />
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-10">
                                                                            <div class="image-txticons-txt">{{ $t(`Up to
                                                                                $500 in statement credits each calendar
                                                                                year
                                                                                when you shop with your Centurion
                                                                                Card`)}}</div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="row image-txticons-margin">
                                                                        <div class="col-2">
                                                                            <div class="image-txticons-icons"><img
                                                                                     alt=""
                                                                                     class="img-fluid"
                                                                                     :src="require(`@/assets/images/centurion/customer-care.jpg`)" />
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-10">
                                                                            <div class="image-txticons-txt">
                                                                                {{ $t(`Priority customer care and
                                                                                one-on-one virtual
                                                                                consultations`)}}</div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="row image-txticons-margin">
                                                                        <div class="col-2">
                                                                            <div class="image-txticons-icons"><img
                                                                                     alt=""
                                                                                     class="img-fluid"
                                                                                     :src="require(`@/assets/images/centurion/exclusive.jpg`)" />
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-10">
                                                                            <div class="image-txticons-txt">
                                                                                {{ $t(`Exclusive access to private sales
                                                                                and
                                                                                pre-reservation services`)}}</div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="row image-txticons-margin">
                                                                        <div class="col-2">
                                                                            <div class="image-txticons-icons"><img
                                                                                     alt=""
                                                                                     class="img-fluid"
                                                                                     :src="require(`@/assets/images/centurion/gift.jpg`)" />
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-10">
                                                                            <div class="image-txticons-txt">
                                                                                {{ $t(`!Special birthday discounts and
                                                                                more`)}}</div>
                                                                        </div>
                                                                    </div>

                                                                    <p> </p>

                                                                    <p>{{ $t(`Click`) }} <a
                                                                           href="https://www.net-a-porter.com/en-gb/account/centurion"  target="_blank">{{ $t(`here`) }}</a>
                                                                        {{ $t(`to
                                                                        register with Net-A-Porter`)}}</p>

                                                                    <p>{{ $t(`Click`) }} <a
                                                                           href="https://www.mrporter.com/en-gb/account/centurion"  target="_blank">{{ $t(`here`) }}</a>
                                                                        {{ $t(`to
                                                                        register with MR PORTER`)}}</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-page-title paragraph--view-mode--default cent-bg-1">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div
                                                     class="col-12 mt-3 mt-md-0 p-md-0 d-flex align-items-md-center flex-column justify-content-md-center position-relative">
                                                    <div class="cent-homepage-title">

                                                        <div
                                                             class="clearfix text-formatted field field--name-field-centurion-page-title-text field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t(`ENJOY PREMIUM BENEFITS`) }}</p>

                                                        </div>


                                                        <div
                                                             class="clearfix text-formatted field field--name-field-centurion-title-sub-text field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t(`AT SOME OF THE WORLD'S MOST PRESTIGIOUS SHOPPING
                                                                DESTINATIONS!`)}}</p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">


                                    <div
                                         class="paragraph paragraph--type--block-reference paragraph--view-mode--default">




                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--cent-z-shape-image-with-text paragraph--view-mode--default cent-bg-1">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div
                                                     class="col-12 col-md-7 mt-3 mt-md-0 p-md-0 d-flex align-items-md-end flex-column position-relative cent-imgwithtxt-zshape-image-cont">
                                                    <div class="cent-imgwithtxt-zshape-image">

                                                        <div
                                                             class="field field--name-field-image-with-text-icons-img field--type-image field--label-hidden field__item">
                                                            <img :src="require(`@/assets/images/centurion/bicester-collection_0.jpg`)"
                                                                 width="500"
                                                                 height="501"
                                                                 alt=""
                                                                 loading="lazy"
                                                                 typeof="foaf:Image"
                                                                 class="img-fluid" />

                                                        </div>

                                                    </div>
                                                </div>
                                                <div
                                                     class="col-12 col-md-5 mt-3 mt-md-0 p-md-0 d-flex align-self-md-center flex-column cent-imgwithtxt-zshape-text-cont">
                                                    <div class="cent-imgwithtxt-zshape-text">

                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-icon-title field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t(`THE BICESTER COLLECTION VILLAGES!`) }}</p>
                                                        </div>


                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-icons-text field--type-text-long field--label-hidden field__items">
                                                            <div class="field__item">
                                                                <p>{{ $t(`An elite shopping experience at The Bicester
                                                                    Collection Villages is made just for you
                                                                    with exclusive Benefits from American Express Saudi
                                                                    Arabia.`)}}</p>
                                                            </div>
                                                            <div class="field__item">
                                                                <div class="row image-txticons-margin">
                                                                    <div class="col-2">
                                                                        <div class="image-txticons-icons"><img alt=""
                                                                                 class="img-fluid"
                                                                                 :src="require(`@/assets/images/centurion/cashback.jpg`)" />
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-10">
                                                                        <div class="image-txticons-txt">{{ $t(`Receive
                                                                            10% cashback up to USD 250*`)}}</div>
                                                                    </div>
                                                                </div>

                                                                <div class="row image-txticons-margin">
                                                                    <div class="col-2">
                                                                        <div class="image-txticons-icons"><img alt=""
                                                                                 class="img-fluid"
                                                                                 :src="require(`@/assets/images/centurion/car.jpg`)" />
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-10">
                                                                        <div class="image-txticons-txt">{{ $t(`Advanced
                                                                            reservations for private parking`)}}</div>
                                                                    </div>
                                                                </div>

                                                                <div class="row image-txticons-margin">
                                                                    <div class="col-2">
                                                                        <div class="image-txticons-icons"><img alt=""
                                                                                 class="img-fluid"
                                                                                 :src="require(`@/assets/images/centurion/customer-care.jpg`)" />
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-10">
                                                                        <div class="image-txticons-txt">{{ $t(`Priority
                                                                            bookings for queuing systems (where
                                                                            applicable)`)}}</div>
                                                                    </div>
                                                                </div>

                                                                <div class="row image-txticons-margin">
                                                                    <div class="col-2">
                                                                        <div class="image-txticons-icons"><img alt=""
                                                                                 class="img-fluid"
                                                                                 :src="require(`@/assets/images/centurion/private-access.jpg`)" />
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-10">
                                                                        <div class="image-txticons-txt">
                                                                            {{ $t(`Private access to The Apartment, The
                                                                            Bicester
                                                                            Collection's Villages VIP suite with
                                                                            complimentary refreshments`)}}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="row image-txticons-margin">
                                                                    <div class="col-2">
                                                                        <div class="image-txticons-icons"><img alt=""
                                                                                 class="img-fluid"
                                                                                 :src="require(`@/assets/images/centurion/hands-free.jpg`)" />
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-10">
                                                                        <div class="image-txticons-txt">{{ $t(`Hands-free
                                                                            shopping Service`)}}</div>
                                                                    </div>
                                                                </div>

                                                                <div class="row image-txticons-margin">
                                                                    <div class="col-2">
                                                                        <div class="image-txticons-icons"><img alt=""
                                                                                 class="img-fluid"
                                                                                 :src="require(`@/assets/images/centurion/vip.jpg`)" />
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-10">
                                                                        <div class="image-txticons-txt">{{ $t(`A VIP Pass
                                                                            for an additional saving of up to 10%
                                                                            Priority`)}}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-page-title paragraph--view-mode--default cent-bg-1">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div
                                                     class="col-12 mt-3 mt-md-0 p-md-0 d-flex align-items-md-center flex-column justify-content-md-center position-relative">
                                                    <div class="cent-homepage-title">

                                                        <div
                                                             class="clearfix text-formatted field field--name-field-centurion-page-title-text field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t(`To OBTAIN YOUR VIP PASS,`) }}</p>
                                                        </div>


                                                        <div
                                                             class="clearfix text-formatted field field--name-field-centurion-title-sub-text field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t(`please click on the Village of your choice by
                                                                choosing Saudi Arabia as your country of
                                                                residence.`)}}</p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-villages paragraph--view-mode--default cent-bg-1">
                                        <div class="village-pad">
                                            <div class="container g-md-0">
                                                <div class="row g-md-0">
                                                    <div
                                                         class="col-12 mt-3 mt-md-0 p-md-0 d-flex align-items-md-center flex-column justify-content-md-center position-relative">
                                                        <div class="cent-village-container">
                                                            <div class="row justify-content-center">
                                                                <div class="col-4 col-md-auto vilmar">
                                                                    <div
                                                                         class="paragraph paragraph--type--centurion-village-items paragraph--view-mode--default">
                                                                        <div class="row g-md-0">
                                                                            <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                <div class="cent-village-items">
                                                                                    <a href="https://www.bicestervillage.com/eVIP-AmexCenturionGlobal"
                                                                                       target="_blank">
                                                                                        <div
                                                                                             class="field field--name-field-village-logo field--type-image field--label-hidden field__item">
                                                                                            <img :src="require(`@/assets/images/centurion/bicester-village.jpg`)"
                                                                                                 width="170"
                                                                                                 height="170"
                                                                                                 alt=""
                                                                                                 loading="lazy"
                                                                                                 typeof="foaf:Image"
                                                                                                 class="img-fluid" />

                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-4 col-md-auto vilmar">
                                                                    <div
                                                                         class="paragraph paragraph--type--centurion-village-items paragraph--view-mode--default">
                                                                        <div class="row g-md-0">
                                                                            <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                <div class="cent-village-items">
                                                                                    <a href="https://www.fidenzavillage.com/eVIP-AmexCenturionGlobal"
                                                                                       target="_blank">
                                                                                        <div
                                                                                             class="field field--name-field-village-logo field--type-image field--label-hidden field__item">
                                                                                            <img :src="require(`@/assets/images/centurion/fidenza.jpg`)"
                                                                                                 width="170"
                                                                                                 height="170"
                                                                                                 alt=""
                                                                                                 loading="lazy"
                                                                                                 typeof="foaf:Image"
                                                                                                 class="img-fluid" />

                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-4 col-md-auto vilmar">
                                                                    <div
                                                                         class="paragraph paragraph--type--centurion-village-items paragraph--view-mode--default">
                                                                        <div class="row g-md-0">
                                                                            <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                <div class="cent-village-items">
                                                                                    <a href="https://www.ingolstadtvillage.com/eVIP-AmexCenturionGlobal"
                                                                                       target="_blank">
                                                                                        <div
                                                                                             class="field field--name-field-village-logo field--type-image field--label-hidden field__item">
                                                                                            <img :src="require(`@/assets/images/centurion/ingolstadt.jpg`)"
                                                                                                 width="170"
                                                                                                 height="170"
                                                                                                 alt=""
                                                                                                 loading="lazy"
                                                                                                 typeof="foaf:Image"
                                                                                                 class="img-fluid" />

                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-4 col-md-auto vilmar">
                                                                    <div
                                                                         class="paragraph paragraph--type--centurion-village-items paragraph--view-mode--default">
                                                                        <div class="row g-md-0">
                                                                            <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                <div class="cent-village-items">
                                                                                    <a href="https://www.kildarevillage.com/eVIP-AmexCenturionGlobal"
                                                                                       target="_blank">
                                                                                        <div
                                                                                             class="field field--name-field-village-logo field--type-image field--label-hidden field__item">
                                                                                            <img :src="require(`@/assets/images/centurion/kildare.jpg`)"
                                                                                                 width="170"
                                                                                                 height="170"
                                                                                                 alt=""
                                                                                                 loading="lazy"
                                                                                                 typeof="foaf:Image"
                                                                                                 class="img-fluid" />

                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-4 col-md-auto vilmar">
                                                                    <div
                                                                         class="paragraph paragraph--type--centurion-village-items paragraph--view-mode--default">
                                                                        <div class="row g-md-0">
                                                                            <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                <div class="cent-village-items">
                                                                                    <a href="https://www.larocavillage.com/eVIP-AmexCenturionGlobal"
                                                                                       target="_blank">
                                                                                        <div
                                                                                             class="field field--name-field-village-logo field--type-image field--label-hidden field__item">
                                                                                            <img :src="require(`@/assets/images/centurion/laroca.jpg`)"
                                                                                                 width="170"
                                                                                                 height="170"
                                                                                                 alt=""
                                                                                                 loading="lazy"
                                                                                                 typeof="foaf:Image"
                                                                                                 class="img-fluid" />

                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-4 col-md-auto vilmar">
                                                                    <div
                                                                         class="paragraph paragraph--type--centurion-village-items paragraph--view-mode--default">
                                                                        <div class="row g-md-0">
                                                                            <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                <div class="cent-village-items">
                                                                                    <a href="https://www.lavalleevillage.com/eVIP-AmexCenturionGlobal"
                                                                                       target="_blank">
                                                                                        <div
                                                                                             class="field field--name-field-village-logo field--type-image field--label-hidden field__item">
                                                                                            <img :src="require(`@/assets/images/centurion/lavallee.jpg`)"
                                                                                                 width="170"
                                                                                                 height="170"
                                                                                                 alt=""
                                                                                                 loading="lazy"
                                                                                                 typeof="foaf:Image"
                                                                                                 class="img-fluid" />

                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-4 col-md-auto vilmar">
                                                                    <div
                                                                         class="paragraph paragraph--type--centurion-village-items paragraph--view-mode--default">
                                                                        <div class="row g-md-0">
                                                                            <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                <div class="cent-village-items">
                                                                                    <a href="https://www.lasrozasvillage.com/eVIP-AmexCenturionGlobal"
                                                                                       target="_blank">
                                                                                        <div
                                                                                             class="field field--name-field-village-logo field--type-image field--label-hidden field__item">
                                                                                            <img :src="require(`@/assets/images/centurion/lasrozas.jpg`)"
                                                                                                 width="170"
                                                                                                 height="170"
                                                                                                 alt=""
                                                                                                 loading="lazy"
                                                                                                 typeof="foaf:Image"
                                                                                                 class="img-fluid" />

                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-4 col-md-auto vilmar">
                                                                    <div
                                                                         class="paragraph paragraph--type--centurion-village-items paragraph--view-mode--default">
                                                                        <div class="row g-md-0">
                                                                            <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                <div class="cent-village-items">
                                                                                    <a href="https://www.maasmechelenvillage.com/eVIP-AmexCenturionGlobal"
                                                                                       target="_blank">
                                                                                        <div
                                                                                             class="field field--name-field-village-logo field--type-image field--label-hidden field__item">
                                                                                            <img :src="require(`@/assets/images/centurion/maasmechelen.jpg`)"
                                                                                                 width="170"
                                                                                                 height="170"
                                                                                                 alt=""
                                                                                                 loading="lazy"
                                                                                                 typeof="foaf:Image"
                                                                                                 class="img-fluid" />

                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-4 col-md-auto vilmar">
                                                                    <div
                                                                         class="paragraph paragraph--type--centurion-village-items paragraph--view-mode--default">
                                                                        <div class="row g-md-0">
                                                                            <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                <div class="cent-village-items">
                                                                                    <a href="https://www.wertheimvillage.com/eVIP-AmexCenturionGlobal"
                                                                                       target="_blank">
                                                                                        <div
                                                                                             class="field field--name-field-village-logo field--type-image field--label-hidden field__item">
                                                                                            <img :src="require(`@/assets/images/centurion/wertheim.jpg`)"
                                                                                                 width="170"
                                                                                                 height="170"
                                                                                                 alt=""
                                                                                                 loading="lazy"
                                                                                                 typeof="foaf:Image"
                                                                                                 class="img-fluid" />

                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-image-with-text-icons paragraph--view-mode--default cent-bg-1">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div
                                                     class="col-12 col-md-6 mt-3 mt-md-0 p-md-0 d-flex align-items-md-center flex-column position-relative">
                                                    <div class="cent-imgwithtxt-icons-image">

                                                        <div
                                                             class="field field--name-field-image-with-text-icons-img field--type-image field--label-hidden field__item">
                                                            <img :src="require(`@/assets/images/centurion/moreinfo-lifestyle_0_0.jpg`)"
                                                                 width="499"
                                                                 height="500"
                                                                 alt=""
                                                                 loading="lazy"
                                                                 typeof="foaf:Image"
                                                                 class="img-fluid" />

                                                        </div>

                                                    </div>
                                                </div>
                                                <div
                                                     class="col-12 col-md-6 mt-3 mt-md-0 p-md-0 d-flex align-self-md-center flex-column">
                                                    <div class="cent-imgwithtxt-icons-text">


                                                        <div
                                                             class="clearfix text-formatted field field--name-field-image-with-text-icons-text field--type-text-long field--label-hidden field__items">
                                                            <div class="field__item">
                                                                <ul class="small-font">
                                                                    <li class="mb-2">{{ $t(`This benefit is valid at all
                                                                        nine European Villages in The Bicester
                                                                        Collection Villages that display their logo on
                                                                        the website, at specific
                                                                        participating locations.`)}}</li>
                                                                </ul>
                                                                <ul class="small-font-star">
                                                                    <li class="mb-2">{{ $t(`Enjoy 10% cashback on
                                                                        purchases at all 9 participating European
                                                                        Villages in The Bicester Collection Villages,
                                                                        capped at USD 250. This offer is valid
                                                                        until the end of March 2025, per visit, per
                                                                        Village during the offer period. It is
                                                                        non-transferable and cannot be redeemed as
                                                                        cash.`)}}</li>
                                                                </ul>
                                                                <ul class="small-font">
                                                                    <li class="mb-2">{{ $t(`Receive a VIP Pass for an
                                                                        additional 10% savings, valid until the end
                                                                        of 2026 and applicable on retail prices and
                                                                        boutiques. It is non-transferable and
                                                                        cannot be redeemed as cash.`)}}</li>
                                                                    <li class="mb-2">{{ $t(`All products and offers are
                                                                        subject to availability and may change
                                                                        without prior notice. They cannot be exchanged
                                                                        or combined with any other offers or
                                                                        promotions.`)}}</li>
                                                                    <li class="mb-2">{{ $t(`Private access to The
                                                                        Bicester Collection's Apartments and private
                                                                        parking reservations require booking in advance
                                                                        through Your Centurion Relationship
                                                                        Manager.`)}}</li>
                                                                    <li class="mb-2">{{ $t(`Terms and conditions of The
                                                                        Bicester Collection Villages apply.`)}}</li>
                                                                    <li class="mb-2">{{ $t(`For more information and
                                                                        bookings, please call your Centurion
                                                                        Relationship Manager or From inside Saudi
                                                                        Arabia:`)}} <a href="tel:800 122 5225">800 122
                                                                            5225</a> {{ $t(`From outside Saudi
                                                                        Arabia:`)}} <a
                                                                           href="tel:(+966) 11 407 1999">(+966) 11 407
                                                                            1999</a> {{ $t(`Or via email:`) }} <a
                                                                           href="mailto:centurion@americanexpress.com.sa">centurion@americanexpress.com.sa</a>
                                                                    </li>
                                                                </ul>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--cent-black-footer paragraph--view-mode--default cent-bg-">
                                        <div class="black-bg footer-pad">
                                            <div class="container g-md-0">
                                                <div class="row g-md-0">
                                                    <div
                                                         class="col-12 col-md-6 mt-3 mt-md-0 p-md-0 d-flex align-items-md-center flex-column justify-content-md-center position-relative">
                                                        <div class="cent-black-footer-image">

                                                            <div
                                                                 class="field field--name-field-black-footer-image field--type-image field--label-hidden field__item">
                                                                <img :src="require(`@/assets/images/centurion/lifestyle-racing.jpg`)"
                                                                     width="499"
                                                                     height="500"
                                                                     alt=""
                                                                     loading="lazy"
                                                                     typeof="foaf:Image"
                                                                     class="img-fluid" />

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div
                                                         class="col-12 col-md-6 mt-3 mt-md-0 p-md-0 d-flex align-self-md-center flex-column">
                                                        <div class="cent-black-footer-text">

                                                            <div
                                                                 class="clearfix text-formatted field field--name-field-black-footer-title field--type-text-long field--label-hidden field__item">
                                                                {{ $t(`WORLDWIDE FORMULA RACING, EXCLUSIVE PREMIUM
                                                                MOMENTS`)}}<p></p>
                                                            </div>


                                                            <div
                                                                 class="clearfix text-formatted field field--name-field-black-footer-text field--type-text-long field--label-hidden field__item">
                                                                <p>{{ $t(`Revel in a genuine Formula 1 Experience
                                                                    worldwide and celebrate the highest class of
                                                                    international racing. To avail your exclusive 6%
                                                                    markdown and enjoy access to the VIP
                                                                    area, use code AESA24 with your American Express
                                                                    Centurion Card on the F1 Experiences`)}}
                                                                    <a href="https://f1experiences.com/aesa?aesa=true"
                                                                         target="_blank"
                                                                       style="color: #ffffff !important;text-decoration: underline;">{{ $t(`website`) }}</a>.
                                                                </p>

                                                                <p>{{ $t(`*Benefit are subject to availability and change
                                                                    without prior notice`)}}</p>

                                                                <p>{{ $t(`Terms and Conditions apply.`) }}</p>
                                                            </div>

                                                        </div>
                                                        <div class="cent-black-footer-code">

                                                            <div
                                                                 class="clearfix text-formatted field field--name-field-black-footer-code field--type-text field--label-hidden field__item">
                                                                CODE: AESA24</div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </article>

                </div>

            </div>


        </main>
        <CenturionFooter></CenturionFooter>
    </div>
</template>

<script>
import store from '@/store';

import CenturionHeader from './centurion-header.vue';
import CenturionFooter from './centurion-footer.vue';

export default {
    components: {
        CenturionHeader,
        CenturionFooter
    },
    data: () => ({
        offers: [],
        loaded: true,
        selectedLanguage: 'en',
        heading: 'Available Offers',
        loadedCount: 0,
        unsubscribe: null,
    }),
    created()
    {
        this.unsubscribe = store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
                if (store.get('requestKeys/accessToken') && this.loadedCount == 0)
                {
                    this.loadedCount++;
                }

            }
        })

    },

    destroyed()
    {
        this.unsubscribe();
    },
    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang)
        {
            this.selectedLanguage = localStorage.getItem('language');
        }
        // this.loggedOffers();
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
@import './centurion.scss';
@import './bootstrap.css';

.inclined {
    font-size: 0.75em;

}

p~.field--name-field-centurion-page-title-text {
    margin-bottom: 0 !important;
}

.small-font {
    font-size: 0.9em;
}

.small-font-star li {
    font-size: 0.9em;
    list-style: none;
    margin-left: 0;
    text-indent: -1.5em;
}

.small-font-star li::before {
    content: "*";
    padding: 0 6px 0 7px;
}
</style>