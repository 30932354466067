<template>
    <header>
        <nav class="navbar navbar-expand-lg">
            <div class="container">
                <div class="navbar-brand d-flex align-items-center"><img alt="Centurion Home"
                         class="img-fluid"
                         :src="require(`@/assets/images/centurion/centurion-branding-logo.png`)" /></div><button
                        class="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
                <div class="collapse navbar-collapse"
                     id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <router-link class="nav-link"
                                         to="/centurion-home"
                                         active-class="active">
                                {{ $t('HOME PAGE') }}
                            </router-link>

                            <!-- <a class="nav-link"
                               @click="onClick('')">{{ $t('HOME PAGE') }}</a> -->
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link"
                                         to="/centurion-experience"
                                         active-class="active">
                                {{ $t('EXPERIENCES & OFFERS') }}
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link"
                                         to="/centurion-lifestyle"
                                         active-class="active">
                                {{ $t('LIFESTYLE') }}
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link"
                                         to="/centurion-membership"
                                         active-class="active">
                                {{ $t('MEMBERSHIP REWARDS') }}
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link"
                                         to="/centurion-travel-main"
                                         active-class="active">
                                {{ $t('TRAVEL INSURANCE') }}
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link"
                                         to="/centurion-luxury"
                                         active-class="active">
                                {{ $t('LUXURY VOYAGE PRIVILEGES') }}
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link"
                                         to="/centurion-dining"
                                         active-class="active">
                                {{ $t('DINING PROGRAM') }}
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
import store from '@/store';
import router from '@/router';


export default {
    components: {

    },
    data: () => ({
        offers: [],
        loaded: true,
        selectedLanguage: 'en',
        heading: 'Available Offers',
        loadedCount: 0,
        unsubscribe: null,
    }),
    created()
    {
        this.unsubscribe = store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
                if (store.get('requestKeys/accessToken') && this.loadedCount == 0)
                {
                    this.loadedCount++;
                }

            }
        })

    },

    destroyed()
    {
        this.unsubscribe();
    },
    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang)
        {
            this.selectedLanguage = localStorage.getItem('language');
        }
        // this.loggedOffers();
    },
    methods: {
        onClick(path)
        {
            router.push('/' + path);
        }
    }
}
</script>


<style lang="scss" scoped>
@import './centurion.scss';
@import './bootstrap.css';

.banner-cont {
    background: url(../../assets/images/centurion/centurion_en-min.png) 100% 70% no-repeat;
    background-size: cover;
    min-height: 450px;
}

table {
    width: 100%;
    border: solid 1px;
    text-align: center;
}

th {
    padding: 1em;
    border: solid 1px;
}

td {
    border: solid 1px;
    padding: 0.25em 0;
}

@media only screen and (max-width: 576px) {
    table {
        display: block;
        overflow-x: scroll;
    }
}

.phone,
.email,
.fs {
    font-size: 0.9em;
}

.phone a,
.email a {
    color: #ffffff;
    text-decoration: none;
}

.phone a:hover,
.email a:hover {
    color: #ffffff;
    text-decoration: underline;
}

.pad {
    padding: 0;
}

.brd {
    border-bottom: solid 1px #fff;
    margin: 1em 0 2em 0;
    max-width: 50%;
}

.pr-0 {
    padding-right: 0;
}

.centtoctitle {
    color: #000000;
    font-size: 2em;
}

.centtoclist,
.centtoclist a,
.centtoclist a:link,
.centtoclist a:visited,
.centtoclist a:hover {
    color: #000000;
    font-size: 1em;
    line-height: 1.5em;
}

@media only screen and (max-width: 576px) {
    .centtoclist {
        margin-bottom: 1.5em;
    }
}
</style>
