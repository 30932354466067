<template>
    <div class="dialog-off-canvas-main-canvas d-flex flex-column h-100"
         style="background: white;"
         data-off-canvas-main-canvas>
        <CenturionHeader></CenturionHeader>
        <main role="main">
            <a id="main-content"
               tabindex="-1"></a>
            <div class="region region-content">
                <div data-drupal-messages-fallback
                     class="hidden"></div>
                <div id="block-amexsubtheme-content"
                     class="block block-system block-system-main-block">
                    <article data-history-node-id="1087"
                             role="article"
                             about="/content/luxury-voyage-privileges"
                             class="node node--type-centurion-page node--view-mode-full">
                        <div class="node__content">
                            <div
                                 class="field field--name-field-centurion-page-sections field--type-entity-reference-revisions field--label-hidden field__items">
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--centurion-sub-page-banner paragraph--view-mode--default">
                                        <div class="container">
                                            <div class="row g-md-0 black-bg">
                                                <div class="col-md-4">
                                                    <div class="sub-page-banner-title">
                                                        <div class="clearfix text-formatted field field--name-field-sub-banner-title field--type-text field--label-hidden field__item"
                                                             style="color: white">
                                                            {{ $t(`ExploretheWorldLuxury1`) }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-8">
                                                    <div class="sub-page-banner-image">
                                                        <div
                                                             class="field field--name-field-sub-banner-image field--type-image field--label-hidden field__item">
                                                            <img :src="require(`@/assets/images/centurion/travel-banner_0.jpg`)"
                                                                 width="640"
                                                                 height="453"
                                                                 alt=""
                                                                 loading="lazy"
                                                                 typeof="foaf:Image"
                                                                 class="img-fluid" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--cent-scrolling-navigation paragraph--view-mode--default">
                                        <div class="container">
                                            <div class="row g-md-0">
                                                <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                    <div class="cent-carousel-scrolling-cont">
                                                        <div
                                                             class="clearfix text-formatted field field--name-field-scrolling-navigation-title field--type-text-long field--label-hidden field__item">
                                                            <p>{{ $t(`TAKE YOUR TRAVEL EXPERIENCE TO NEW HEIGHTS!`) }}
                                                            </p>
                                                        </div>
                                                        <div class="row scrolltomargin grid-cont">
                                                            <div class="grid-view">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-scrolling-navigation-items paragraph--view-mode--default">
                                                                    <div class="container">
                                                                        <div class="row g-md-0">
                                                                            <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                <div class="cent-carousel-scrolling">
                                                                                    <a href="#airlines">
                                                                                        <div
                                                                                             class="field field--name-field-scrolling-navigate-logo field--type-image field--label-hidden field__item">
                                                                                            <img :src="require(`@/assets/images/centurion/airplane.jpg`)"
                                                                                                 width="93"
                                                                                                 height="60"
                                                                                                 alt=""
                                                                                                 loading="lazy"
                                                                                                 typeof="foaf:Image"
                                                                                                 class="img-fluid" />
                                                                                        </div>
                                                                                        <div
                                                                                             class="clearfix text-formatted field field--name-field-scrolling-navigate-text field--type-text field--label-hidden field__item">
                                                                                            {{ $t(`International Airline
                                                                                            Program`) }}
                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="grid-view">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-scrolling-navigation-items paragraph--view-mode--default">
                                                                    <div class="container">
                                                                        <div class="row g-md-0">
                                                                            <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                <div class="cent-carousel-scrolling">
                                                                                    <a href="#hotels">
                                                                                        <div
                                                                                             class="field field--name-field-scrolling-navigate-logo field--type-image field--label-hidden field__item">
                                                                                            <img :src="require(`@/assets/images/centurion/hotels_0.jpg`)"
                                                                                                 width="62"
                                                                                                 height="60"
                                                                                                 alt=""
                                                                                                 loading="lazy"
                                                                                                 typeof="foaf:Image"
                                                                                                 class="img-fluid" />
                                                                                        </div>
                                                                                        <div
                                                                                             class="clearfix text-formatted field field--name-field-scrolling-navigate-text field--type-text field--label-hidden field__item">
                                                                                            {{ $t(`Hotels Program`) }}
                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="grid-view">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-scrolling-navigation-items paragraph--view-mode--default">
                                                                    <div class="container">
                                                                        <div class="row g-md-0">
                                                                            <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                <div class="cent-carousel-scrolling">
                                                                                    <a href="#carrentals">
                                                                                        <div
                                                                                             class="field field--name-field-scrolling-navigate-logo field--type-image field--label-hidden field__item">
                                                                                            <img :src="require(`@/assets/images/centurion/car-rental_0.jpg`)"
                                                                                                 width="160"
                                                                                                 height="60"
                                                                                                 alt=""
                                                                                                 loading="lazy"
                                                                                                 typeof="foaf:Image"
                                                                                                 class="img-fluid" />
                                                                                        </div>
                                                                                        <div
                                                                                             class="clearfix text-formatted field field--name-field-scrolling-navigate-text field--type-text field--label-hidden field__item">
                                                                                            {{ $t(`The Car Rental Elite
                                                                                            Memberships`) }}
                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="grid-view">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-scrolling-navigation-items paragraph--view-mode--default">
                                                                    <div class="container">
                                                                        <div class="row g-md-0">
                                                                            <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                <div class="cent-carousel-scrolling">
                                                                                    <a href="#cruise">
                                                                                        <div
                                                                                             class="field field--name-field-scrolling-navigate-logo field--type-image field--label-hidden field__item">
                                                                                            <img :src="require(`@/assets/images/centurion/cruise_0.jpg`)
                                                                                                "
                                                                                                 width="91"
                                                                                                 height="60"
                                                                                                 alt=""
                                                                                                 loading="lazy"
                                                                                                 typeof="foaf:Image"
                                                                                                 class="img-fluid" />
                                                                                        </div>
                                                                                        <div
                                                                                             class="clearfix text-formatted field field--name-field-scrolling-navigate-text field--type-text field--label-hidden field__item">
                                                                                            {{ $t(`Cruise Program`) }}
                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="grid-view">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-scrolling-navigation-items paragraph--view-mode--default">
                                                                    <div class="container">
                                                                        <div class="row g-md-0">
                                                                            <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                <div class="cent-carousel-scrolling">
                                                                                    <a href="#lounge">
                                                                                        <div
                                                                                             class="field field--name-field-scrolling-navigate-logo field--type-image field--label-hidden field__item">
                                                                                            <img :src="require(`@/assets/images/centurion/lounge_0.jpg`)"
                                                                                                 width="62"
                                                                                                 height="60"
                                                                                                 alt=""
                                                                                                 loading="lazy"
                                                                                                 typeof="foaf:Image"
                                                                                                 class="img-fluid" />
                                                                                        </div>
                                                                                        <div
                                                                                             class="clearfix text-formatted field field--name-field-scrolling-navigate-text field--type-text field--label-hidden field__item">
                                                                                            {{ $t(`Global Lounge
                                                                                            Collection`) }}
                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="grid-view">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-scrolling-navigation-items paragraph--view-mode--default">
                                                                    <div class="container">
                                                                        <div class="row g-md-0">
                                                                            <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                <div class="cent-carousel-scrolling">
                                                                                    <a href="#tanfeethi">
                                                                                        <div
                                                                                             class="field field--name-field-scrolling-navigate-logo field--type-image field--label-hidden field__item">
                                                                                            <img :src="require(`@/assets/images/centurion/membership_0.jpg`)"
                                                                                                 width="58"
                                                                                                 height="60"
                                                                                                 alt=""
                                                                                                 loading="lazy"
                                                                                                 typeof="foaf:Image"
                                                                                                 class="img-fluid" />
                                                                                        </div>
                                                                                        <div
                                                                                             class="clearfix text-formatted field field--name-field-scrolling-navigate-text field--type-text field--label-hidden field__item">
                                                                                            {{ $t(`ALTANFEETHI`) }}
                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="grid-view">
                                                                <div
                                                                     class="paragraph paragraph--type--cent-scrolling-navigation-items paragraph--view-mode--default">
                                                                    <div class="container">
                                                                        <div class="row g-md-0">
                                                                            <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                <div class="cent-carousel-scrolling">
                                                                                    <a href="#centurion">
                                                                                        <div
                                                                                             class="field field--name-field-scrolling-navigate-logo field--type-image field--label-hidden field__item">
                                                                                            <img :src="require(`@/assets/images/centurion/membership_1.jpg`)"
                                                                                                 width="58"
                                                                                                 height="60"
                                                                                                 alt=""
                                                                                                 loading="lazy"
                                                                                                 typeof="foaf:Image"
                                                                                                 class="img-fluid" />
                                                                                        </div>
                                                                                        <div
                                                                                             class="clearfix text-formatted field field--name-field-scrolling-navigate-text field--type-text field--label-hidden field__item">
                                                                                            <div
                                                                                                 style="font-size: inherit;direction: ltr;">
                                                                                                Centurion New York™
                                                                                            </div>
                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field__item">
                                    <div
                                         class="paragraph paragraph--type--cent-scrolling-sections paragraph--view-mode--default">
                                        <div
                                             class="field field--name-field-scrolling-section field--type-entity-reference-revisions field--label-hidden field__items">
                                            <div class="field__item">
                                                <div
                                                     class="paragraph paragraph--type--cent-scrolling-section paragraph--view-mode--default">
                                                    <a id="airlines"></a>
                                                    <div
                                                         class="field field--name-field-scrolling-section-item field--type-entity-reference-revisions field--label-hidden field__items">
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--centurion-free-design paragraph--view-mode--default">
                                                                <div class="container">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-freedesign">
                                                                                <div class="row">
                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-free-design-text field--type-text-long field--label-hidden field__item">
                                                                                        <div class="freedesign-title">
                                                                                            {{ $t(`PREMIUM AIR TRAVEL,
                                                                                            ANYWHERE IN THE WORLD`) }}
                                                                                        </div>
                                                                                        <div class="freedesign-text">
                                                                                            {{ $t(`Elevate your flightLuxury2`) }}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--cent-airways-container paragraph--view-mode--default">
                                                                <div class="container">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-aiways-cont">
                                                                                <div class="row">
                                                                                    <div class="col-6 col-md-3">
                                                                                        <div
                                                                                             class="paragraph paragraph--type--cent-airways-items paragraph--view-mode--default">
                                                                                            <div class="row g-md-0">
                                                                                                <div
                                                                                                     class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                                    <div
                                                                                                         class="cent-airways-items">
                                                                                                        <div
                                                                                                             class="flip-card">
                                                                                                            <div
                                                                                                                 class="flip-card-inner">
                                                                                                                <div
                                                                                                                     class="flip-card-front d-flex justify-content-around flex-column">
                                                                                                                    <div
                                                                                                                         class="row justify-content-end">
                                                                                                                        <div
                                                                                                                             class="col-auto closebtn text-end">
                                                                                                                            <a href="#close"
                                                                                                                               id="centairwaysplus">
                                                                                                                                <img :src="require(`@/assets/images/centurion/airwaysplus.svg`)"
                                                                                                                                     class="img-fluid"
                                                                                                                                     alt="Plus Icon">
                                                                                                                            </a>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                         class="col-12">
                                                                                                                        <div
                                                                                                                             class="field field--name-field-airways-logo field--type-image field--label-hidden field__item">
                                                                                                                            <img :src="require(`@/assets/images/centurion/lufthansa-logo.png`)"
                                                                                                                                 width="215"
                                                                                                                                 height="121"
                                                                                                                                 alt=""
                                                                                                                                 loading="lazy"
                                                                                                                                 typeof="foaf:Image"
                                                                                                                                 class="img-fluid" />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                         class="col-12">
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                     class="flip-card-back">
                                                                                                                    <div
                                                                                                                         class="row justify-content-end">
                                                                                                                        <div
                                                                                                                             class="col-auto closebtn text-end">
                                                                                                                            <a href="#close"
                                                                                                                               id="centairwaysminus">
                                                                                                                                <img :src="require(`@/assets/images/centurion/airwaysminus.svg`)"
                                                                                                                                     class="img-fluid"
                                                                                                                                     alt="Plus Icon">
                                                                                                                            </a>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                         class="clearfix text-formatted field field--name-field-airways-back-text field--type-text-long field--label-hidden field__item">
                                                                                                                        <ul>
                                                                                                                            <li>{{
                                                                                                                                $t(`This
                                                                                                                                offer
                                                                                                                                is
                                                                                                                                valid
                                                                                                                                for
                                                                                                                                sale
                                                                                                                                until
                                                                                                                                January
                                                                                                                                31,
                                                                                                                                2025.`)
                                                                                                                                }}
                                                                                                                            </li>
                                                                                                                            <li>{{
                                                                                                                                $t(`This
                                                                                                                                offer
                                                                                                                                is
                                                                                                                                valid
                                                                                                                                on
                                                                                                                                roundtrip
                                                                                                                                journeys
                                                                                                                                from
                                                                                                                                KSA
                                                                                                                                to
                                                                                                                                selected
                                                                                                                                destinations
                                                                                                                                in
                                                                                                                                Europe,
                                                                                                                                Canada,
                                                                                                                                and
                                                                                                                                the
                                                                                                                                North
                                                                                                                                Atlantic.`)
                                                                                                                                }}
                                                                                                                            </li>
                                                                                                                            <li>{{
                                                                                                                                $t(`This
                                                                                                                                offer
                                                                                                                                includes
                                                                                                                                discounts
                                                                                                                                of
                                                                                                                                up
                                                                                                                                to
                                                                                                                                15%:
                                                                                                                                5%
                                                                                                                                in
                                                                                                                                First
                                                                                                                                Class,
                                                                                                                                up
                                                                                                                                to
                                                                                                                                12%
                                                                                                                                in
                                                                                                                                Business
                                                                                                                                and
                                                                                                                                Economy,
                                                                                                                                and
                                                                                                                                up
                                                                                                                                to
                                                                                                                                15%
                                                                                                                                in
                                                                                                                                Premium
                                                                                                                                Economy
                                                                                                                                (selected
                                                                                                                                routes).`)
                                                                                                                                }}
                                                                                                                            </li>
                                                                                                                            <li>{{
                                                                                                                                $t(`This
                                                                                                                                offer
                                                                                                                                allows
                                                                                                                                Miles
                                                                                                                                &
                                                                                                                                More
                                                                                                                                members
                                                                                                                                to
                                                                                                                                earn
                                                                                                                                Tier
                                                                                                                                Points.`)
                                                                                                                                }}
                                                                                                                            </li>
                                                                                                                            <li>{{
                                                                                                                                $t(`This
                                                                                                                                offer
                                                                                                                                is
                                                                                                                                subject
                                                                                                                                to
                                                                                                                                Lufthansa
                                                                                                                                Group
                                                                                                                                Terms
                                                                                                                                and
                                                                                                                                Conditions.`)
                                                                                                                                }}
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-6 col-md-3">
                                                                                        <div
                                                                                             class="paragraph paragraph--type--cent-airways-items paragraph--view-mode--default">
                                                                                            <div class="row g-md-0">
                                                                                                <div
                                                                                                     class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                                    <div
                                                                                                         class="cent-airways-items">
                                                                                                        <div
                                                                                                             class="flip-card">
                                                                                                            <div
                                                                                                                 class="flip-card-inner">
                                                                                                                <div
                                                                                                                     class="flip-card-front d-flex justify-content-around flex-column">
                                                                                                                    <div
                                                                                                                         class="row justify-content-end">
                                                                                                                        <div
                                                                                                                             class="col-auto closebtn text-end">
                                                                                                                            <a href="#close"
                                                                                                                               id="centairwaysplus">
                                                                                                                                <img :src="require(`@/assets/images/centurion/airwaysplus.svg`)"
                                                                                                                                     class="img-fluid"
                                                                                                                                     alt="Plus Icon">
                                                                                                                            </a>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                         class="col-12">
                                                                                                                        <div
                                                                                                                             class="field field--name-field-airways-logo field--type-image field--label-hidden field__item">
                                                                                                                            <img :src="require(`@/assets/images/centurion/etihad-logo.jpg`)"
                                                                                                                                 width="215"
                                                                                                                                 height="121"
                                                                                                                                 alt=""
                                                                                                                                 loading="lazy"
                                                                                                                                 typeof="foaf:Image"
                                                                                                                                 class="img-fluid" />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                         class="col-12">
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                     class="flip-card-back">
                                                                                                                    <div
                                                                                                                         class="row justify-content-end">
                                                                                                                        <div
                                                                                                                             class="col-auto closebtn text-end">
                                                                                                                            <a href="#close"
                                                                                                                               id="centairwaysminus">
                                                                                                                                <img :src="require(`@/assets/images/centurion/airwaysminus.svg`)"
                                                                                                                                     class="img-fluid"
                                                                                                                                     alt="Plus Icon">
                                                                                                                            </a>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                         class="clearfix text-formatted field field--name-field-airways-back-text field--type-text-long field--label-hidden field__item">
                                                                                                                        <ul>
                                                                                                                            <li>{{
                                                                                                                                $t(`This
                                                                                                                                offer
                                                                                                                                is
                                                                                                                                valid
                                                                                                                                for
                                                                                                                                sale
                                                                                                                                until
                                                                                                                                December
                                                                                                                                31,
                                                                                                                                2024.`)
                                                                                                                                }}
                                                                                                                            </li>
                                                                                                                            <li>{{
                                                                                                                                $t(`This
                                                                                                                                offer
                                                                                                                                includes
                                                                                                                                up
                                                                                                                                to
                                                                                                                                15%
                                                                                                                                off
                                                                                                                                on
                                                                                                                                selected
                                                                                                                                classes
                                                                                                                                for
                                                                                                                                First
                                                                                                                                (not
                                                                                                                                applicable
                                                                                                                                for
                                                                                                                                The
                                                                                                                                Residence),
                                                                                                                                Business,
                                                                                                                                and
                                                                                                                                Economy
                                                                                                                                on
                                                                                                                                one-way
                                                                                                                                and
                                                                                                                                roundtrip
                                                                                                                                journeys
                                                                                                                                on
                                                                                                                                Etihad-operated
                                                                                                                                flights.`)
                                                                                                                                }}
                                                                                                                            </li>
                                                                                                                            <li>{{
                                                                                                                                $t(`This
                                                                                                                                offer
                                                                                                                                is
                                                                                                                                not
                                                                                                                                combinable
                                                                                                                                and
                                                                                                                                applies
                                                                                                                                to
                                                                                                                                the
                                                                                                                                base
                                                                                                                                fare
                                                                                                                                only.`)
                                                                                                                                }}
                                                                                                                            </li>
                                                                                                                            <li>{{
                                                                                                                                $t(`This
                                                                                                                                offer
                                                                                                                                allows
                                                                                                                                Etihad
                                                                                                                                Guest
                                                                                                                                members
                                                                                                                                to
                                                                                                                                earn
                                                                                                                                Tier
                                                                                                                                Points
                                                                                                                                as
                                                                                                                                per
                                                                                                                                program
                                                                                                                                terms
                                                                                                                                and
                                                                                                                                conditions.`)
                                                                                                                                }}
                                                                                                                            </li>
                                                                                                                            <li>{{
                                                                                                                                $t(`This
                                                                                                                                offer
                                                                                                                                is
                                                                                                                                subject
                                                                                                                                to
                                                                                                                                Etihad
                                                                                                                                Terms
                                                                                                                                and
                                                                                                                                Conditions.`)
                                                                                                                                }}
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-6 col-md-3">
                                                                                        <div
                                                                                             class="paragraph paragraph--type--cent-airways-items paragraph--view-mode--default">
                                                                                            <div class="row g-md-0">
                                                                                                <div
                                                                                                     class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                                    <div
                                                                                                         class="cent-airways-items">
                                                                                                        <div
                                                                                                             class="flip-card">
                                                                                                            <div
                                                                                                                 class="flip-card-inner">
                                                                                                                <div
                                                                                                                     class="flip-card-front d-flex justify-content-around flex-column">
                                                                                                                    <div
                                                                                                                         class="row justify-content-end">
                                                                                                                        <div
                                                                                                                             class="col-auto closebtn text-end">
                                                                                                                            <a href="#close"
                                                                                                                               id="centairwaysplus">
                                                                                                                                <img :src="require(`@/assets/images/centurion/airwaysplus.svg`)"
                                                                                                                                     class="img-fluid"
                                                                                                                                     alt="Plus Icon">
                                                                                                                            </a>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                         class="col-12">
                                                                                                                        <div
                                                                                                                             class="field field--name-field-airways-logo field--type-image field--label-hidden field__item">
                                                                                                                            <img :src="require(`@/assets/images/centurion/emirates-logo.png`)"
                                                                                                                                 width="215"
                                                                                                                                 height="121"
                                                                                                                                 alt=""
                                                                                                                                 loading="lazy"
                                                                                                                                 typeof="foaf:Image"
                                                                                                                                 class="img-fluid" />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                         class="col-12">
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                     class="flip-card-back">
                                                                                                                    <div
                                                                                                                         class="row justify-content-end">
                                                                                                                        <div
                                                                                                                             class="col-auto closebtn text-end">
                                                                                                                            <a href="#close"
                                                                                                                               id="centairwaysminus">
                                                                                                                                <img :src="require(`@/assets/images/centurion/airwaysminus.svg`)"
                                                                                                                                     class="img-fluid"
                                                                                                                                     alt="Plus Icon">
                                                                                                                            </a>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                         class="clearfix text-formatted field field--name-field-airways-back-text field--type-text-long field--label-hidden field__item">
                                                                                                                        <ul>
                                                                                                                            <li>{{
                                                                                                                                $t(`This
                                                                                                                                offer
                                                                                                                                is
                                                                                                                                valid
                                                                                                                                for
                                                                                                                                sale
                                                                                                                                until
                                                                                                                                September
                                                                                                                                30,
                                                                                                                                2025.`)
                                                                                                                                }}
                                                                                                                            </li>
                                                                                                                            <li>{{
                                                                                                                                $t(`This
                                                                                                                                offer
                                                                                                                                includes
                                                                                                                                discounts
                                                                                                                                of
                                                                                                                                up
                                                                                                                                to
                                                                                                                                10%
                                                                                                                                in
                                                                                                                                First
                                                                                                                                and
                                                                                                                                Business
                                                                                                                                Class
                                                                                                                                on
                                                                                                                                Emirates-operated
                                                                                                                                flights.`)
                                                                                                                                }}
                                                                                                                            </li>
                                                                                                                            <li>{{
                                                                                                                                $t(`This
                                                                                                                                offer
                                                                                                                                applies
                                                                                                                                to
                                                                                                                                the
                                                                                                                                base
                                                                                                                                fare
                                                                                                                                only,
                                                                                                                                and
                                                                                                                                is
                                                                                                                                not
                                                                                                                                combinable.`)
                                                                                                                                }}
                                                                                                                            </li>
                                                                                                                            <li>{{
                                                                                                                                $t(`This
                                                                                                                                offer
                                                                                                                                allows
                                                                                                                                a
                                                                                                                                maximum
                                                                                                                                of
                                                                                                                                eight
                                                                                                                                air
                                                                                                                                tickets
                                                                                                                                per
                                                                                                                                booking
                                                                                                                                reference`)
                                                                                                                                }}
                                                                                                                            </li>
                                                                                                                            <li>{{
                                                                                                                                $t(`This
                                                                                                                                offer
                                                                                                                                allows
                                                                                                                                Skywards
                                                                                                                                members
                                                                                                                                to
                                                                                                                                earn
                                                                                                                                Tier
                                                                                                                                Points
                                                                                                                                based
                                                                                                                                on
                                                                                                                                eligibility
                                                                                                                                in
                                                                                                                                selected
                                                                                                                                booking
                                                                                                                                classes.`)
                                                                                                                                }}
                                                                                                                            </li>
                                                                                                                            <li>{{
                                                                                                                                $t(`This
                                                                                                                                offer
                                                                                                                                is
                                                                                                                                subject
                                                                                                                                to
                                                                                                                                Emirates
                                                                                                                                Terms
                                                                                                                                and
                                                                                                                                Conditions.`)
                                                                                                                                }}
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-6 col-md-3">
                                                                                        <div
                                                                                             class="paragraph paragraph--type--cent-airways-items paragraph--view-mode--default">
                                                                                            <div class="row g-md-0">
                                                                                                <div
                                                                                                     class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                                    <div
                                                                                                         class="cent-airways-items">
                                                                                                        <div
                                                                                                             class="flip-card">
                                                                                                            <div
                                                                                                                 class="flip-card-inner">
                                                                                                                <div
                                                                                                                     class="flip-card-front d-flex justify-content-around flex-column">
                                                                                                                    <div
                                                                                                                         class="row justify-content-end">
                                                                                                                        <div
                                                                                                                             class="col-auto closebtn text-end">
                                                                                                                            <a href="#close"
                                                                                                                               id="centairwaysplus">
                                                                                                                                <img :src="require(`@/assets/images/centurion/airwaysplus.svg`)"
                                                                                                                                     class="img-fluid"
                                                                                                                                     alt="Plus Icon">
                                                                                                                            </a>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                         class="col-12">
                                                                                                                        <div
                                                                                                                             class="field field--name-field-airways-logo field--type-image field--label-hidden field__item">
                                                                                                                            <img :src="require(`@/assets/images/centurion/saudia-logo.jpg`)"
                                                                                                                                 width="215"
                                                                                                                                 height="121"
                                                                                                                                 alt=""
                                                                                                                                 loading="lazy"
                                                                                                                                 typeof="foaf:Image"
                                                                                                                                 class="img-fluid" />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                         class="col-12">
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                     class="flip-card-back">
                                                                                                                    <div
                                                                                                                         class="row justify-content-end">
                                                                                                                        <div
                                                                                                                             class="col-auto closebtn text-end">
                                                                                                                            <a href="#close"
                                                                                                                               id="centairwaysminus">
                                                                                                                                <img :src="require(`@/assets/images/centurion/airwaysminus.svg`)"
                                                                                                                                     class="img-fluid"
                                                                                                                                     alt="Plus Icon">
                                                                                                                            </a>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                         class="clearfix text-formatted field field--name-field-airways-back-text field--type-text-long field--label-hidden field__item">
                                                                                                                        <ul>
                                                                                                                            <li>{{
                                                                                                                                $t(`This
                                                                                                                                offer
                                                                                                                                is
                                                                                                                                valid
                                                                                                                                for
                                                                                                                                sale
                                                                                                                                until
                                                                                                                                October
                                                                                                                                31,
                                                                                                                                2025.`)
                                                                                                                                }}
                                                                                                                            </li>
                                                                                                                            <li>{{
                                                                                                                                $t(`This
                                                                                                                                offer
                                                                                                                                is
                                                                                                                                Valid
                                                                                                                                for
                                                                                                                                one-way
                                                                                                                                and
                                                                                                                                roundtrip
                                                                                                                                journeys
                                                                                                                                on
                                                                                                                                Saudia-operated
                                                                                                                                flights
                                                                                                                                to
                                                                                                                                international
                                                                                                                                destinations.`)
                                                                                                                                }}
                                                                                                                            </li>
                                                                                                                            <li>{{
                                                                                                                                $t(`Enjoy
                                                                                                                                discounts
                                                                                                                                of
                                                                                                                                up
                                                                                                                                to
                                                                                                                                20%
                                                                                                                                in
                                                                                                                                First
                                                                                                                                Class,
                                                                                                                                up
                                                                                                                                to
                                                                                                                                18%
                                                                                                                                in
                                                                                                                                Business
                                                                                                                                Class,
                                                                                                                                and
                                                                                                                                up
                                                                                                                                to
                                                                                                                                15%
                                                                                                                                in
                                                                                                                                Economy
                                                                                                                                Class,
                                                                                                                                with
                                                                                                                                varying
                                                                                                                                percentages
                                                                                                                                based
                                                                                                                                on
                                                                                                                                fare
                                                                                                                                class.`)
                                                                                                                                }}
                                                                                                                            </li>
                                                                                                                            <li>{{
                                                                                                                                $t(`Discounts
                                                                                                                                apply
                                                                                                                                to
                                                                                                                                base
                                                                                                                                fares
                                                                                                                                only,
                                                                                                                                excluding
                                                                                                                                taxes
                                                                                                                                and
                                                                                                                                fees.`)
                                                                                                                                }}
                                                                                                                            </li>
                                                                                                                            <li>{{
                                                                                                                                $t(`Al
                                                                                                                                Fursan
                                                                                                                                members
                                                                                                                                will
                                                                                                                                earn
                                                                                                                                Tier
                                                                                                                                Points
                                                                                                                                according
                                                                                                                                to
                                                                                                                                program
                                                                                                                                terms.`)
                                                                                                                                }}
                                                                                                                            </li>
                                                                                                                            <li>{{
                                                                                                                                $t(`Saudia
                                                                                                                                Terms
                                                                                                                                and
                                                                                                                                Conditions
                                                                                                                                apply.`)
                                                                                                                                }}
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--centurion-free-design paragraph--view-mode--default">
                                                                <div class="container">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-freedesign">
                                                                                <div class="row">
                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-free-design-text field--type-text-long field--label-hidden field__item">
                                                                                        <div class="freedesign-text">
                                                                                            {{ $t(`For more details and
                                                                                            bookings, please contact
                                                                                            your Centurion Relationship
                                                                                            Manager!`) }}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="field__item">
                                                <div
                                                     class="paragraph paragraph--type--cent-scrolling-section paragraph--view-mode--default">
                                                    <a id="hotels"></a>
                                                    <div
                                                         class="field field--name-field-scrolling-section-item field--type-entity-reference-revisions field--label-hidden field__items">
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--centurion-free-design paragraph--view-mode--default">
                                                                <div class="container">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-freedesign">
                                                                                <div class="row">
                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-free-design-text field--type-text-long field--label-hidden field__item">
                                                                                        <div class="freedesign-title">
                                                                                            {{ $t(`UNMATCHED HOSPITALITY
                                                                                            WITH THE CENTURION CARD`) }}
                                                                                        </div>
                                                                                        <div class="freedesign-text">
                                                                                            {{ $t(`The Fine Hotels +
                                                                                            Resorts and The Hotel
                                                                                            Collection Programs`) }}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--centurion-image paragraph--view-mode--default">
                                                                <div class="container">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-image text-center">
                                                                                <div
                                                                                     class="field field--name-field-centurion-image field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/hospitality-cent-cards.jpg`)"
                                                                                         width="1300"
                                                                                         height="484"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--centurion-free-design paragraph--view-mode--default">
                                                                <div class="container">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-freedesign">
                                                                                <div class="row">
                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-free-design-text field--type-text-long field--label-hidden field__item">
                                                                                        <div class="freedesign-text">
                                                                                            <p
                                                                                               class="cent-card-txt mt-3 mb-3">
                                                                                                {{ $t(`Elevate every
                                                                                                stay
                                                                                                with exclusive Centurion
                                                                                                privileges. As a
                                                                                                Centurion Cardmember,
                                                                                                unlock luxurious
                                                                                                benefits valued at $600
                                                                                                at over 1,600 Fine
                                                                                                Hotels & Resorts
                                                                                                worldwide, including
                                                                                                room upgrades,
                                                                                                complimentary breakfast
                                                                                                for two, guaranteed 4 PM
                                                                                                late checkout, and a
                                                                                                $100 hotel credit.`) }}
                                                                                            </p>
                                                                                            <p
                                                                                               class="cent-card-txt mt-3 mb-3">
                                                                                                {{ $t(`Explore
                                                                                                distinctive privileges
                                                                                                through the Hotel
                                                                                                Collection Program,
                                                                                                offering over 1,000
                                                                                                upscale properties
                                                                                                globally, with
                                                                                                limited-time offers such
                                                                                                as a complimentary 3rd
                                                                                                or 4th night at select
                                                                                                locations, room upgrades
                                                                                                when available,
                                                                                                additional $100 hotel
                                                                                                credits, and convenient
                                                                                                noon check-in and late
                                                                                                check-out.`) }}
                                                                                            </p>
                                                                                            <p
                                                                                               class="cent-card-txt text-center mt-3">
                                                                                                {{ $t(`Transform each
                                                                                                journey into a luxurious
                                                                                                experience with our
                                                                                                exclusive travel
                                                                                                perquisites designed to
                                                                                                enhance every
                                                                                                moment.`) }}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--centurion-villages paragraph--view-mode--default cent-bg-">
                                                                <div class="village-pad">
                                                                    <div class="container g-md-0">
                                                                        <div class="row g-md-0">
                                                                            <div
                                                                                 class="col-12 mt-3 mt-md-0 p-md-0 d-flex align-items-md-center flex-column justify-content-md-center position-relative">
                                                                                <div class="cent-village-container">
                                                                                    <div
                                                                                         class="row justify-content-center">
                                                                                        <div
                                                                                             class="col-4 col-md-auto vilmar">
                                                                                            <div
                                                                                                 class="paragraph paragraph--type--centurion-village-items paragraph--view-mode--default">
                                                                                                <div class="row g-md-0">
                                                                                                    <div
                                                                                                         class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                                        <div
                                                                                                             class="cent-village-items">
                                                                                                            <a href="https://www.americanexpress.com/en-sa/travel/discover/"
                                                                                                               target="_blank">
                                                                                                                <div
                                                                                                                     class="field field--name-field-village-logo field--type-image field--label-hidden field__item">
                                                                                                                    <img :src="require(`@/assets/images/centurion/cent-fhr.png`)"
                                                                                                                         width="215"
                                                                                                                         height="69"
                                                                                                                         alt=""
                                                                                                                         loading="lazy"
                                                                                                                         typeof="foaf:Image"
                                                                                                                         class="img-fluid" />
                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                             class="col-4 col-md-auto vilmar">
                                                                                            <div
                                                                                                 class="paragraph paragraph--type--centurion-village-items paragraph--view-mode--default">
                                                                                                <div class="row g-md-0">
                                                                                                    <div
                                                                                                         class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                                        <div
                                                                                                             class="cent-village-items">
                                                                                                            <a href="https://www.americanexpress.com/en-us/travel/the-hotel-collection/"
                                                                                                               target="_blank">
                                                                                                                <div
                                                                                                                     class="field field--name-field-village-logo field--type-image field--label-hidden field__item">
                                                                                                                    <img :src="require(`@/assets/images/centurion/cent-thc.png`)"
                                                                                                                         width="197"
                                                                                                                         height="69"
                                                                                                                         alt=""
                                                                                                                         loading="lazy"
                                                                                                                         typeof="foaf:Image"
                                                                                                                         class="img-fluid" />
                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--centurion-free-design paragraph--view-mode--default">
                                                                <div class="container">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-freedesign">
                                                                                <div class="row">
                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-free-design-text field--type-text-long field--label-hidden field__item">
                                                                                        <div class="freedesign-title">
                                                                                            {{ $t(`Unlock Elite Hotel
                                                                                            Memberships with American
                                                                                            Express Centurion Experience
                                                                                            Luxury at Its Finest`) }}
                                                                                        </div>
                                                                                        <div class="freedesign-text">
                                                                                            {{ $t(`Enjoy exclusive
                                                                                            upgrades and premium
                                                                                            benefits across major global
                                                                                            hotel chains. Click on your
                                                                                            preferred hotel to enroll
                                                                                            and access rewards that
                                                                                            elevate your lifestyle!`) }}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--centurion-free-design paragraph--view-mode--default">
                                                                <div class="container">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-freedesign">
                                                                                <div class="row">
                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-free-design-text field--type-text-long field--label-hidden field__item">
                                                                                        <div class="freedesign-title">
                                                                                            {{ $t(`Elevate every stay
                                                                                            with exclusive
                                                                                            privileges`) }}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--centurion-image paragraph--view-mode--default">
                                                                <div class="container">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-image text-center">
                                                                                <div
                                                                                     class="field field--name-field-centurion-image field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/stay-exclusive-privileges.jpg`)"
                                                                                         width="1300"
                                                                                         height="484"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--centurion-free-design paragraph--view-mode--default">
                                                                <div class="container">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-freedesign">
                                                                                <div class="row">
                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-free-design-text field--type-text-long field--label-hidden field__item">
                                                                                        <div class="freedesign-text">
                                                                                            {{ $t(`Enjoy exclusive
                                                                                            upgrades and premium beneﬁts
                                                                                            across major global hotel
                                                                                            chains. Click on your
                                                                                            preferred hotel to enroll
                                                                                            and access rewards that
                                                                                            elevate your lifestyle!`) }}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--cent-travel-resorts-container paragraph--view-mode--default">
                                                                <div class="container">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-resort-cont">
                                                                                <div class="row">
                                                                                    <div
                                                                                         class="col-6 col-md-4 marginresort">
                                                                                        <div
                                                                                             class="paragraph paragraph--type--cent-travel-resort-items paragraph--view-mode--default">
                                                                                            <div class="h100">
                                                                                                <div
                                                                                                     class="row g-md-0 h100">
                                                                                                    <div
                                                                                                         class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                                        <div
                                                                                                             class="cent-resort-items">
                                                                                                            <div
                                                                                                                 class="row">
                                                                                                                <div
                                                                                                                     class="col-md-10 col-9">
                                                                                                                    <div
                                                                                                                         class="field field--name-field-resort-logo field--type-image field--label-hidden field__item">
                                                                                                                        <img :src="require(`@/assets/images/centurion/hilton-res.jpg`)"
                                                                                                                             width="146"
                                                                                                                             height="64"
                                                                                                                             alt=""
                                                                                                                             loading="lazy"
                                                                                                                             typeof="foaf:Image"
                                                                                                                             class="img-fluid" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                     class="col-md-2 col-3">
                                                                                                                    <a @click="opened = !opened"                                                                                                                       
                                                                                                                       id="centresortbtn"
                                                                                                                       class="cent-res-btn centresclosebtn">&nbsp;</a>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                     class="clearfix text-formatted field field--name-field-resort-name field--type-text field--label-hidden field__item">
                                                                                                                    {{
                                                                                                                        $t(`HILTON
                                                                                                                    HONORS
                                                                                                                    DIAMOND`)
                                                                                                                    }}
                                                                                                                </div>
                                                                                                                <div v-if="opened"
                                                                                                                style="display: block;"
                                                                                                                     class="clearfix text-formatted field field--name-field-resort-description field--type-text-long field__item"
                                                                                                              
                                                                                                                     >
                                                                                                                    <p>{{
                                                                                                                        $t(`Get
                                                                                                                        special
                                                                                                                        rewards
                                                                                                                        across
                                                                                                                        the
                                                                                                                        Hilton
                                                                                                                        portfolio
                                                                                                                        when
                                                                                                                        you
                                                                                                                        enroll
                                                                                                                        in
                                                                                                                        complimentary
                                                                                                                        Hilton
                                                                                                                        Honors
                                                                                                                        Diamond
                                                                                                                        Status.
                                                                                                                        Earn
                                                                                                                        rewards
                                                                                                                        nights
                                                                                                                        faster
                                                                                                                        with
                                                                                                                        a
                                                                                                                        100%
                                                                                                                        Bonus
                                                                                                                        on
                                                                                                                        all
                                                                                                                        Hilton
                                                                                                                        Honors
                                                                                                                        Base
                                                                                                                        Points
                                                                                                                        you
                                                                                                                        earn,
                                                                                                                        Enjoy
                                                                                                                        while-you-stay
                                                                                                                        amenities
                                                                                                                        like
                                                                                                                        space-available
                                                                                                                        room
                                                                                                                        upgrades
                                                                                                                        at
                                                                                                                        select
                                                                                                                        brands,
                                                                                                                        executive
                                                                                                                        floor
                                                                                                                        access,
                                                                                                                        and
                                                                                                                        premium
                                                                                                                        Wi-Fi.`)
                                                                                                                    }}
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                 class="row">
                                                                                                                <div
                                                                                                                     class="col-12 cent-resort-items-link">
                                                                                                                    <div
                                                                                                                         class="field field--name-field-resort-link field--type-link field--label-hidden field__item">
                                                                                                                        <a
                                                                                                                           href="https://www.americanexpress.com.sa/content/centurion-hotel-partners-en"  target="_blank">{{
                                                                                                                            $t(`enroll`)
                                                                                                                            }}</a>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                         class="col-6 col-md-4 marginresort">
                                                                                        <div
                                                                                             class="paragraph paragraph--type--cent-travel-resort-items paragraph--view-mode--default">
                                                                                            <div class="h100">
                                                                                                <div
                                                                                                     class="row g-md-0 h100">
                                                                                                    <div
                                                                                                         class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                                        <div
                                                                                                             class="cent-resort-items">
                                                                                                            <div
                                                                                                                 class="row">
                                                                                                                <div
                                                                                                                     class="col-md-10 col-9">
                                                                                                                    <div
                                                                                                                         class="field field--name-field-resort-logo field--type-image field--label-hidden field__item">
                                                                                                                        <img :src="require(`@/assets/images/centurion/ihg-res.jpg`)"
                                                                                                                             width="210"
                                                                                                                             height="64"
                                                                                                                             alt=""
                                                                                                                             loading="lazy"
                                                                                                                             typeof="foaf:Image"
                                                                                                                             class="img-fluid" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                     class="col-md-2 col-3">
                                                                                                                    <a @click="opened2 = !opened2"
                                                                                                                       id="centresortbtn"
                                                                                                                       class="cent-res-btn centresclosebtn">&nbsp;</a>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                     class="clearfix text-formatted field field--name-field-resort-name field--type-text field--label-hidden field__item">
                                                                                                                    {{
                                                                                                                        $t(`IHG
                                                                                                                    PLATINUM
                                                                                                                    ELITE`)
                                                                                                                    }}
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    v-if="opened2"
                                                                                                                    style="display: block;"
                                                                                                                     class="clearfix text-formatted field field--name-field-resort-description field--type-text-long field--label-hidden field__item">
                                                                                                                    <p>{{
                                                                                                                        $t(`Enjoy
                                                                                                                        personalized
                                                                                                                        touches
                                                                                                                        at
                                                                                                                        every
                                                                                                                        IHG
                                                                                                                        hotel
                                                                                                                        when
                                                                                                                        you
                                                                                                                        enroll
                                                                                                                        as
                                                                                                                        an
                                                                                                                        IHG®
                                                                                                                        Rewards
                                                                                                                        Club
                                                                                                                        Platinum
                                                                                                                        Elite
                                                                                                                        member.
                                                                                                                        You
                                                                                                                        will
                                                                                                                        get
                                                                                                                        amenities
                                                                                                                        and
                                                                                                                        extras
                                                                                                                        like
                                                                                                                        Priority
                                                                                                                        Check-in™
                                                                                                                        service,
                                                                                                                        room
                                                                                                                        upgrades,
                                                                                                                        and
                                                                                                                        a
                                                                                                                        50%
                                                                                                                        bonus
                                                                                                                        on
                                                                                                                        points,
                                                                                                                        an
                                                                                                                        extended
                                                                                                                        checkout,
                                                                                                                        and
                                                                                                                        even
                                                                                                                        complimentary
                                                                                                                        weekday
                                                                                                                        newspapers.`)
                                                                                                                    }}
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                 class="row">
                                                                                                                <div
                                                                                                                     class="col-12 cent-resort-items-link">
                                                                                                                    <div
                                                                                                                         class="field field--name-field-resort-link field--type-link field--label-hidden field__item">
                                                                                                                        <a
                                                                                                                           href="https://www.americanexpress.com.sa/content/centurion-hotel-partners-en"  target="_blank">{{
                                                                                                                            $t(`enroll`)
                                                                                                                            }}</a>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                         class="col-6 col-md-4 marginresort">
                                                                                        <div
                                                                                             class="paragraph paragraph--type--cent-travel-resort-items paragraph--view-mode--default">
                                                                                            <div class="h100">
                                                                                                <div
                                                                                                     class="row g-md-0 h100">
                                                                                                    <div
                                                                                                         class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                                        <div
                                                                                                             class="cent-resort-items">
                                                                                                            <div
                                                                                                                 class="row">
                                                                                                                <div
                                                                                                                     class="col-md-10 col-9">
                                                                                                                    <div
                                                                                                                         class="field field--name-field-resort-logo field--type-image field--label-hidden field__item">
                                                                                                                        <img :src="require(`@/assets/images/centurion/marriot-res.png`)"
                                                                                                                             width="162"
                                                                                                                             height="63"
                                                                                                                             alt=""
                                                                                                                             loading="lazy"
                                                                                                                             typeof="foaf:Image"
                                                                                                                             class="img-fluid" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                     class="col-md-2 col-3">
                                                                                                                    <a @click="opened3 = !opened3"
                                                                                                                       id="centresortbtn"
                                                                                                                       class="cent-res-btn centresclosebtn">&nbsp;</a>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                     class="clearfix text-formatted field field--name-field-resort-name field--type-text field--label-hidden field__item">
                                                                                                                    {{
                                                                                                                        $t(`MARRIOTT
                                                                                                                    BONVOY™
                                                                                                                    GOLD
                                                                                                                    ELITE`)
                                                                                                                    }}
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    v-if="opened3"
                                                                                                                    style="display: block;"
                                                                                                                     class="clearfix text-formatted field field--name-field-resort-description field--type-text-long field--label-hidden field__item">
                                                                                                                    <p>{{
                                                                                                                        $t(`ElevateYourTravel`)
                                                                                                                    }}
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                 class="row">
                                                                                                                <div
                                                                                                                     class="col-12 cent-resort-items-link">
                                                                                                                    <div
                                                                                                                         class="field field--name-field-resort-link field--type-link field--label-hidden field__item">
                                                                                                                        <a
                                                                                                                           href="https://www.americanexpress.com.sa/content/centurion-hotel-partners-en"  target="_blank">{{
                                                                                                                            $t(`enroll`)
                                                                                                                            }}</a>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                         class="col-6 col-md-4 marginresort">
                                                                                        <div
                                                                                             class="paragraph paragraph--type--cent-travel-resort-items paragraph--view-mode--default">
                                                                                            <div class="h100">
                                                                                                <div
                                                                                                     class="row g-md-0 h100">
                                                                                                    <div
                                                                                                         class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                                        <div
                                                                                                             class="cent-resort-items">
                                                                                                            <div
                                                                                                                 class="row">
                                                                                                                <div
                                                                                                                     class="col-md-10 col-9">
                                                                                                                    <div
                                                                                                                         class="field field--name-field-resort-logo field--type-image field--label-hidden field__item">
                                                                                                                        <img :src="require(`@/assets/images/centurion/jumeirah-res.png`)"
                                                                                                                             width="195"
                                                                                                                             height="62"
                                                                                                                             alt=""
                                                                                                                             loading="lazy"
                                                                                                                             typeof="foaf:Image"
                                                                                                                             class="img-fluid" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                     class="col-md-2 col-3">
                                                                                                                    <a @click="opened4 = !opened4"
                                                                                                                       id="centresortbtn"
                                                                                                                       class="cent-res-btn centresclosebtn">&nbsp;</a>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                     class="clearfix text-formatted field field--name-field-resort-name field--type-text field--label-hidden field__item">
                                                                                                                    {{
                                                                                                                        $t(`Jumeirah
                                                                                                                    One-
                                                                                                                    Gold`)
                                                                                                                    }}
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    v-if="opened4"
                                                                                                                     style="display: block;"
                                                                                                                     class="clearfix text-formatted field field--name-field-resort-description field--type-text-long field--label-hidden field__item">
                                                                                                                    <p>{{
                                                                                                                        $t(`As
                                                                                                                        a
                                                                                                                        Jumeirah
                                                                                                                        One
                                                                                                                        Member,
                                                                                                                        enjoy
                                                                                                                        more
                                                                                                                        of
                                                                                                                        life’s
                                                                                                                        pleasures
                                                                                                                        with
                                                                                                                        privileged
                                                                                                                        access
                                                                                                                        to
                                                                                                                        an
                                                                                                                        exclusive
                                                                                                                        global
                                                                                                                        lifestyle.
                                                                                                                        Expand
                                                                                                                        your
                                                                                                                        horizons
                                                                                                                        and
                                                                                                                        unlock
                                                                                                                        coveted
                                                                                                                        benefits
                                                                                                                        when
                                                                                                                        you
                                                                                                                        visit
                                                                                                                        Jumeirah
                                                                                                                        Hotels
                                                                                                                        &
                                                                                                                        Resorts.`)
                                                                                                                        }}
                                                                                                                        <br />{{
                                                                                                                            $t(`At
                                                                                                                        Jumeirah
                                                                                                                        One,
                                                                                                                        you
                                                                                                                        are
                                                                                                                        not
                                                                                                                        just
                                                                                                                        the
                                                                                                                        center
                                                                                                                        of
                                                                                                                        our
                                                                                                                        attention.
                                                                                                                        You
                                                                                                                        are
                                                                                                                        the
                                                                                                                        heart
                                                                                                                        of
                                                                                                                        it.`)
                                                                                                                        }}
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                 class="row">
                                                                                                                <div
                                                                                                                     class="col-12 cent-resort-items-link">
                                                                                                                    <div
                                                                                                                         class="field field--name-field-resort-link field--type-link field--label-hidden field__item">
                                                                                                                        <a
                                                                                                                           href="https://www.americanexpress.com.sa/content/centurion-hotel-partners-en"  target="_blank">{{
                                                                                                                            $t(`enroll`)
                                                                                                                            }}</a>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                         class="col-6 col-md-4 marginresort">
                                                                                        <div
                                                                                             class="paragraph paragraph--type--cent-travel-resort-items paragraph--view-mode--default">
                                                                                            <div class="h100">
                                                                                                <div
                                                                                                     class="row g-md-0 h100">
                                                                                                    <div
                                                                                                         class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                                        <div
                                                                                                             class="cent-resort-items">
                                                                                                            <div
                                                                                                                 class="row">
                                                                                                                <div
                                                                                                                     class="col-md-10 col-9">
                                                                                                                    <div
                                                                                                                         class="field field--name-field-resort-logo field--type-image field--label-hidden field__item">
                                                                                                                        <img :src="require(`@/assets/images/centurion/melia-res.png`)"
                                                                                                                             width="168"
                                                                                                                             height="63"
                                                                                                                             alt=""
                                                                                                                             loading="lazy"
                                                                                                                             typeof="foaf:Image"
                                                                                                                             class="img-fluid" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                     class="col-md-2 col-3">
                                                                                                                    <a @click="opened5 = !opened5"
                                                                                                                       id="centresortbtn"
                                                                                                                       class="cent-res-btn centresclosebtn">&nbsp;</a>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                     class="clearfix text-formatted field field--name-field-resort-name field--type-text field--label-hidden field__item">
                                                                                                                    {{
                                                                                                                        $t(`Meliá
                                                                                                                    Rewards
                                                                                                                    -
                                                                                                                    Platinum`)
                                                                                                                    }}
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    v-if="opened5"
                                                                                                                    style="display: block;"
                                                                                                                     class="clearfix text-formatted field field--name-field-resort-description field--type-text-long field--label-hidden field__item">
                                                                                                                    <p>{{
                                                                                                                        $t(`Meliá
                                                                                                                        Rewards
                                                                                                                        is
                                                                                                                        the
                                                                                                                        Meliá
                                                                                                                        Hotels
                                                                                                                        International
                                                                                                                        loyalty
                                                                                                                        program,
                                                                                                                        where
                                                                                                                        you
                                                                                                                        can
                                                                                                                        enjoy
                                                                                                                        benefits
                                                                                                                        and
                                                                                                                        exclusive
                                                                                                                        offers.
                                                                                                                        Belonging
                                                                                                                        to
                                                                                                                        Meliá
                                                                                                                        Rewards
                                                                                                                        means
                                                                                                                        more:
                                                                                                                        more
                                                                                                                        value,
                                                                                                                        because
                                                                                                                        you
                                                                                                                        enjoy
                                                                                                                        points,
                                                                                                                        surprises
                                                                                                                        and
                                                                                                                        discounts;
                                                                                                                        more
                                                                                                                        opportunities,
                                                                                                                        because
                                                                                                                        you
                                                                                                                        can
                                                                                                                        earn
                                                                                                                        and
                                                                                                                        redeem
                                                                                                                        points
                                                                                                                        in
                                                                                                                        a
                                                                                                                        thousand
                                                                                                                        ways;
                                                                                                                        and
                                                                                                                        more
                                                                                                                        time,
                                                                                                                        because
                                                                                                                        you
                                                                                                                        are
                                                                                                                        our
                                                                                                                        priority.`)
                                                                                                                    }}
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                 class="row">
                                                                                                                <div
                                                                                                                     class="col-12 cent-resort-items-link">
                                                                                                                    <div
                                                                                                                         class="field field--name-field-resort-link field--type-link field--label-hidden field__item">
                                                                                                                        <a
                                                                                                                           href="https://www.americanexpress.com.sa/content/centurion-hotel-partners-en"  target="_blank">{{
                                                                                                                            $t(`enroll`)
                                                                                                                            }}</a>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                         class="col-6 col-md-4 marginresort">
                                                                                        <div
                                                                                             class="paragraph paragraph--type--cent-travel-resort-items paragraph--view-mode--default">
                                                                                            <div class="h100">
                                                                                                <div
                                                                                                     class="row g-md-0 h100">
                                                                                                    <div
                                                                                                         class="col-12 mt-3 mt-md-0 p-md-0">
                                                                                                        <div
                                                                                                             class="cent-resort-items">
                                                                                                            <div
                                                                                                                 class="row">
                                                                                                                <div
                                                                                                                     class="col-md-10 col-9">
                                                                                                                    <div
                                                                                                                         class="field field--name-field-resort-logo field--type-image field--label-hidden field__item">
                                                                                                                        <img :src="require(`@/assets/images/centurion/radisson-res.png`)"
                                                                                                                             width="164"
                                                                                                                             height="62"
                                                                                                                             alt=""
                                                                                                                             loading="lazy"
                                                                                                                             typeof="foaf:Image"
                                                                                                                             class="img-fluid" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                     class="col-md-2 col-3">
                                                                                                                    <a @click="opened6 = !opened6"
                                                                                                                       id="centresortbtn"
                                                                                                                       class="cent-res-btn centresclosebtn">&nbsp;</a>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                     class="clearfix text-formatted field field--name-field-resort-name field--type-text field--label-hidden field__item">
                                                                                                                    {{
                                                                                                                        $t(`Radisson
                                                                                                                    Rewards-
                                                                                                                    Premium`)
                                                                                                                    }}
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    style="display: block;"
                                                                                                                    v-if="opened6"
                                                                                                                     class="clearfix text-formatted field field--name-field-resort-description field--type-text-long field--label-hidden field__item">
                                                                                                                    <p>{{
                                                                                                                        $t(`Radisson
                                                                                                                        Rewards
                                                                                                                        is
                                                                                                                        designed
                                                                                                                        to
                                                                                                                        guarantee
                                                                                                                        memorable
                                                                                                                        moments
                                                                                                                        by
                                                                                                                        rewarding
                                                                                                                        your
                                                                                                                        loyalty
                                                                                                                        with
                                                                                                                        truly
                                                                                                                        relevant
                                                                                                                        benefits
                                                                                                                        every
                                                                                                                        day.
                                                                                                                        As
                                                                                                                        a
                                                                                                                        Radisson
                                                                                                                        Rewards
                                                                                                                        member,
                                                                                                                        you
                                                                                                                        unlock
                                                                                                                        a
                                                                                                                        world
                                                                                                                        of
                                                                                                                        exclusive
                                                                                                                        benefits
                                                                                                                        across
                                                                                                                        a
                                                                                                                        wide
                                                                                                                        range
                                                                                                                        of
                                                                                                                        our
                                                                                                                        hotels
                                                                                                                        and
                                                                                                                        destinations.`)
                                                                                                                    }}
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                 class="row">
                                                                                                                <div
                                                                                                                     class="col-12 cent-resort-items-link">
                                                                                                                    <div
                                                                                                                         class="field field--name-field-resort-link field--type-link field--label-hidden field__item">
                                                                                                                        <a
                                                                                                                           href="https://www.americanexpress.com.sa/content/centurion-hotel-partners-en"  target="_blank">{{
                                                                                                                            $t(`enroll`)
                                                                                                                            }}</a>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--centurion-free-design paragraph--view-mode--default">
                                                                <div class="container">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-freedesign">
                                                                                <div class="row">
                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-free-design-text field--type-text-long field--label-hidden field__item">
                                                                                        <div class="disclaimer">
                                                                                            <ul>
                                                                                                <li>{{ $t(`Membership
                                                                                                    cannot be used by
                                                                                                    any other parties or
                                                                                                    transferred.`) }}
                                                                                                </li>
                                                                                                <li>{{ $t(`The program
                                                                                                    is
                                                                                                    valid for primary
                                                                                                    Centurion
                                                                                                    Cardmembers.`) }}
                                                                                                </li>
                                                                                                <li>{{ $t(`All
                                                                                                    membership
                                                                                                    tiers and benefits
                                                                                                    are subject to
                                                                                                    change without prior
                                                                                                    notice by the hotel
                                                                                                    partners.`) }}</li>
                                                                                                <li>{{ $t(`Centurion
                                                                                                    Cardmembers who have
                                                                                                    already upgraded
                                                                                                    their tier are not
                                                                                                    eligible for this
                                                                                                    benefit.`) }}</li>
                                                                                                <li>{{ $t(`Terms and
                                                                                                    conditions of hotel
                                                                                                    partners apply.`) }}
                                                                                                </li>
                                                                                                <li>{{ $t(`The highest
                                                                                                    tier status is
                                                                                                    offered for the
                                                                                                    first year, and
                                                                                                    hotel partners'
                                                                                                    terms and conditions
                                                                                                    apply to maintain
                                                                                                    the same tier
                                                                                                    status.`) }}</li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--centurion-free-design paragraph--view-mode--default">
                                                                <div class="container">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-freedesign">
                                                                                <div class="row"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--centurion-image-with-text-icons paragraph--view-mode--default cent-bg-">
                                                                <div class="container">
                                                                    <div class="row g-md-0">
                                                                        <div
                                                                             class="col-12 col-md-6 mt-3 mt-md-0 p-md-0 d-flex align-items-md-center flex-column position-relative">
                                                                            <div class="cent-imgwithtxt-icons-image">
                                                                                <div
                                                                                     class="field field--name-field-image-with-text-icons-img field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/travel-ofs.jpg`)"
                                                                                         width="499"
                                                                                         height="568"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                             class="col-12 col-md-6 mt-3 mt-md-0 p-md-0 d-flex align-self-md-center flex-column">
                                                                            <div class="cent-imgwithtxt-icons-text">
                                                                                <div
                                                                                     class="clearfix text-formatted field field--name-field-image-with-text-icon-title field--type-text-long field--label-hidden field__item">
                                                                                    <p>
                                                                                        <img alt=""
                                                                                             class="img-fluid"
                                                                                             :src="require(`@/assets/images/centurion/travel-ofs-logo.png`)" />
                                                                                    </p>
                                                                                    <p>{{ $t(`Enjoy $300 off exclusive
                                                                                        onefinestay rentals, featuring
                                                                                        luxury apartments, villas, and
                                                                                        personalized concierge
                                                                                        services`) }}</p>
                                                                                </div>
                                                                                <div
                                                                                     class="clearfix text-formatted field field--name-field-image-with-text-icons-text field--type-text-long field--label-hidden field__items">
                                                                                    <div class="field__item">
                                                                                        <p>{{ $t(`Enjoy an upfront
                                                                                            discount of USD 300 on the
                                                                                            world’s most exclusive
                                                                                            vacation rentals, tailored
                                                                                            to create unforgettable
                                                                                            memories. Choose from a
                                                                                            selection of spacious
                                                                                            apartments, beachfront
                                                                                            villas, and countryside
                                                                                            mansions in 50 premier
                                                                                            destinations worldwide with
                                                                                            onefinestay. Revel in
                                                                                            comfort and privacy while
                                                                                            enjoying personalized
                                                                                            concierge services and
                                                                                            unique experiences. Book a
                                                                                            minimum of 7 nights through
                                                                                            Premium Travel Services
                                                                                            using your Centurion Card to
                                                                                            enjoy exclusive savings of
                                                                                            USD300 and personalized
                                                                                            concierge services.`) }}
                                                                                            {{ $t(`Click`) }}
                                                                                            <a
                                                                                               href="http://www.onefinestay.com/">{{
                                                                                                $t(`here`) }}</a>
                                                                                            {{ $t(`for
                                                                                            more details. Terms and
                                                                                            condition Apply.`) }}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="field__item">
                                                <div
                                                     class="paragraph paragraph--type--cent-scrolling-section paragraph--view-mode--default">
                                                    <a id="carrentals"></a>
                                                    <div
                                                         class="field field--name-field-scrolling-section-item field--type-entity-reference-revisions field--label-hidden field__items">
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--cent-car-rental paragraph--view-mode--default">
                                                                <div class="container">
                                                                    <div class="row g-md-0 black-bg">
                                                                        <div class="col-12 travelcarrental">
                                                                            <div
                                                                                 class="field field--name-field-car-rental-banner field--type-image field--label-hidden field__item">
                                                                                <img :src="require(`@/assets/images/centurion/carrental-banner.jpg`)"
                                                                                     width="1300"
                                                                                     height="550"
                                                                                     alt=""
                                                                                     loading="lazy"
                                                                                     typeof="foaf:Image"
                                                                                     class="img-fluid" />
                                                                            </div>
                                                                            <div
                                                                                 class="clearfix text-formatted field field--name-field-car-rental-title field--type-text-long field--label-hidden field__item">
                                                                                <p>{{ $t(`Elevate your journey with the
                                                                                    premium car rental benefits with
                                                                                    Your Centurion Card.`) }}</p>
                                                                            </div>
                                                                            <div
                                                                                 class="clearfix text-formatted field field--name-field-car-rental-text field--type-text-long field--label-hidden field__item">
                                                                                <p>{{ $t(`When you travel,
                                                                                    transportation
                                                                                    should be the least of your worries.
                                                                                    Enjoy exclusive privileges with
                                                                                    trusted global partners like Sixt,
                                                                                    Hertz, and Avis. Experience
                                                                                    complimentary premium status,
                                                                                    enhanced perks, and special
                                                                                    discounts that ensure every ride is
                                                                                    exceptional. Let us take care of the
                                                                                    details, so you can focus on the
                                                                                    adventure ahead!`) }}</p>
                                                                            </div>
                                                                            <div
                                                                                 class="row justify-content-md-center carrentalitems">
                                                                                <div
                                                                                     class="col-12 col-md-4 text-center">
                                                                                    <div
                                                                                         class="paragraph paragraph--type--cent-car-rental-list paragraph--view-mode--default">
                                                                                        <div id="centcarrentbtn"
                                                                                             class="cent-car-btn centcarclosebtn">
                                                                                            <div
                                                                                                 style="cursor: pointer;"
                                                                                                 class="field field--name-field-rental-item-logo field--type-image field--label-hidden field__item"
                                                                                                 @click="opened7 = !opened7"
                                                                                                 >
                                                                                                <img :src="require(`@/assets/images/centurion/travel-avis.jpg`)"
                                                                                                     width="126"
                                                                                                     height="65"
                                                                                                     alt=""
                                                                                                     loading="lazy"
                                                                                                     typeof="foaf:Image"
                                                                                                     class="img-fluid"
                                                                                                    
                                                                                                      />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                             class="clearfix text-formatted field field--name-field-rental-item-title field--type-text field--label-hidden field__item">
                                                                                            {{ $t(`Presidents Club`) }}
                                                                                        </div>
                                                                                        <div
                                                                                            v-if="opened7"
                                                                                            style="display: block;"
                                                                                             class="clearfix text-formatted field field--name-field-rental-item-text field--type-text-long field--label-hidden field__item">
                                                                                            <ul>
                                                                                                <li>{{ $t(`Up to 15%
                                                                                                    discount on base
                                                                                                    rates worldwide
                                                                                                    (excludes select
                                                                                                    locations).`) }}
                                                                                                </li>
                                                                                                <li>{{ $t(`Applies to
                                                                                                    inbound, outbound,
                                                                                                    and domestic rates;
                                                                                                    extras like
                                                                                                    insurance and fuel
                                                                                                    are excluded.`) }}
                                                                                                </li>
                                                                                                <li>{{ $t(`Complimentary
                                                                                                    one-class upgrade
                                                                                                    with a voucher from
                                                                                                    the Avis link
                                                                                                    (subject to
                                                                                                    availability).`) }}
                                                                                                </li>
                                                                                                <li>{{ $t(`Discount may
                                                                                                    not be valid during
                                                                                                    peak periods.`) }}
                                                                                                </li>
                                                                                                <li>{{ $t(`Voucher must
                                                                                                    be presented at
                                                                                                    pick-up.`) }}</li>
                                                                                                <li>{{ $t(`Cannot be
                                                                                                    combined with other
                                                                                                    offers; standard
                                                                                                    Avis terms and
                                                                                                    conditions
                                                                                                    apply.`) }}</li>
                                                                                                <li>{{ $t(`Use
                                                                                                    "Centurion" as the
                                                                                                    passcode and select
                                                                                                    “Leisure” as the
                                                                                                    account type if
                                                                                                    requested. Status
                                                                                                    updates may take up
                                                                                                    to 72 hours.
                                                                                                    Existing members
                                                                                                    should use their
                                                                                                    email or loyalty
                                                                                                    number.`) }}</li>
                                                                                            </ul>
                                                                                        </div>
                                                                                        <div
                                                                                             class="field field--name-field-rental-item-link field--type-link field--label-hidden field__item">
                                                                                            <a
                                                                                               href="https://member.avisworld.com/"  target="_blank">{{
                                                                                                $t(`Enroll
                                                                                                Here`) }}</a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                     class="col-12 col-md-4 text-center">
                                                                                    <div
                                                                                         class="paragraph paragraph--type--cent-car-rental-list paragraph--view-mode--default">
                                                                                        <div id="centcarrentbtn"
                                                                                             class="cent-car-btn centcarclosebtn">
                                                                                            <div
                                                                                                @click="opened8 = !opened8"
                                                                                                style="cursor: pointer;"
                                                                                                 class="field field--name-field-rental-item-logo field--type-image field--label-hidden field__item">
                                                                                                <img :src="require(`@/assets/images/centurion/travel-hertz.jpg`)"
                                                                                                     width="126"
                                                                                                     height="65"
                                                                                                     alt=""
                                                                                                     loading="lazy"
                                                                                                     typeof="foaf:Image"
                                                                                                     class="img-fluid" />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                             class="clearfix text-formatted field field--name-field-rental-item-title field--type-text field--label-hidden field__item">
                                                                                            {{ $t(`Gold Plus Rewards`)
                                                                                            }}
                                                                                        </div>
                                                                                        <div
                                                                                            style="display: block;"
                                                                                            v-if="opened8"
                                                                                             class="clearfix text-formatted field field--name-field-rental-item-text field--type-text-long field--label-hidden field__item">
                                                                                            <ul>
                                                                                                <li>{{ $t(`Complimentary
                                                                                                    one-class upgrade
                                                                                                    (subject to
                                                                                                    availability).`) }}
                                                                                                </li>
                                                                                                <li>{{ $t(`Free
                                                                                                    additional driver
                                                                                                    for spouse/civil
                                                                                                    partner.`) }}</li>
                                                                                                <li>{{ $t(`Skip the
                                                                                                    counter at major
                                                                                                    airports and 1,000+
                                                                                                    locations
                                                                                                    worldwide.`) }}</li>
                                                                                                <li>{{ $t(`Earn 10%
                                                                                                    bonus
                                                                                                    points and 900
                                                                                                    points after 10
                                                                                                    rentals (Reward
                                                                                                    Day).`) }}</li>
                                                                                                <li>{{ $t(`Fee-waived
                                                                                                    4-hour return grace
                                                                                                    period (varies by
                                                                                                    location).`) }}</li>
                                                                                                <li>{{ $t(`Up to 15% off
                                                                                                    worldwide
                                                                                                    rentals.`) }}</li>
                                                                                            </ul>
                                                                                        </div>
                                                                                        <div
                                                                                             class="field field--name-field-rental-item-link field--type-link field--label-hidden field__item">
                                                                                            <a
                                                                                               href="https://www.hertz.com/rentacar/misc/index.jsp?targetPage=american_express_platinum_members.jsp"  target="_blank">{{
                                                                                                $t(`Enroll
                                                                                                Here`) }}</a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                     class="col-12 col-md-4 text-center">
                                                                                    <div
                                                                                         class="paragraph paragraph--type--cent-car-rental-list paragraph--view-mode--default">
                                                                                        <div id="centcarrentbtn"
                                                                                             class="cent-car-btn centcarclosebtn">
                                                                                            <div
                                                                                                @click="opened9 = !opened9"
                                                                                                 style="cursor: pointer;"
                                                                                                 class="field field--name-field-rental-item-logo field--type-image field--label-hidden field__item">
                                                                                                <img :src="require(`@/assets/images/centurion/travel-sixt.jpg`)"
                                                                                                     width="126"
                                                                                                     height="65"
                                                                                                     alt=""
                                                                                                     loading="lazy"
                                                                                                     typeof="foaf:Image"
                                                                                                     class="img-fluid" />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                             class="clearfix text-formatted field field--name-field-rental-item-title field--type-text field--label-hidden field__item">
                                                                                            {{ $t(`Diamond`) }}
                                                                                        </div>
                                                                                        <div
                                                                                            v-if="opened9"
                                                                                             style="display: block;"
                                                                                             class="clearfix text-formatted field field--name-field-rental-item-text field--type-text-long field--label-hidden field__item">
                                                                                            <ul>
                                                                                                <li>{{ $t(`Access to
                                                                                                    exclusive counters,
                                                                                                    parking, and Diamond
                                                                                                    lounges with VIP
                                                                                                    services.`) }}</li>
                                                                                                <li>{{ $t(`Up to 20%
                                                                                                    discount on eligible
                                                                                                    rates (local
                                                                                                    availability
                                                                                                    applies).`) }}</li>
                                                                                                <li>{{ $t(`Free
                                                                                                    additional driver
                                                                                                    for U.S. residents
                                                                                                    (U.S. rentals
                                                                                                    only).`) }}</li>
                                                                                                <li>{{ $t(`Complimentary
                                                                                                    car upgrade
                                                                                                    (availability and
                                                                                                    restrictions
                                                                                                    apply).`) }}</li>
                                                                                                <li>{{ $t(`Guaranteed
                                                                                                    upgrade for eligible
                                                                                                    rentals.`) }}</li>
                                                                                                <li>{{ $t(`Discounts and
                                                                                                    upgrades apply to
                                                                                                    short-term rentals;
                                                                                                    excludes Sports,
                                                                                                    Luxury, and
                                                                                                    trucks.`) }}</li>
                                                                                                <li>{{ $t(`Select Saudi
                                                                                                    Arabia as your
                                                                                                    country and use your
                                                                                                    Centurion card
                                                                                                    number. If already
                                                                                                    enrolled, please
                                                                                                    re-enroll with a
                                                                                                    different email
                                                                                                    address.`) }}</li>
                                                                                            </ul>
                                                                                        </div>
                                                                                        <div
                                                                                             class="field field--name-field-rental-item-link field--type-link field--label-hidden field__item">
                                                                                            <a
                                                                                               href="https://www.sixt.com.sa/b2bregistration?b2b_token=ee8f5ae2-6fde-aab8-d2fe-b28e47e8c5be"  target="_blank">{{
                                                                                                $t(`Enroll
                                                                                                Here`) }}</a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--centurion-free-design paragraph--view-mode--default">
                                                                <div class="container">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-freedesign">
                                                                                <div class="row">
                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-free-design-text field--type-text-long field--label-hidden field__item">
                                                                                        <div class="disclaimer">
                                                                                            <ul>
                                                                                                <li>{{ $t(`Memberships
                                                                                                    cannot be used by
                                                                                                    others or
                                                                                                    transferred.`) }}
                                                                                                </li>
                                                                                                <li>{{ $t(`The program
                                                                                                    is
                                                                                                    valid only for
                                                                                                    primary Centurion
                                                                                                    Cardmember.`) }}
                                                                                                </li>
                                                                                                <li>{{ $t(`Centurion
                                                                                                    Cardmembers who have
                                                                                                    already upgraded
                                                                                                    their tier are not
                                                                                                    eligible for this
                                                                                                    benefit.`) }}</li>
                                                                                                <li>{{ $t(`All
                                                                                                    membership
                                                                                                    tiers and benefits
                                                                                                    are subject to
                                                                                                    change without prior
                                                                                                    notice.`) }}</li>
                                                                                                <li>{{ $t(`Terms and
                                                                                                    conditions of
                                                                                                    partners apply.`) }}
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="field__item">
                                                <div
                                                     class="paragraph paragraph--type--cent-scrolling-section paragraph--view-mode--default">
                                                    <a id="tanfeethi"></a>
                                                    <div
                                                         class="field field--name-field-scrolling-section-item field--type-entity-reference-revisions field--label-hidden field__items">
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--centurion-image-with-text-icons paragraph--view-mode--default cent-bg-">
                                                                <div class="container">
                                                                    <div class="row g-md-0">
                                                                        <div
                                                                             class="col-12 col-md-6 mt-3 mt-md-0 p-md-0 d-flex align-items-md-center flex-column position-relative">
                                                                            <div class="cent-imgwithtxt-icons-image">
                                                                                <div
                                                                                     class="field field--name-field-image-with-text-icons-img field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/new-tanfeethi.jpg`)"
                                                                                         width="500"
                                                                                         height="568"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                             class="col-12 col-md-6 mt-3 mt-md-0 p-md-0 d-flex align-self-md-center flex-column">
                                                                            <div class="cent-imgwithtxt-icons-text">
                                                                                <div
                                                                                     class="clearfix text-formatted field field--name-field-image-with-text-icon-title field--type-text-long field--label-hidden field__item">
                                                                                    <p>{{ $t(`Elevate Your Travel with
                                                                                        ALTANFEETHI – Where Luxury Meets
                                                                                        Convenience`) }}</p>
                                                                                </div>
                                                                                <div
                                                                                     class="clearfix text-formatted field field--name-field-image-with-text-icons-text field--type-text-long field--label-hidden field__items">
                                                                                    <div class="field__item">
                                                                                        <p>{{ $t(`Revolutionize your
                                                                                            journey with ALTANFEETHI,
                                                                                            the epitome of elegance and
                                                                                            efficiency. From seamless
                                                                                            check-in to personalized
                                                                                            assistance and expedited
                                                                                            security processes, their
                                                                                            exclusive terminal services
                                                                                            ensure your travel is as
                                                                                            effortless as it is
                                                                                            luxurious. Say goodbye to
                                                                                            long queues and hello to
                                                                                            premium lounges designed to
                                                                                            make your pre-flight
                                                                                            experience as memorable as
                                                                                            your destination.`) }}</p>
                                                                                        <p>{{ $t(`Exclusively for
                                                                                            Centurion Cardmembers We are
                                                                                            thrilled to reintroduce the
                                                                                            ALTANFEETHI Terminals
                                                                                            Membership Program, crafted
                                                                                            to bring unparalleled luxury
                                                                                            and convenience. Unlock up
                                                                                            to 20% savings on family
                                                                                            memberships for both new
                                                                                            sign-ups and renewals and
                                                                                            indulge in a host of premium
                                                                                            privileges.`) }}</p>
                                                                                        <p>{{ $t(`Join now to experience
                                                                                            the perfect blend of style,
                                                                                            comfort, and efficiency. To
                                                                                            register, simply contact
                                                                                            your Centurion Relationship
                                                                                            Manager or call`) }}
                                                                                            <a href="tel:800 122 5225">{{
                                                                                                $t(`800
                                                                                                122
                                                                                                5225`) }}</a>{{ $t(`.`)
                                                                                            }}
                                                                                            <br />{{ $t(`Offer
                                                                                            valid from December 11,
                                                                                            2024, to November 4, 2026.
                                                                                            T&Cs apply.`) }}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div class="field__item">
                                                                                        <div class="d-none">
                                                                                            <div
                                                                                                 class="row image-txticons-margin">
                                                                                                <div class="col-2">
                                                                                                    <div
                                                                                                         class="image-txticons-icons">
                                                                                                        <img alt=""
                                                                                                             class="img-fluid"
                                                                                                             :src="require(`@/assets/images/centurion/exclusive.jpg`)" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-10">
                                                                                                    <div
                                                                                                         class="image-txticons-txt">
                                                                                                        <span>{{
                                                                                                            $t(`Join
                                                                                                            Al Tanfeethi
                                                                                                            with your
                                                                                                            Centurion
                                                                                                            Card
                                                                                                            and`) }}
                                                                                                            <strong>{{
                                                                                                                $t(`unlock
                                                                                                                exclusive
                                                                                                                20%
                                                                                                                savings
                                                                                                                on
                                                                                                                family
                                                                                                                packages`)
                                                                                                                }}</strong>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                 class="row image-txticons-margin">
                                                                                                <div class="col-2">
                                                                                                    <div
                                                                                                         class="image-txticons-icons">
                                                                                                        <img alt=""
                                                                                                             class="img-fluid"
                                                                                                             :src="require(`@/assets/images/centurion/airplane-logo.jpg`)" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-10">
                                                                                                    <div
                                                                                                         class="image-txticons-txt">
                                                                                                        <span>
                                                                                                            <strong>{{
                                                                                                                $t(`Experience
                                                                                                                the
                                                                                                                perfect
                                                                                                                blend`)
                                                                                                                }}</strong>{{
                                                                                                                    $t(`of
                                                                                                            luxury and
                                                                                                            eﬃciency on
                                                                                                            your next
                                                                                                            trip`) }}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="field__item">
                                                <div
                                                     class="paragraph paragraph--type--cent-scrolling-section paragraph--view-mode--default">
                                                    <a id="lounge"></a>
                                                    <div
                                                         class="field field--name-field-scrolling-section-item field--type-entity-reference-revisions field--label-hidden field__items">
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--centurion-free-design paragraph--view-mode--default">
                                                                <div class="container">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-freedesign">
                                                                                <div class="row">
                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-free-design-text field--type-text-long field--label-hidden field__item">
                                                                                        <div class="lounge-freedesign">
                                                                                            <div
                                                                                                 class="freedesign-title">
                                                                                                {{ $t(`Exclusive Access
                                                                                                to a World of Luxury
                                                                                                Lounges.`) }}
                                                                                                <br />{{ $t(`The
                                                                                                American Express Global
                                                                                                Lounge Collection®`) }}
                                                                                            </div>
                                                                                            <div class="freedesign-img">
                                                                                                <img alt=""
                                                                                                     class="img-fluid"
                                                                                                     :src="require(`@/assets/images/centurion/luxury-lounge-1.jpg`)" />
                                                                                            </div>
                                                                                            <div
                                                                                                 class="freedesign-text">
                                                                                                {{ $t(`Experience
                                                                                                unparalleled access with
                                                                                                the American Express
                                                                                                Global Lounge
                                                                                                Collection®, crafted
                                                                                                exclusively for
                                                                                                Centurion Cardmembers.
                                                                                                Enjoy two complimentary
                                                                                                annual Priority Pass
                                                                                                memberships with
                                                                                                unlimited access—one for
                                                                                                you as the primary
                                                                                                cardmember and one for
                                                                                                the Centurion
                                                                                                supplementary cardholder
                                                                                                of your choice. Access
                                                                                                over 1,600 airport
                                                                                                lounges across 140
                                                                                                countries, including
                                                                                                premium spaces in The
                                                                                                Centurion® Network,
                                                                                                featuring Centurion
                                                                                                Lounges and Delta Sky
                                                                                                Clubs.`) }}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--centurion-free-design paragraph--view-mode--default">
                                                                <div class="container">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-freedesign">
                                                                                <div class="row">
                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-free-design-text field--type-text-long field--label-hidden field__item">
                                                                                        <div class="loungecont">
                                                                                            <div class="col-12">
                                                                                                <div
                                                                                                     class="loungetitle">
                                                                                                    {{ $t(`Begin every
                                                                                                    journey in luxury
                                                                                                    with your Centurion
                                                                                                    Card.`) }}
                                                                                                </div>
                                                                                                <div
                                                                                                     class="loungeimage">
                                                                                                    <img alt=""
                                                                                                         class="img-fluid"
                                                                                                         :src="require(`@/assets/images/centurion/priority-pass.jpg`)" />
                                                                                                </div>
                                                                                                <div
                                                                                                     class="loungeimgtitle">
                                                                                                    {{ $t(`Priority
                                                                                                    Pass™
                                                                                                    lounges`) }}
                                                                                                </div>
                                                                                                <div class="loungetext">
                                                                                                    <p>{{ $t(`Your
                                                                                                        Centurion Card
                                                                                                        grants access to
                                                                                                        over 1,600
                                                                                                        airport lounges
                                                                                                        worldwide with
                                                                                                        your Priority
                                                                                                        Pass membership.
                                                                                                        Follow these
                                                                                                        steps for a
                                                                                                        seamless
                                                                                                        experience:`) }}
                                                                                                    </p>
                                                                                                    <ol>
                                                                                                        <li>{{
                                                                                                            $t(`Activate
                                                                                                            your
                                                                                                            membership
                                                                                                            at
                                                                                                            PriorityPass.com`)
                                                                                                            }}
                                                                                                        </li>
                                                                                                        <li>{{
                                                                                                            $t(`Access
                                                                                                            your digital
                                                                                                            card in the
                                                                                                            Priority
                                                                                                            Pass
                                                                                                            app.`) }}
                                                                                                        </li>
                                                                                                        <li>{{
                                                                                                            $t(`Present
                                                                                                            your digital
                                                                                                            membership
                                                                                                            card at the
                                                                                                            lounge and
                                                                                                            enjoy your
                                                                                                            trip with
                                                                                                            American
                                                                                                            Express`) }}
                                                                                                        </li>
                                                                                                    </ol>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-12 text-center">
                                                                                            <p></p>
                                                                                            <p>
                                                                                                <strong>{{ $t(`To
                                                                                                    receive
                                                                                                    your unique
                                                                                                    enrollment code,
                                                                                                    please contact your
                                                                                                    Centurion
                                                                                                    Relationship Manager
                                                                                                    or on`) }}
                                                                                                    <a
                                                                                                       href="tel:8001225225">{{
                                                                                                        $t(`8001225225`)
                                                                                                        }}</a>
                                                                                                </strong>
                                                                                            </p>
                                                                                            <div class="loungelink">
                                                                                                <a href="https://www.prioritypass.com/centurion"
                                                                                                   target="_blank">{{
                                                                                                    $t(`Enroll
                                                                                                    Here`) }}</a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--centurion-free-design paragraph--view-mode--default">
                                                                <div class="container">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-freedesign">
                                                                                <div class="row">
                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-free-design-text field--type-text-long field--label-hidden field__item">
                                                                                        <table>
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <th colspan="2">
                                                                                                        {{ $t(`AMERICAN
                                                                                                        EXPRESS GLOBAL
                                                                                                        LOUNGE
                                                                                                        ACCESS`) }}
                                                                                                    </th>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td colspan="2">
                                                                                                        <p>
                                                                                                            <strong>{{
                                                                                                                $t(`International
                                                                                                                American
                                                                                                                Express
                                                                                                                Lounges`)
                                                                                                                }}</strong>
                                                                                                            <br />
                                                                                                            {{
                                                                                                                $t(`Explore
                                                                                                            the
                                                                                                            International
                                                                                                            American
                                                                                                            Express
                                                                                                            Lounges,
                                                                                                            including
                                                                                                            the renowned
                                                                                                            Centurion
                                                                                                            Lounges, as
                                                                                                            part of the
                                                                                                            American
                                                                                                            Express
                                                                                                            Global
                                                                                                            Lounge
                                                                                                            Collection.
                                                                                                            These
                                                                                                            lounges are
                                                                                                            available in
                                                                                                            select
                                                                                                            countries
                                                                                                            worldwide,
                                                                                                            offering
                                                                                                            premium
                                                                                                            amenities to
                                                                                                            enhance your
                                                                                                            travel
                                                                                                            experience.`)
                                                                                                            }}
                                                                                                        </p>
                                                                                                        <p>{{ $t(`For
                                                                                                            more details
                                                                                                            and a full
                                                                                                            list of
                                                                                                            locations,
                                                                                                            visit:`) }}
                                                                                                            <a
                                                                                                               href="https://thecenturionlounge.com/"  target="_blank">{{
                                                                                                                $t(`thecenturionlounge.com`)
                                                                                                                }}</a>
                                                                                                        </p>
                                                                                                        <p>{{
                                                                                                            $t(`Centurion
                                                                                                            Members may
                                                                                                            bring either
                                                                                                            their
                                                                                                            immediate
                                                                                                            family
                                                                                                            members
                                                                                                            (includes a
                                                                                                            spouse or
                                                                                                            domestic
                                                                                                            partner and
                                                                                                            their
                                                                                                            children
                                                                                                            under 18) as
                                                                                                            complimentary
                                                                                                            OR up to two
                                                                                                            (2) guests
                                                                                                            as
                                                                                                            complimentary
                                                                                                            into The
                                                                                                            Centurion
                                                                                                            Lounge. Card
                                                                                                            Members must
                                                                                                            accompany
                                                                                                            their guests
                                                                                                            at all times
                                                                                                            while
                                                                                                            visiting the
                                                                                                            Lounge.
                                                                                                            Guest access
                                                                                                            policies may
                                                                                                            vary
                                                                                                            internationally
                                                                                                            by location
                                                                                                            and are
                                                                                                            subject to
                                                                                                            change.`) }}
                                                                                                        </p>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td colspan="2">
                                                                                                        <strong>{{
                                                                                                            $t(`United
                                                                                                            States`)
                                                                                                            }}</strong>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{{ $t(`Atlanta
                                                                                                        (ATL)`) }}</td>
                                                                                                    <td>{{ $t(`Concourse
                                                                                                        E, near Gate
                                                                                                        E11`) }}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{{ $t(`Charlotte
                                                                                                        (CLT)`) }}</td>
                                                                                                    <td>{{ $t(`Concourse
                                                                                                        D, near Gate
                                                                                                        D13`) }}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{{
                                                                                                        $t(`Dallas/Fort
                                                                                                        Worth (DFW)`) }}
                                                                                                    </td>
                                                                                                    <td>{{ $t(`Terminal
                                                                                                        D, near Gate
                                                                                                        D12`) }}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{{ $t(`Denver
                                                                                                        (DEN)`) }}</td>
                                                                                                    <td>{{ $t(`Concourse
                                                                                                        C, near Gate
                                                                                                        C46`) }}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{{ $t(`Houston
                                                                                                        (IAH)`) }}</td>
                                                                                                    <td>{{ $t(`Terminal
                                                                                                        D, near Gate
                                                                                                        D6`) }}</td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                        <div
                                                                                             class="discovermore-btn discover-margin">
                                                                                            <a @click="opened10 = !opened10"
                                                                                               id="discovermore-link">{{
                                                                                                $t(`Discover
                                                                                                More`) }}</a>
                                                                                        </div>
                                                                                        <table 
                                                                                        v-if="opened10"
                                                                                        class="dismoretable"
                                                                                               style="display: block;">
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td>{{ $t(`Las Vegas
                                                                                                        (LAS)`) }}</td>
                                                                                                    <td>{{ $t(`Concourse
                                                                                                        D, near Gate
                                                                                                        D1`) }}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{{ $t(`Los
                                                                                                        Angeles
                                                                                                        (LAX)`) }}</td>
                                                                                                    <td>{{ $t(`Tom
                                                                                                        Bradley Int’l
                                                                                                        Terminal`) }}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{{ $t(`Miami
                                                                                                        (MIA)`) }}</td>
                                                                                                    <td>{{ $t(`Concourse
                                                                                                        D, near Gate
                                                                                                        D12`) }}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{{ $t(`New York
                                                                                                        (JFK)`) }}</td>
                                                                                                    <td>{{ $t(`Terminal
                                                                                                        4, near Gate
                                                                                                        A2`) }}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{{ $t(`New York
                                                                                                        (LGA)`) }}</td>
                                                                                                    <td>{{ $t(`Terminal
                                                                                                        B, near Gates
                                                                                                        40-59`) }}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{{
                                                                                                        $t(`Philadelphia
                                                                                                        (PHL)`) }}</td>
                                                                                                    <td>{{ $t(`Terminal
                                                                                                        A
                                                                                                        West, near Gate
                                                                                                        A14`) }}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{{ $t(`Phoenix
                                                                                                        (PHX)`) }}</td>
                                                                                                    <td>{{ $t(`Terminal
                                                                                                        4, near Gate
                                                                                                        B22`) }}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{{ $t(`San
                                                                                                        Francisco
                                                                                                        (SFO)`) }}</td>
                                                                                                    <td>{{ $t(`Terminal
                                                                                                        3, near Gate
                                                                                                        F1`) }}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{{ $t(`Seattle
                                                                                                        (SEA)`) }}</td>
                                                                                                    <td>{{ $t(`Concourse
                                                                                                        B, near Gate
                                                                                                        B3`) }}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td colspan="2">
                                                                                                        <strong>{{
                                                                                                            $t(`International
                                                                                                            locations`)
                                                                                                            }}</strong>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{{ $t(`Buenos
                                                                                                        Aires (EZE)`) }}
                                                                                                    </td>
                                                                                                    <td>{{ $t(`Terminal
                                                                                                        B, Argentina`)
                                                                                                        }}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{{ $t(`Delhi
                                                                                                        (DEL) +1
                                                                                                        Guest`) }}</td>
                                                                                                    <td>{{ $t(`Terminal
                                                                                                        3, India`) }}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{{ $t(`Hong Kong
                                                                                                        (HKG)`) }}</td>
                                                                                                    <td>{{ $t(`Terminal
                                                                                                        1, near Gate 60,
                                                                                                        Hong Kong`) }}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{{ $t(`London
                                                                                                        Heathrow
                                                                                                        (LHR)`) }}</td>
                                                                                                    <td>{{ $t(`Terminal
                                                                                                        3, United
                                                                                                        Kingdom`) }}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{{ $t(`Melbourne
                                                                                                        (MEL)`) }}</td>
                                                                                                    <td>{{ $t(`Terminal
                                                                                                        2, Australia`)
                                                                                                        }}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{{ $t(`Mexico
                                                                                                        City (MEX)`) }}
                                                                                                    </td>
                                                                                                    <td>{{ $t(`Terminals
                                                                                                        1 and 2,
                                                                                                        Mexico`) }}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{{ $t(`Mumbai
                                                                                                        (BOM) + 1
                                                                                                        Guest`) }}</td>
                                                                                                    <td>{{ $t(`Terminal
                                                                                                        2, India`) }}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{{ $t(`São Paulo
                                                                                                        (GRU)`) }}</td>
                                                                                                    <td>{{ $t(`Terminal
                                                                                                        3, Brazil`) }}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{{ $t(`Stockholm
                                                                                                        (ARN)`) }}</td>
                                                                                                    <td>{{ $t(`Terminal
                                                                                                        5, Pier F,
                                                                                                        Sweden`) }}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{{ $t(`Sydney
                                                                                                        (SYD)`) }}</td>
                                                                                                    <td>{{ $t(`Terminal
                                                                                                        1, Australia`)
                                                                                                        }}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td colspan="2">
                                                                                                        <strong>{{
                                                                                                            $t(`Airline
                                                                                                            Lounges`)
                                                                                                            }}</strong>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <p>{{ $t(`Delta
                                                                                                            Sky Club:
                                                                                                            Lounges
                                                                                                            operated by
                                                                                                            Delta
                                                                                                            Airlines
                                                                                                            Only`) }}
                                                                                                        </p>
                                                                                                        <p>{{ $t(`Access
                                                                                                            to Delta Sky
                                                                                                            Club partner
                                                                                                            lounges is
                                                                                                            not
                                                                                                            permitted
                                                                                                            (Managed by
                                                                                                            airlines
                                                                                                            within the
                                                                                                            SkyTeam
                                                                                                            alliance or
                                                                                                            other
                                                                                                            affiliated
                                                                                                            carriers,
                                                                                                            offering
                                                                                                            similar
                                                                                                            amenities to
                                                                                                            Delta Sky
                                                                                                            Clubs)`) }}
                                                                                                        </p>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <p>{{ $t(`Yes`)
                                                                                                            }}
                                                                                                        </p>
                                                                                                        <p>{{
                                                                                                            $t(`(Centurion
                                                                                                            Card Members
                                                                                                            traveling on
                                                                                                            same-day
                                                                                                            Delta-operated
                                                                                                            flights with
                                                                                                            Basic
                                                                                                            Economy (E)
                                                                                                            fare tickets
                                                                                                            are not
                                                                                                            eligible for
                                                                                                            access to
                                                                                                            Delta Sky
                                                                                                            Club or Grab
                                                                                                            and Go
                                                                                                            services)`)
                                                                                                            }}
                                                                                                        </p>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{{
                                                                                                        $t(`Lufthansa`)
                                                                                                        }}
                                                                                                    </td>
                                                                                                    <td>{{ $t(`No`) }}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td colspan="2">
                                                                                                        <strong>{{
                                                                                                            $t(`Premium
                                                                                                            Airport
                                                                                                            Lounges`)
                                                                                                            }}</strong>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <p>{{
                                                                                                            $t(`Premium
                                                                                                            Plaza`) }}
                                                                                                        </p>
                                                                                                        <p>{{
                                                                                                            $t(`Argentina:
                                                                                                            Buenos Aires
                                                                                                            - Ministro
                                                                                                            Pistarini
                                                                                                            International
                                                                                                            Airport
                                                                                                            (EZE);
                                                                                                            Australia:
                                                                                                            Adelaide -
                                                                                                            Adelaide
                                                                                                            Airport
                                                                                                            (ADL);
                                                                                                            Brazil: Rio
                                                                                                            de Janeiro -
                                                                                                            RIOgaleão -
                                                                                                            Tom Jobim
                                                                                                            International
                                                                                                            Airport
                                                                                                            (GIG), São
                                                                                                            Paulo - São
                                                                                                            Paulo
                                                                                                            International
                                                                                                            Airport
                                                                                                            (GRU);
                                                                                                            Canada:
                                                                                                            Edmonton -
                                                                                                            Edmonton
                                                                                                            International
                                                                                                            Airport
                                                                                                            (YEG),
                                                                                                            Montréal -
                                                                                                            Pierre
                                                                                                            Elliott
                                                                                                            Trudeau
                                                                                                            International
                                                                                                            Airport
                                                                                                            (YUL),
                                                                                                            Toronto -
                                                                                                            Pearson
                                                                                                            International
                                                                                                            Airport
                                                                                                            (YYZ),
                                                                                                            Vancouver -
                                                                                                            Vancouver
                                                                                                            International
                                                                                                            Airport
                                                                                                            (YVR),
                                                                                                            Winnipeg -
                                                                                                            Richardson
                                                                                                            International
                                                                                                            Airport
                                                                                                            (YWG);
                                                                                                            China:
                                                                                                            Beijing -
                                                                                                            Beijing
                                                                                                            Daxing
                                                                                                            International
                                                                                                            Airport
                                                                                                            (PKX),
                                                                                                            Chongqing -
                                                                                                            Chongqing
                                                                                                            Jiangbei
                                                                                                            International
                                                                                                            Airport
                                                                                                            (CKG), Hong
                                                                                                            Kong - Hong
                                                                                                            Kong
                                                                                                            International
                                                                                                            Airport
                                                                                                            (HKG), Macau
                                                                                                            - Macau
                                                                                                            International
                                                                                                            Airport
                                                                                                            (MFM),
                                                                                                            Qingdao -
                                                                                                            Jiaodong
                                                                                                            International
                                                                                                            Airport
                                                                                                            (TAO);
                                                                                                            India:
                                                                                                            Bengaluru -
                                                                                                            Kempegowda
                                                                                                            International
                                                                                                            Airport
                                                                                                            (BLR),
                                                                                                            Hyderabad -
                                                                                                            Rajiv Gandhi
                                                                                                            International
                                                                                                            Airport
                                                                                                            (HYD), New
                                                                                                            Delhi -
                                                                                                            Indira
                                                                                                            Gandhi
                                                                                                            International
                                                                                                            Airport
                                                                                                            (DEL);
                                                                                                            Malaysia:
                                                                                                            Kuala Lumpur
                                                                                                            - Kuala
                                                                                                            Lumpur
                                                                                                            International
                                                                                                            Airport
                                                                                                            (KUL),
                                                                                                            Langkawi -
                                                                                                            Langkawi
                                                                                                            International
                                                                                                            Airport
                                                                                                            (LGK),
                                                                                                            Penang -
                                                                                                            Penang
                                                                                                            International
                                                                                                            Airport
                                                                                                            (PEN); Saudi
                                                                                                            Arabia:
                                                                                                            Dammam -
                                                                                                            King Fahd
                                                                                                            International
                                                                                                            Airport
                                                                                                            (DMM),
                                                                                                            Jeddah -
                                                                                                            King
                                                                                                            Abdulaziz
                                                                                                            International
                                                                                                            Airport
                                                                                                            (JED),
                                                                                                            Riyadh -
                                                                                                            King Khalid
                                                                                                            International
                                                                                                            Airport
                                                                                                            (RUH);
                                                                                                            Singapore:
                                                                                                            Singapore -
                                                                                                            Changi
                                                                                                            Airport
                                                                                                            (SIN);
                                                                                                            United Arab
                                                                                                            Emirates:
                                                                                                            Dubai -
                                                                                                            Dubai
                                                                                                            International
                                                                                                            Airport
                                                                                                            (DXB);
                                                                                                            United
                                                                                                            Kingdom:
                                                                                                            London -
                                                                                                            Heathrow
                                                                                                            Airport
                                                                                                            (LHR);
                                                                                                            United
                                                                                                            States:
                                                                                                            Dallas -
                                                                                                            Fort Worth
                                                                                                            International
                                                                                                            Airport
                                                                                                            (DFW),
                                                                                                            Orlando -
                                                                                                            Orlando
                                                                                                            International
                                                                                                            Airport
                                                                                                            (MCO).`) }}
                                                                                                        </p>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <p>{{ $t(`No`)
                                                                                                            }}
                                                                                                        </p>
                                                                                                        <p>{{
                                                                                                            $t(`(Centurion
                                                                                                            Cardmemebrs
                                                                                                            can access
                                                                                                            the Premium
                                                                                                            Plaza Lounge
                                                                                                            using their
                                                                                                            Priority
                                                                                                            Pass Digital
                                                                                                            Membership)`)
                                                                                                            }}
                                                                                                        </p>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <p>{{ $t(`Escape
                                                                                                            Lounges`) }}
                                                                                                        </p>
                                                                                                        <p>{{
                                                                                                            $t(`(3-hour
                                                                                                            access
                                                                                                            policy
                                                                                                            window
                                                                                                            before the
                                                                                                            departure
                                                                                                            time)
                                                                                                            Including
                                                                                                            Escape
                                                                                                            Lounges –
                                                                                                            The
                                                                                                            Centurion®
                                                                                                            Studio
                                                                                                            Partner
                                                                                                            USA`) }}</p>
                                                                                                    </td>
                                                                                                    <td>{{ $t(`No`) }}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>{{ $t(`Priority
                                                                                                        pass`) }}</td>
                                                                                                    <td>{{ $t(`Yes`) }}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <p>{{ $t(`Air
                                                                                                            Space`) }}
                                                                                                        </p>
                                                                                                        <p>{{ $t(`(San
                                                                                                            Diego
                                                                                                            International
                                                                                                            Airport
                                                                                                            (SAN) in
                                                                                                            Terminal
                                                                                                            2)`) }}</p>
                                                                                                    </td>
                                                                                                    <td>{{ $t(`No`) }}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                        <!-- <script>
                                                  document.addEventListener("DOMContentLoaded", function(event) {
                                                    (function($) {
                                                      $("#discovermore-link").click(function(e) {
                                                        e.preventDefault();
                                                        $(".dismoretable").toggle();
                                                      });
                                                    })(jQuery);
                                                  });
                                                </script> -->
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="field__item">
                                                <div
                                                     class="paragraph paragraph--type--cent-scrolling-section paragraph--view-mode--default">
                                                    <a id="centurion"></a>
                                                    <div
                                                         class="field field--name-field-scrolling-section-item field--type-entity-reference-revisions field--label-hidden field__items">
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--centurion-free-design paragraph--view-mode--default">
                                                                <div class="container">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-freedesign">
                                                                                <div class="row"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--centurion-image-with-text-icons paragraph--view-mode--default cent-bg-">
                                                                <div class="container">
                                                                    <div class="row g-md-0">
                                                                        <div
                                                                             class="col-12 col-md-6 mt-3 mt-md-0 p-md-0 d-flex align-items-md-center flex-column position-relative">
                                                                            <div class="cent-imgwithtxt-icons-image">
                                                                                <div
                                                                                     class="field field--name-field-image-with-text-icons-img field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/nyc.jpg`)"
                                                                                         width="499"
                                                                                         height="568"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                             class="col-12 col-md-6 mt-3 mt-md-0 p-md-0 d-flex align-self-md-center flex-column">
                                                                            <div class="cent-imgwithtxt-icons-text">
                                                                                <div
                                                                                     class="clearfix text-formatted field field--name-field-image-with-text-icon-title field--type-text-long field--label-hidden field__item">
                                                                                    <p>{{ $t(`Experience Ultimate Luxury
                                                                                        at Centurion New York™`) }}</p>
                                                                                </div>
                                                                                <div
                                                                                     class="clearfix text-formatted field field--name-field-image-with-text-icons-text field--type-text-long field--label-hidden field__items">
                                                                                    <div class="field__item">
                                                                                        <p>{{ $t(`Prepare to be
                                                                                            captivated by Centurion New
                                                                                            York™, perched on the 55th
                                                                                            ﬂoor of One Vanderbilt in
                                                                                            Midtown Manhattan. This
                                                                                            sophisticated sanctuary is
                                                                                            designed to elevate your
                                                                                            Centurion membership,
                                                                                            inviting you to indulge in
                                                                                            Michelin-starred cuisine and
                                                                                            stunning designer spaces.
                                                                                            Centurion New York™ is set
                                                                                            to redeﬁne your concept of
                                                                                            luxury.`) }}</p>
                                                                                    </div>
                                                                                    <div class="field__item">
                                                                                        <div class="nycblocks">
                                                                                            <div
                                                                                                @click="opened11 = !opened11"
                                                                                                 class="nyctitleblk nycplus">
                                                                                                <div class="nyctitle">
                                                                                                    {{ $t(`On-site
                                                                                                    Membership
                                                                                                    Concierge`) }}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                            v-if="opened11"
                                                                                            style="display: block;" 
                                                                                            class="nyctxt"
                                                                                            >
                                                                                                {{ $t(`Experience
                                                                                                bespoke
                                                                                                assistance, ensuring
                                                                                                your lifestyle requests
                                                                                                are met with grace and
                                                                                                efficiency.`) }}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="nycblocks">
                                                                                            <div
                                                                                                @click="opened12 = !opened12"
                                                                                                 class="nyctitleblk nycplus">
                                                                                                <div class="nyctitle">
                                                                                                    {{ $t(`Complimentary
                                                                                                    Access and Priority
                                                                                                    Reservations`) }}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div 
                                                                                                v-if="opened12"
                                                                                                style="display: block;"
                                                                                                class="nyctxt">{{
                                                                                                $t(`We
                                                                                                are committed to
                                                                                                ensuring your priority
                                                                                                bookings and seamless
                                                                                                reservations in our
                                                                                                highly sought-after
                                                                                                dining areas and
                                                                                                lounges, subject to
                                                                                                space availability.`) }}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="nycblocks">
                                                                                            <div
                                                                                                @click="opened13 = !opened13"
                                                                                                 class="nyctitleblk nycplus">
                                                                                                <div class="nyctitle">
                                                                                                    {{ $t(`Private
                                                                                                    Dining`) }}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div 
                                                                                            style="display: block;"
                                                                                            v-if="opened13"
                                                                                            class="nyctxt">
                                                                                                {{ $t(`Our on-site team
                                                                                                can help you organize
                                                                                                and host intimate
                                                                                                gatherings in our
                                                                                                exclusive spaces, where
                                                                                                luxury and privacy
                                                                                                harmonize.`) }}
                                                                                            </div>
                                                                                        </div>
                                                                                        <p></p>
                                                                                        <p>{{ $t(`To access Centurion
                                                                                            New
                                                                                            York™, simply present your
                                                                                            Centurion Card at reception
                                                                                            or contact your Centurion
                                                                                            Relationship Manager for
                                                                                            reservations.`) }}</p>
                                                                                        <p>{{ $t(`Centurion New York™
                                                                                            T&C Apply.`) }}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="field__item">
                                                <div
                                                     class="paragraph paragraph--type--cent-scrolling-section paragraph--view-mode--default">
                                                    <a id="cruise"></a>
                                                    <div
                                                         class="field field--name-field-scrolling-section-item field--type-entity-reference-revisions field--label-hidden field__items">
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--centurion-free-design paragraph--view-mode--default">
                                                                <div class="container">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-freedesign">
                                                                                <div class="row"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--centurion-free-design paragraph--view-mode--default">
                                                                <div class="container">
                                                                    <div class="row g-md-0">
                                                                        <div class="col-12 mt-3 mt-md-0 p-md-0">
                                                                            <div class="cent-freedesign">
                                                                                <div class="row">
                                                                                    <div
                                                                                         class="clearfix text-formatted field field--name-field-free-design-text field--type-text-long field--label-hidden field__item">
                                                                                        <div class="lounge-freedesign">
                                                                                            <div
                                                                                                 class="freedesign-title">
                                                                                                {{ $t(`Luxury at Sea
                                                                                                with
                                                                                                the Centurion Cruise
                                                                                                Program`) }}
                                                                                            </div>
                                                                                            <div
                                                                                                 class="freedesign-text">
                                                                                                {{ $t(`As a Centurion
                                                                                                Cardmember, enjoy up to
                                                                                                $500 in onboard credit
                                                                                                and exclusive amenities
                                                                                                on select sailings with
                                                                                                our distinguished luxury
                                                                                                cruise line partners
                                                                                                listed below.`) }}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--centurion-image-with-text-icons paragraph--view-mode--default cent-bg-">
                                                                <div class="container">
                                                                    <div class="row g-md-0">
                                                                        <div
                                                                             class="col-12 col-md-6 mt-3 mt-md-0 p-md-0 d-flex align-items-md-center flex-column position-relative">
                                                                            <div class="cent-imgwithtxt-icons-image">
                                                                                <div
                                                                                     class="field field--name-field-image-with-text-icons-img field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/regent-seven.jpg`)"
                                                                                         width="482"
                                                                                         height="401"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                             class="col-12 col-md-6 mt-3 mt-md-0 p-md-0 d-flex align-self-md-center flex-column">
                                                                            <div class="cent-imgwithtxt-icons-text">
                                                                                <div
                                                                                     class="clearfix text-formatted field field--name-field-image-with-text-icon-title field--type-text-long field--label-hidden field__item">
                                                                                    <p>
                                                                                        <img alt=""
                                                                                             class="img-fluid"
                                                                                             :src="require(`@/assets/images/centurion/regent-seven-logo.png`)" />
                                                                                    </p>
                                                                                    <p>{{ $t(`Regent Seven Seas`) }}</p>
                                                                                </div>
                                                                                <div
                                                                                     class="clearfix text-formatted field field--name-field-image-with-text-icons-text field--type-text-long field--label-hidden field__items">
                                                                                    <div class="field__item">
                                                                                        <p>{{ $t(`For nearly 30 years,
                                                                                            Regent Seven Seas Cruises
                                                                                            has oﬀered guest’s
                                                                                            memorable, all-inclusive
                                                                                            voyages. Suite
                                                                                            accommodations include ocean
                                                                                            views, private balconies,
                                                                                            private sitting areas,
                                                                                            spacious closets and butler
                                                                                            service upon request. Choose
                                                                                            from a variety of shore
                                                                                            excursions, including
                                                                                            wellness, master-chef led or
                                                                                            architectural design tours,
                                                                                            each unique to Regent
                                                                                            sailings. As a Centurion
                                                                                            Member, receive a $500
                                                                                            onboard credit and one
                                                                                            unique amenity per stateroom
                                                                                            on cruises of 5-nights or
                                                                                            more.`) }}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--centurion-image-with-text-icons paragraph--view-mode--default cent-bg-">
                                                                <div class="container">
                                                                    <div class="row g-md-0">
                                                                        <div
                                                                             class="col-12 col-md-6 mt-3 mt-md-0 p-md-0 d-flex align-items-md-center flex-column position-relative">
                                                                            <div class="cent-imgwithtxt-icons-image">
                                                                                <div
                                                                                     class="field field--name-field-image-with-text-icons-img field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/silversea.jpg`)"
                                                                                         width="482"
                                                                                         height="401"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                             class="col-12 col-md-6 mt-3 mt-md-0 p-md-0 d-flex align-self-md-center flex-column">
                                                                            <div class="cent-imgwithtxt-icons-text">
                                                                                <div
                                                                                     class="clearfix text-formatted field field--name-field-image-with-text-icon-title field--type-text-long field--label-hidden field__item">
                                                                                    <p>
                                                                                        <img alt=""
                                                                                             class="img-fluid"
                                                                                             :src="require(`@/assets/images/centurion/silversea-logo.png`)" />
                                                                                    </p>
                                                                                    <p>{{ $t(`Silversea Cruises`) }}</p>
                                                                                </div>
                                                                                <div
                                                                                     class="clearfix text-formatted field field--name-field-image-with-text-icons-text field--type-text-long field--label-hidden field__items">
                                                                                    <div class="field__item">
                                                                                        <p>{{ $t(`Silversea's
                                                                                            expeditions
                                                                                            oﬀer door-lo-door private
                                                                                            chauﬀeur transfers, luxury
                                                                                            ships, 24-hour gourmet
                                                                                            dining and butler service in
                                                                                            every suite, Spacious suites
                                                                                            oﬀer ocean views and the
                                                                                            opportunity to customize to
                                                                                            your liking, including
                                                                                            toiletries, stationery, bar
                                                                                            and snacks. Choose from over
                                                                                            900 destinations on all
                                                                                            seven continents to set
                                                                                            sail. As a Centurion Member,
                                                                                            receive a $500 onboard
                                                                                            credit and one unique
                                                                                            amenity per stateroom on
                                                                                            cruises of 5-nights or
                                                                                            more.`) }}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="field__item">
                                                            <div
                                                                 class="paragraph paragraph--type--centurion-image-with-text-icons paragraph--view-mode--default cent-bg-">
                                                                <div class="container">
                                                                    <div class="row g-md-0">
                                                                        <div
                                                                             class="col-12 col-md-6 mt-3 mt-md-0 p-md-0 d-flex align-items-md-center flex-column position-relative">
                                                                            <div class="cent-imgwithtxt-icons-image">
                                                                                <div
                                                                                     class="field field--name-field-image-with-text-icons-img field--type-image field--label-hidden field__item">
                                                                                    <img :src="require(`@/assets/images/centurion/seabourn.jpg`)"
                                                                                         width="482"
                                                                                         height="401"
                                                                                         alt=""
                                                                                         loading="lazy"
                                                                                         typeof="foaf:Image"
                                                                                         class="img-fluid" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                             class="col-12 col-md-6 mt-3 mt-md-0 p-md-0 d-flex align-self-md-center flex-column">
                                                                            <div class="cent-imgwithtxt-icons-text">
                                                                                <div
                                                                                     class="clearfix text-formatted field field--name-field-image-with-text-icon-title field--type-text-long field--label-hidden field__item">
                                                                                    <p>
                                                                                        <img alt=""
                                                                                             class="img-fluid"
                                                                                             :src="require(`@/assets/images/centurion/seabourn-logo.png`)" />
                                                                                    </p>
                                                                                    <p>{{ $t(`Seabourn`) }}</p>
                                                                                </div>
                                                                                <div
                                                                                     class="clearfix text-formatted field field--name-field-image-with-text-icons-text field--type-text-long field--label-hidden field__items">
                                                                                    <div class="field__item">
                                                                                        <p>{{ $t(`Seabourn's ﬂeet of
                                                                                            intimate, luxury resorts at
                                                                                            sea carry guests to
                                                                                            destinations on all seven
                                                                                            continents in elegance and
                                                                                            ease. Voyage destinations
                                                                                            include landmark cities and
                                                                                            hidden gems where larger
                                                                                            vessels cannot navigate. All
                                                                                            oceanfront suites, a variety
                                                                                            of superb dining options, a
                                                                                            world-class spa and
                                                                                            beautifully designed spaces
                                                                                            and amenities are augmented
                                                                                            by enhanced health and
                                                                                            safety protocols - and by
                                                                                            the intuitive, heartfelt
                                                                                            hospitality of the staﬀ. As
                                                                                            a Centurion Member, receive
                                                                                            a $500 onboard credit and
                                                                                            one unique amenity per
                                                                                            stateroom on cruises of
                                                                                            5-nights or more.`) }}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </main>
        <CenturionFooter></CenturionFooter>
    </div>
</template>

<script>
import store from '@/store';

import CenturionHeader from './centurion-header.vue';
import CenturionFooter from './centurion-footer.vue';

export default {
    components: {
        CenturionHeader,
        CenturionFooter
    },
    data: () => ({
        opened:false,
        opened2:false,
        opened3:false,
        opened4:false,
        opened5:false,
        opened6:false,
        opened7:false,
        opened8:false,
        opened9:false,
        opened10:false,
        opened11:false,
        opened12:false,
        opened13:false,
        offers: [],
        loaded: true,
        selectedLanguage: 'en',
        heading: 'Available Offers',
        loadedCount: 0,
        unsubscribe: null,
    }),
    created()
    {
        this.unsubscribe = store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
                if (store.get('requestKeys/accessToken') && this.loadedCount == 0)
                {
                    this.loadedCount++;
                }
            }
        })
    },
    destroyed()
    {
        this.unsubscribe();
    },
    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang)
        {
            this.selectedLanguage = localStorage.getItem('language');
        }
        // this.loggedOffers();
    },
    methods: {}
}
</script>
<style lang="scss" scoped>
@import './centurion.scss';
@import './bootstrap.css';

.lounge-freedesign {
    max-width: 80%;
    margin: 0 auto;
}

@media only screen and (max-width: 576px) {
    .lounge-freedesign {
        max-width: 100%;
        margin: auto;
    }

    .lounge-freedesign .freedesign-title,
    .lounge-freedesign .freedesign-text {
        text-align: start;
    }
}

table {
    width: 100%;
    border: solid 1px;
    margin-bottom: 3em;
}

th {
    font-size: 1.5em;
    font-weight: bold;
    background-color: #000000;
    color: #ffffff;
    text-align: center;
    padding: 1em;
}

td {
    border: solid 1px;
    padding: 1em;
    text-align: center;
    width: 50%;
}

.discovermore-btn {
    margin: 1em 0;
}

.discovermore-btn a {
    background-color: #000000;
    color: #ffffff;
    text-decoration: none;
    display: block;
    width: fit-content;
    padding: 1em 2em;
    margin: 0 auto;
    border-radius: 10px;
}

.discover-margin {
    margin-bottom: 2em;
}

.cent-card-txt {
    max-width: 90%;
    margin: auto;
    text-align: start;
}

.disclaimer {
    max-width: 50%;
    margin: auto;
    font-size: 0.9em;
    margin-top: 2em;
    margin-bottom: 3.5em;
}

.disclaimer ul li {
    margin-bottom: 0.4em;
}

@media only screen and (max-width: 576px) {
    .disclaimer {
        max-width: 100% !important;
    }
}

.disclaimer {
    max-width: 50%;
    margin: auto;
    font-size: 0.9em;
    margin-top: 0;
    margin-bottom: 3.5em;
}

.disclaimer ul li {
    margin-bottom: 0.4em;
}

.lounge-freedesign {
    max-width: 80%;
    margin: 0 auto;
}

@media only screen and (max-width: 576px) {
    .lounge-freedesign {
        max-width: 100%;
        margin: auto;
    }

    .lounge-freedesign .freedesign-title,
    .lounge-freedesign .freedesign-text {
        text-align: start;
    }
}

.loungecont {
    max-width: 60%;
    margin: 0 auto;
    text-align: center;
    border: solid 2px #d9d9d9;
    padding: 1.5em;
    border-radius: 1em;
}

.loungetitle {
    font-size: 1.2em;
    margin-bottom: 1em;
}

.loungeimage {}

.loungeimgtitle {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 1em;
}

.loungetext {
    max-width: 90%;
    margin: 0 auto;
}

.loungetext ol {
    text-align: start;
}

.loungelink {
    max-width: fit-content;
    margin: 3em auto;
}

.loungelink a {
    color: #ffffff;
    padding: 1em 3em;
    background: #000000;
    font-weight: bold;
    text-decoration: none;
    font-size: 1.2em;
}

@media only screen and (max-width: 576px) {
    .loungecont {
        max-width: 100% !important;
        margin: 0 auto;
        text-align: start !important;
        border: unset !important;
        padding: 0 !important;
        border-radius: unset;
    }

    .loungetext {
        max-width: 100% !important;
        margin: 0 auto;
    }
}
</style>