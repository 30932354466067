<template>
    <v-card class="overflow-hidden"
            style="border-radius: 0px !important">
        <suspendCard @close="close"
                     @switchIcon="switchIcon"
                     :dialog="showDialog"
                     :card="cardDetail"></suspendCard>
        <v-img :src="responsiveHeaderImage"
               style="max-height: 200px;">
            <div class="d-flex justify-space-between">
                <div class="d-flex align-center flex-row ml-lg-1 ml-md-1 ml-sm-5">

                    <v-btn @click="onGoHome()"
                           color="white"
                           class="ar-navicon d-lg-flex d-none mt-lg-7 mt-md-5 md-sm-4 mt-2 ml-lg-7 ml-md-7 ml-sm-5 ml-3"
                           icon>
                        <v-icon size="21"
                                style="color: #fff; cursor: pointer;">
                            $vuetify.icons.home
                        </v-icon>
                    </v-btn>
                    <div class="d-lg-flex d-none mx-2 mt-lg-8 mt-md-5 md-sm-4 mt-2"
                         style="width: 1px;background: white; height: 21px;"></div>

                    <div class="text-center">
                        <v-menu v-model="menu"
                                :close-on-content-click="false"
                                :nudge-width="200"
                                offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="white"
                                       v-bind="attrs"
                                       v-on="on"
                                       class="mt-lg-8 mt-md-5 md-sm-4 mt-2"
                                       icon>
                                    <v-icon size="25"
                                            style="color: #fff; cursor: pointer;">
                                        $vuetify.icons.menu
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-card class="pa-1">
                                <v-card-text class="pa-6"
                                             style="max-width: 570px;">
                                    <v-row>
                                        <v-col cols="12"
                                               lg="6"
                                               md="6"
                                               sm="12"
                                               v-for="menu in headerMenus"
                                               class="pa-2">
                                            <div class="d-flex align-center"
                                                 style="cursor: pointer;"
                                                 @click="manageCard(menu.route)">
                                                <div>
                                                    <img class="mx-2"
                                                         :src="menu.image"
                                                         style="max-width: 22px;max-height: 22px;margin-top: 5px;">
                                                </div>
                                                <div style="width: 100%;"
                                                     class="font-weight-bold mx-2">{{ $t(menu.name) }}</div>
                                            </div>
                                        </v-col>
                                    </v-row>

                                </v-card-text>
                            </v-card>
                        </v-menu>
                    </div>

                    <v-app-bar-title class="mt-lg-8 mt-md-5 md-sm-4 mt-2 ml-2 mr-2">
                        <div class="header-name">
                            {{ $t('Welcome') }} <span class="font-weight-semibold">{{ name }}</span>
                        </div>
                    </v-app-bar-title>
                </div>


                <v-card max-height="48px"
                        class="d-lg-flex d-none flex-row mt-7 mx-10">
                    <v-card-text class="pt-1 pb-1 d-flex">
                        <div class="d-flex justify-space-between">
                            <div v-if="cardDetail.isEnableForCall"
                                 class="d-flex flex-column justify-center">
                                <div style="cursor:pointer;"
                                     @click="activateCard"
                                     class="d-flex text-blue font-weight-semibold">
                                    <span class="d-flex">
                                        <img contain
                                             style="height: 21px;"
                                             class="auth-3d-group overflow-hidden"
                                             :src="require(`@/assets/images/icons/Activate-card.png`)">
                                    </span>

                                    <span class="arabic-suspend eng-suspend text-black">
                                        {{ $t('Activate Card') }}

                                    </span>
                                </div>
                            </div>

                            <div v-else="!cardDetail?.isEnableForCall"
                                 class="d-flex flex-column justify-center">

                                <div style="cursor:pointer;"
                                     @click="suspendCard"
                                     class="text-blue font-weight-semibold">
                                    <span class="d-flex">
                                        <img contain
                                             style="height: 21px;"
                                             class="auth-3d-group overflow-hidden"
                                             :src="require(`@/assets/images/icons/freeze-card.png`)">
                                        <span v-if="!cardDetail.isSusByUser"
                                              class="arabic-suspend eng-suspend text-black">
                                            {{ $t('Suspend Card') }}
                                        </span>
                                        <span v-if="cardDetail.isSusByUser"
                                              class="arabic-suspend eng-suspend text-black">
                                            {{ $t('Unsuspend card') }}
                                        </span>
                                    </span>
                                </div>

                                <div v-if="status"
                                     class="d-flex divider ar-divider"
                                     style="height: 30px;">
                                    <v-divider :vertical="true"></v-divider>
                                </div>

                            </div>

                            <div v-if="!status"
                                 class="d-flex divider ar-divider"
                                 style="height: 30px;">
                                <v-divider :vertical="true"></v-divider>
                            </div>
                            <div class="d-flex flex-column justify-center">
                                <div style="cursor:pointer;"
                                     @click="viewPin"
                                     class="text-blue font-weight-semibold">
                                    <span class="d-flex">
                                        <img contain
                                             style="height: 21px;"
                                             class="auth-3d-group overflow-hidden"
                                             :src="require(`@/assets/images/icons/view-cardpin.png`)">

                                        <span class="view-pin ar-pin text-black">
                                            {{ $t('View Card PIN') }}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </div>
        </v-img>
    </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import
{
    mdiAccountOutline,
    mdiChatOutline,
    mdiCheckboxMarkedOutline,
    mdiCogOutline,
    mdiCurrencyUsd,
    mdiEmailOutline,
    mdiHelpCircleOutline,
    mdiLogoutVariant
} from '@mdi/js';

import router from '@/router';
import store from '@/store';
import suspendCard from '@/views/card-settings/SuspendCardDialog.vue';

import header from './header';
import mobileHeader from './mobileHeader';

import moment from 'moment';


export default {
    props: {
        details: {
            default: null,
            type: Object
        }
    },
    components: {
        suspendCard
    },
    data: () => ({
        fav: true,
        status: false,
        menu: false,
        cardDetail: {},
        name: '',
        lastLogin: '',
        showDialog: false,
        icons: {
            mdiAccountOutline,
            mdiEmailOutline,
            mdiCheckboxMarkedOutline,
            mdiChatOutline,
            mdiCogOutline,
            mdiCurrencyUsd,
            mdiHelpCircleOutline,
            mdiLogoutVariant,
        },
        headerMenus: [
            {
                name: 'Manage Cards',
                image: require('@/assets/images/Managecards.png'),
                route: '/manage-card-details',
            },
            {
                name: 'Trip Plan',
                image: require('@/assets/images/Calendar.png'),
                route: '/trips',
            },
            {
                name: 'Transactions and Statements',
                image: require('@/assets/images/icons/transaction-statement.png'),
                route: '/view-transaction',
            },
            {
                name: 'FAQs',
                image: require('@/assets/images/login/faqs.png'),
                route: '/faqs',
            },
            {
                name: 'Amex Offers',
                image: require('@/assets/images/icons/amex-offer.png'),
                route: '/offers',
            },
            {
                name: 'Membership Rewards',
                image: require('@/assets/images/Membershiprewards.png'),
                route: '/membership-rewards',
            },
            {
                name: 'Alerts & Notifications',
                image: require('@/assets/images/notifications.png'),
                route: '/alert-notifications',
            },
            {
                name: 'Add Supplementary Cards',
                image: require('@/assets/images/AddSupplemntry.png'),
                route: '/apply-supplementary',
            },
            {
                name: 'Contact Us',
                image: require('@/assets/images/login/contact-us.png'),
                route: '/contact-us',
            },
            {
                name: 'Centurion',
                image: require('@/assets/images/login/contact-us.png'),
                route: '/centurion-home',
            },
        ],
        headerImage: header.headerImage,
        mobileHeaderImage: mobileHeader.mobileHeaderImage,
        responsiveHeaderImage: header.headerImage,
    }),
    watch: {
        details: function (newVal, oldVal)
        {
            if (newVal)
            {
                this.cardDetail = newVal
            }
            else
            {
                this.cardDetail = store.get('card/details');
            }
        }
    },
    created()
    {
        if (this.details)
        {
            this.cardDetail = this.details
        }
        else
        {
            this.cardDetail = store.get('card/details');
        }

        window.addEventListener("resize", this.myEventHandler);

        if (screen.width < 1000)
        {
            console.log('screen', screen.width);
            this.responsiveHeaderImage = this.mobileHeaderImage;
        }
        else
        {
            console.log('screen', screen.width);
            this.responsiveHeaderImage = this.headerImage;
        }
    },
    destroyed()
    {
        window.removeEventListener("resize", this.myEventHandler);
    },
    mounted()
    {
        setTimeout(() =>
        {
            this.name = store.get('accounts/name')
            this.lastLogin = store.get('accounts/lastLogin')
        }, 3000);
    },
    methods: {
        onGoHome()
        {
            router.push('/home');
        },
        myEventHandler(e)
        {
            // console.log(e);
            if (screen.width < 1000)
            {
                console.log('screen', screen.width);
                this.responsiveHeaderImage = this.mobileHeaderImage;
            }
            else
            {
                console.log('screen', screen.width);
                this.responsiveHeaderImage = this.headerImage;
            }
        },

        // PostObjectToUri()
        // {

        //     let newDate = moment(new Date()).format('ddMMyyyyHHmmss');

        //     let data = {
        //         timestamp: newDate,
        //         token: 'Bearer ' + store.get('requestKeys/accessToken')
        //     }

        //     var form, input;

        //     // json = JSON.stringify(obj);

        //     form = document.createElement("form");
        //     form.method = "post";
        //     form.action = "https://online.americanexpress.com.sa/rewards/SSOApp/ssologin.aspx?timestamp=" + newDate + "&access=customer.redemption&channel=WEB";
        //     input = document.createElement("input");
        //     input.setAttribute("name", "token");
        //     input.setAttribute("value", data.token);
        //     form.appendChild(input);
        //     document.body.appendChild(form);
        //     form.submit();
        // },

        manageCard(route)
        {
            if (route == '/centurion-home')
            {
                const routeData = router.resolve(route);
                console.log(routeData);
                window.open(routeData.href, '_blank');
            }
            else if (route == '/membership-rewards')
            {
                const routeData = router.resolve(route);
                console.log(routeData);
                window.open(routeData.href, '_blank');
                // window.open('/#/membership-rewards');
                // this.PostObjectToUri()
            }
            else
            {
                router.push(route);
                window.scrollTo(0, 0);
                store.set('manageCard/details', null)
            }
        },
        suspendCard()
        {
            if (this.showDialog == false)
            {
                this.showDialog = true;
            } else
            {
                this.showDialog = false
            }
            store.set('manageCard/details', null)
        },
        switchIcon()
        {
            router.push('/block-card/primary');
            store.set('manageCard/details', null)
        },
        close()
        {
            this.showDialog = false;
            store.set('manageCard/details', null)
        },
        viewPin()
        {
            router.push('/view-pin/primary');
            store.set('manageCard/details', null)
        },
        activateCard()
        {
            let card = store.get('card/details')
            if (!card.isActive)
            {
                router.push('/active-card/primary');
                store.set('manageCard/details', null)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';


.eng-suspend {
    margin-left: 8px;
    margin-right: 15px;
}

.divider {
    margin-right: 19px;
}

.view-pin {
    margin-left: 8px;
}

.header-name {
    font-weight: 100 !important;
    color: white;
    font-size: 18px;
}

.header-login {
    font-weight: 100 !important;
    color: white;
    font-size: 14px;
}

@media (max-width: 780px) {
    .header-name {
        font-size: 14px;
    }

    .header-login {
        font-size: 10px;
    }
}
</style>
